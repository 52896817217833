import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useBoostManager from '../../../hooks/useBoostManager';
import { selectBoostWords } from '../../../selectors/boost';
import { deleteBoostWordRequest } from '../../../store/slices/boost';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import SectionContainer from '../SectionContainer';
import SectionDivider from '../SectionDivider';
import BoostInput from './BoostInput';
import BoostWord from './BoostWord';
const useStyles = makeStyles({
    container: {
        paddingTop: 32,
        paddingLeft: 72,
        position: 'relative',
    },
    arrowBack: {
        cursor: 'pointer',
        position: 'absolute',
        left: 40,
        top: 36,
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
        },
    },
    wordsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 20,
    },
});
const Boost = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const boostWords = useAppSelector(selectBoostWords);
    const dispatch = useAppDispatch();
    const boostManager = useBoostManager();
    useEffect(() => {
        if (!(boostWords === null || boostWords === void 0 ? void 0 : boostWords.length)) {
            boostManager === null || boostManager === void 0 ? void 0 : boostManager.handleLoadBoostWords();
        }
    }, []);
    return (React.createElement(SectionContainer, { className: classes.container },
        React.createElement(Link, { to: "/web/account-settings/general", className: classes.arrowBack },
            React.createElement(ArrowBackIosIcon, null)),
        React.createElement(PageTitle, { dataQA: "vocabularyBoostTitle" }, t('accountSettings.general.vocabularyBoost')),
        React.createElement(PageSubtitle, null, t('boost.description')),
        React.createElement(BoostInput, null),
        (boostManager === null || boostManager === void 0 ? void 0 : boostManager.loading) && (React.createElement("div", { className: classes.loadingContainer },
            React.createElement(CircularProgress, null))),
        !(boostManager === null || boostManager === void 0 ? void 0 : boostManager.loading) && !!(boostWords === null || boostWords === void 0 ? void 0 : boostWords.length) && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "h6" }, t('boost.listTitle')),
            React.createElement(SectionDivider, null),
            React.createElement("div", { className: classes.wordsContainer }, boostWords.map((word) => (React.createElement(BoostWord, { key: word, word: word, deleteWord: () => dispatch(deleteBoostWordRequest(word)) }))))))));
};
export default Boost;
