import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRateConversation } from '../../hooks/useRateConversation';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { selectAvaName, selectUserPhotoURL } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import { SNCF } from '../../utils';
import { showNewMessage } from '../../utils/intercom';
import AvatarLogoIcon from '../Icons/AvatarLogo';
import CloseIcon from '../Icons/Close';
import OpenOrDownloadAvaAppComponent from '../OpenOrDownloadAvaAppComponent';
import RatingAverage from './RatingAverage';
import RatingContent from './RatingContent';
import RatingExcellent from './RatingExcellent';
import RatingPoor from './RatingPoor';
import RatingSNCF from './RatingSNCF';
const useStyles = makeStyles((theme) => {
    const INITIAL_WIDTH = 432;
    const INITIAL_HEIGHT = 368;
    return {
        root: {
            position: 'fixed',
            right: 20,
            bottom: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: INITIAL_WIDTH,
            height: INITIAL_HEIGHT,
            padding: '16px 48px 24px 48px',
            background: theme.palette.mode === 'light' ? theme.palette.ava.darkBg1 : theme.palette.ava.white,
            boxShadow: theme.palette.mode === 'light'
                ? '0px 4px 24px rgba(112, 144, 176, 0.25)'
                : '0px 4px 4px rgba(112, 144, 176, 0.04)',
            borderRadius: 20,
            color: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark1,
            textAlign: 'center',
            zIndex: Z_INDICES.rateConversion,
            [theme.breakpoints.down('md')]: {
                width: INITIAL_WIDTH - 40,
                height: INITIAL_HEIGHT - 40,
            },
            [theme.breakpoints.down('sm')]: {
                right: 0,
                bottom: 0,
                width: '100%',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        closeButton: {
            position: 'absolute',
            backgroundColor: 'transparent',
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            right: -12,
            top: -12,
            outline: 'none',
            border: 'none',
            borderRadius: 100,
            '&:hover': {
                background: 'transparent',
            },
        },
        avatarLogo: {
            marginTop: 12,
            marginBottom: 8,
            [theme.breakpoints.down('md')]: {
                marginTop: 8,
                marginBottom: 4,
            },
        },
        avatarIcon: {
            width: 70,
            height: 70,
            [theme.breakpoints.down('md')]: {
                width: 60,
                height: 60,
            },
        },
        content: {
            flex: 1,
        },
    };
});
const RateConversation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const hostName = useAppSelector((state) => state.rateConversation.hostName);
    const avaName = useAppSelector(selectAvaName);
    const userPhotoURL = useAppSelector(selectUserPhotoURL);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showOpenOrDownloadAvaApp, setShowOpenOrDownloadAvaApp] = useState(false);
    const [showRating, setShowRating] = useState(false);
    const onClose = useCallback(() => {
        setShowRating(false);
    }, [setShowRating]);
    const { rate, rateHover, onRateChange, onRateHover, finishRating } = useRateConversation(onClose);
    const onFadeExited = useCallback(() => {
        finishRating(rate !== null && rate !== void 0 ? rate : 0);
    }, [rate, finishRating]);
    useEffect(() => {
        setTimeout(() => {
            setShowRating(true);
        }, 1500);
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const openOrDownloadAvaApp = () => {
        // Since it's based on rendering a component,
        // we toggle the visibility alltogether
        setShowOpenOrDownloadAvaApp(false);
        setTimeout(() => {
            setShowOpenOrDownloadAvaApp(true);
        }, 0);
    };
    if (electronCaptionMode) {
        return null;
    }
    return (React.createElement(Fade, { in: showRating, timeout: { enter: 600, exit: 500 }, onExited: onFadeExited, unmountOnExit: true },
        React.createElement("div", { className: classes.root },
            React.createElement(Button, { className: classes.closeButton, "data-qa": "ratingCloseButton", onClick: onClose },
                React.createElement(CloseIcon, { color: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark2, width: 70, height: 70 })),
            React.createElement("div", { className: classes.avatarLogo }, userPhotoURL ? (React.createElement(Avatar, { className: classes.avatarIcon, "data-qa": "ratingUserAvatar", src: userPhotoURL, alt: "profile_photo" })) : (React.createElement(AvatarLogoIcon, { bgTheme: "light", color: theme.palette.ava.navy2, width: screenWidth < 900 ? 60 : 70, height: screenWidth < 900 ? 60 : 70 }))),
            React.createElement("div", { className: classes.content },
                rate === undefined && (React.createElement(RatingContent, { rate: rate, rateHover: rateHover, onRateChange: onRateChange, onRateHover: onRateHover })),
                rate !== undefined && SNCF && (React.createElement(React.Fragment, null,
                    React.createElement(RatingSNCF, null))),
                rate !== undefined && !SNCF && (React.createElement(React.Fragment, null,
                    rate >= 4 && (React.createElement(RatingExcellent, { userExists: !!avaName, hostName: hostName, onClick: openOrDownloadAvaApp })),
                    rate === 3 && (React.createElement(RatingAverage, { userExists: !!avaName, hostName: hostName, onClick: openOrDownloadAvaApp })),
                    rate <= 2 && (React.createElement(RatingPoor, { onClick: () => {
                            showNewMessage(t('conversation.over.iHadTheFollowingIssues'));
                            onClose();
                        } }))))),
            showOpenOrDownloadAvaApp && React.createElement(OpenOrDownloadAvaAppComponent, null))));
};
export default RateConversation;
