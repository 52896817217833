import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { Fab, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { selectCurrentSelectedVoice, selectDisplayedItemsCount, selectTtsVoices, selectVoicesToShow, } from '../../selectors/textToSpeech';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { startRecording, stopRecording } from '../../store/slices/audioV2';
import { fetchTtsVoices, setDisplayedItemsCount, setTtsVoiceThunk } from '../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import { getImageNameForVoice, isAudioPlaying, stopAudio } from '../../utils/textToSpeech';
import TtsImage from './TtsImage';
const useStyles = makeStyles((theme) => ({
    showMoreButton: {
        display: 'flex',
        height: 40,
        width: (props) => (props.redirect ? 400 : '100%'),
        padding: '14px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        background: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.ava.transparent.blue.light,
        color: theme.palette.mode === 'dark' ? 'white' : theme.palette.ava.navy,
        fontWeight: 600,
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.ava.navy : theme.palette.ava.navy5,
        },
        marginBottom: (props) => (props.buttonPopupMenu ? 16 : 0),
    },
    showMoreCC: {
        background: '#1B367C99',
        '&:hover': {
            background: theme.palette.ava.blue2,
        },
    },
    fab: {
        backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'white',
        height: (props) => (props.buttonPopupMenu ? 50 : 80),
        padding: (props) => (!props.electronCaptionMode || props.showMore ? '16px 15px' : 0),
        gap: '20px',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'flex-start',
        width: (props) => (props.electronCaptionMode ? 'auto' : props.buttonPopupMenu || !props.redirect ? '100%' : 400),
        boxShadow: 'none',
        ['@media (max-width: 700px)']: {
            width: 'auto',
        },
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.ava.navy : theme.palette.ava.navy5,
            cursor: 'pointer',
        },
    },
    fabContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
        width: (props) => (props.redirect ? '100%' : '292px'),
    },
    fabText: {
        fontSize: 18,
        color: theme.palette.mode === 'dark' ? 'white' : theme.palette.ava.navy,
        fontWeight: 600,
        gap: '16px',
        textAlign: 'left',
        textTransform: 'none',
        width: '100%',
        ['@media (max-width: 700px)']: {
            fontSize: '1em',
        },
    },
    name: {
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        fontSize: (props) => (props.isToggle ? 14 : 16),
    },
    descriptionContainer: {
        color: (props) => (props.electronCaptionMode || theme.palette.mode === 'light' ? theme.palette.ava.text1 : 'white'),
        fontSize: 14,
        fontWeight: 400,
    },
    playButton: {
        zIndex: Z_INDICES.ttsVoiceButtonPlayButton,
        height: 32,
        width: 32,
        padding: 6,
        '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            '&>*': {
                color: 'gray !important',
            },
        },
    },
    playIcon: {
        color: (props) => (props.electronCaptionMode || theme.palette.mode === 'light' ? theme.palette.ava.blue2 : 'white'),
    },
}));
export const TtsVoiceFab = ({ redirect, closeMenu, buttonPopupMenu, isToggle, shouldStopAudio }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const displayedItemsCount = useAppSelector(selectDisplayedItemsCount);
    const ttsVoices = useAppSelector(selectTtsVoices);
    const currentSelectedVoice = useAppSelector(selectCurrentSelectedVoice);
    const voicesToShow = useAppSelector(selectVoicesToShow);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const [readingVoiceKey, setReadingVoiceKey] = useState();
    const [audioElements, setAudioElements] = useState([]);
    const setStoppedAudioState = useCallback(() => {
        setReadingVoiceKey(undefined);
        dispatch(startRecording());
    }, []);
    const classes = useStyles({
        currentSelectedVoice,
        redirect,
        buttonPopupMenu,
        electronCaptionMode,
        showMore: displayedItemsCount > 2,
        isToggle,
    });
    const dispatch = useAppDispatch();
    const handleFabClick = (voice) => {
        if (redirect)
            navigate('/web/onboarding/profile/org-type');
        dispatch(setTtsVoiceThunk(voice));
        closeMenu === null || closeMenu === void 0 ? void 0 : closeMenu();
        const audioPlaying = audioElements.find((audio) => isAudioPlaying(audio));
        if (audioPlaying) {
            stopAudio(audioPlaying);
            setStoppedAudioState();
        }
    };
    const handleShowMoreButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(setDisplayedItemsCount(ttsVoices.size));
    };
    useEffect(() => {
        // if we failed loading voices from backend and used the fallback
        // attempt to retreive voices from backend
        //TODO talk about error states with Product
        if (ttsVoices.size !== 6) {
            dispatch(fetchTtsVoices(i18n.language));
        }
    }, []);
    useEffect(() => {
        const newAudioElements = voicesToShow.map(([_, voice]) => {
            const audio = new Audio(voice.urlAudio);
            audio.preload = 'none';
            audio.addEventListener('ended', setStoppedAudioState);
            return audio;
        });
        setAudioElements(newAudioElements);
        return () => {
            newAudioElements.forEach((audio) => {
                if (isAudioPlaying(audio)) {
                    stopAudio(audio);
                    setStoppedAudioState();
                }
                audio.removeEventListener('ended', setStoppedAudioState);
            });
        };
    }, [voicesToShow]);
    useEffect(() => {
        if (shouldStopAudio) {
            const audioElementPlaying = audioElements.find((audio) => isAudioPlaying(audio));
            if (audioElementPlaying) {
                stopAudio(audioElementPlaying);
                setStoppedAudioState();
            }
        }
    }, [shouldStopAudio, audioElements]);
    return (React.createElement(React.Fragment, null,
        voicesToShow.map(([voiceKey, voice], index) => {
            var _a, _b, _c;
            const imageName = (_a = getImageNameForVoice(voice)) !== null && _a !== void 0 ? _a : '';
            const namePhraseKey = voice.name;
            // they will always be present because the backend will fallback to en if lang not available
            const desc1 = (_b = voice.tones) === null || _b === void 0 ? void 0 : _b[0];
            const desc2 = (_c = voice.tones) === null || _c === void 0 ? void 0 : _c[1];
            if (!audioElements.length)
                return null;
            const audio = audioElements[index];
            return (React.createElement("div", { className: classes.fab, onClick: () => handleFabClick(voice), key: `${voiceKey}` },
                React.createElement("div", { className: classes.fabContent },
                    React.createElement(TtsImage, { imageName: imageName, imageAltText: namePhraseKey }),
                    React.createElement("div", { className: classes.fabText },
                        React.createElement(PhraseMarkdownText, { className: classes.name, phraseKey: namePhraseKey }),
                        React.createElement("div", { className: classes.descriptionContainer },
                            React.createElement(PhraseMarkdownText, { phraseKey: desc1 }),
                            " \u2022 ",
                            React.createElement(PhraseMarkdownText, { phraseKey: desc2 }))),
                    React.createElement(IconButton, { disabled: Boolean(readingVoiceKey) && readingVoiceKey !== voiceKey, id: voiceKey, onClick: (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatch(stopRecording());
                            if (!readingVoiceKey) {
                                setReadingVoiceKey(voiceKey);
                                audio.play();
                            }
                            else {
                                setStoppedAudioState();
                                if (audio) {
                                    stopAudio(audio);
                                    dispatch(startRecording());
                                }
                            }
                        }, className: classes.playButton }, readingVoiceKey === voiceKey ? (React.createElement(StopIcon, { className: classes.playIcon })) : (React.createElement(PlayArrowIcon, { className: classes.playIcon }))))));
        }),
        displayedItemsCount < ttsVoices.size && (React.createElement(Fab, { variant: "extended", className: classnames(classes.showMoreButton, { [classes.showMoreCC]: electronCaptionMode }), onClick: (event) => handleShowMoreButtonClick(event) },
            t('profiling.voiceSelection.showMore'),
            " \u2193"))));
};
