import React from 'react';
import Loading from '../../components/Loading';
import Default from '../../Layouts/Default';
import { selectConfigEnableConvoV2 } from '../../selectors/auth';
import { selectFeature } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import ConversationPage from '../../views/conversation/ConversationPage/ConversationPage';
import { ConvoV2ConversationPage } from './ConvoV2ConversationPage';
export const ConvoV2ConversationPageSwitch = React.memo(() => {
    const hasFlagInUserProfile = useAppSelector(selectFeature('convo-v2'));
    const hasFlagInFirebase = useAppSelector(selectConfigEnableConvoV2);
    const isScribe = useAppSelector(selectFeature('scribe'));
    // Scribes always get ConvoV1 until we implement scribing in ConvoV2
    if (!isScribe) {
        if (hasFlagInUserProfile || hasFlagInFirebase) {
            return React.createElement(ConvoV2ConversationPage, null);
        }
        if (hasFlagInFirebase === undefined) {
            return React.createElement(Loading, null);
        }
    }
    return (React.createElement(Default, null,
        React.createElement(ConversationPage, null)));
});
