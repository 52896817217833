import { Dialog } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MeetingServiceIcon } from '../../components/Icons/MeetingServiceIcon';
import VideocamOutlined from '../../components/Icons/VideoCamOutlined';
import useRecallAIManager from '../../hooks/useRecallAIManager';
import { selectRecallAIService, selectRecallAIStatus } from '../../selectors/recallAI';
import { selectElectronCaptionMode, selectIsConnectToMeetingOpen } from '../../selectors/ui';
import { setConnectToMeetingsOpen } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ConvoV2AvaConnectCard } from './ConvoV2AvaConnectCard';
import { ConvoV2DialogHeader } from './ConvoV2DialogHeader';
import { ConvoV2DialogTitle } from './ConvoV2DialogTitle';
const ICON_COUNT = 3;
const ICON_WIDTH = 24;
const ICON_GAP = 4;
const INPUT_CONTAINER_GAP = 10;
const ICON_CONTAINER_LARGE_WIDTH = (ICON_COUNT - 1) * ICON_GAP + ICON_COUNT * ICON_WIDTH;
const ICON_CONTAINER_SMALL_WIDTH = ICON_WIDTH;
// When a valid link is entered, we show only one icon instead of all,
// so we want to grow the input to componsate for the smaller icon container,
// preventing jumping of the input container size
const ICON_CONTAINER_COMPENSATION = ICON_CONTAINER_LARGE_WIDTH - ICON_CONTAINER_SMALL_WIDTH;
const INPUT_SMALL_WIDTH = 196;
const INPUT_LARGE_WIDTH = INPUT_SMALL_WIDTH + ICON_CONTAINER_COMPENSATION;
const useStyles = makeStyles((theme) => ({
    startButton: {
        display: 'flex',
        alignItems: 'center',
        height: 48,
        gap: 8,
        borderRadius: 40,
        padding: '0 16px',
        fontSize: 18,
        fontWeight: 600,
        cursor: 'pointer',
        border: `1px solid ${theme.palette.ava.grey7}`,
        textWrap: 'nowrap',
        color: theme.palette.ava.deepBlue,
        '&:hover': {
            borderColor: theme.palette.ava.grey7,
            background: theme.palette.ava.navy5,
            color: '#86CDFF',
        },
    },
    startButtonMobile: {
        width: 48,
        padding: 0,
        justifyContent: 'center',
    },
}));
export const ConvoV2AvaConnect = React.memo(() => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md')) && !window.isElectron;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) && !window.isElectron;
    const recallAIManager = useRecallAIManager();
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const recallAIService = useAppSelector(selectRecallAIService);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const isConnectToMeetingsOpen = useAppSelector(selectIsConnectToMeetingOpen);
    const active = !electronCaptionMode && isConnectToMeetingsOpen;
    const dispatch = useAppDispatch();
    const onStartClick = useCallback(() => {
        if (recallAIStatus === 'CAPTIONING' && isTabletOrMobile) {
            recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.mobileClick();
        }
        dispatch(setConnectToMeetingsOpen(true));
    }, [recallAIStatus, isTabletOrMobile, recallAIManager, dispatch, setConnectToMeetingsOpen]);
    const onDialogClose = useCallback(() => {
        dispatch(setConnectToMeetingsOpen(false));
    }, [setConnectToMeetingsOpen]);
    if (!active) {
        const showServiceIcon = isMobile && recallAIStatus === 'CAPTIONING' && recallAIService !== '';
        return (React.createElement(Box, { className: classNames(classes.startButton, { [classes.startButtonMobile]: isMobile }), onClick: onStartClick },
            !showServiceIcon && React.createElement(VideocamOutlined, null),
            showServiceIcon && React.createElement(MeetingServiceIcon, { service: recallAIService }),
            !isMobile && t('connectToOnlineMeetings.title')));
    }
    if (!isMobile) {
        return React.createElement(ConvoV2AvaConnectCard, null);
    }
    return (React.createElement(Dialog, { open: active, onClose: onDialogClose, PaperProps: {
            style: {
                borderRadius: 20,
                padding: 24,
            },
        } },
        React.createElement(ConvoV2DialogHeader, { onClose: onDialogClose },
            React.createElement(ConvoV2DialogTitle, null, t('connectToOnlineMeetings.title'))),
        React.createElement(ConvoV2AvaConnectCard, null)));
});
