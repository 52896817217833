import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectIsUsingTts } from '../../selectors/textToSpeech';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { setIsUsingTts } from '../../store/slices/textToSpeech';
import { useAppDispatch } from '../../store/store';
import { useAppSelector } from '../../store/store';
import TtsToggle from './TtsToggle';
const useStyles = makeStyles((theme) => ({
    tooltip: {
        padding: '2.5px 8px',
        backgroundColor: theme.palette.ava.grey4,
        borderRadius: 4,
        fontSize: 14,
        textAlign: 'center',
    },
    sendToolTipBottom: {
        position: 'relative',
        top: (props) => (props.electronCaptionMode ? 75 : 10),
        right: 15,
    },
}));
function V1TtsButton() {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const classes = useStyles({ isUsingTts, lang: i18n.language, electronCaptionMode });
    const handleButtonClick = () => {
        dispatch(setIsUsingTts(!isUsingTts));
    };
    return (React.createElement("div", { style: { marginRight: 10 } },
        React.createElement(Tooltip, { open: isButtonHovered && isUsingTts, classes: { tooltip: classes.tooltip, tooltipPlacementBottom: classes.sendToolTipBottom }, title: t('ccMode.keyboard.tooltip.tts'), placement: "bottom", disableInteractive: true, onMouseEnter: () => setIsButtonHovered(true), onMouseLeave: () => setIsButtonHovered(false) },
            React.createElement(TtsToggle, { handleToggle: handleButtonClick }))));
}
export default V1TtsButton;
