import React from 'react';
import { selectTotalTranscriptCount } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { ConvoV2TranscriptListContent } from './ConvoV2TranscriptListContent';
export const ConvoV2TranscriptList = React.memo((props) => {
    // We decide here whether we want to show the transcript list content or not
    // instead of in the ConvoV2ConversationPage, so the page doesn't have to re-render
    // whenever the transcript count changes. We previously conditionally rendered
    // the transcript list content, but if we use useAutoScroll hook without the
    // scroll area actually being rendered, it doesn't detect it correctly later on,
    // which manifested as a bug where it would always auto scroll, even if scrolling up.
    const transcriptCount = useAppSelector(selectTotalTranscriptCount);
    return transcriptCount > 0 ? React.createElement(ConvoV2TranscriptListContent, Object.assign({}, props)) : null;
});
