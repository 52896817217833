import React from 'react';
const MicrosoftTeamsIcon = (props) => {
    return (React.createElement("svg", { className: props === null || props === void 0 ? void 0 : props.className, width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_4941_311185)" },
            React.createElement("path", { d: "M15.9502 10.0977H21.1162C21.6043 10.0977 21.9999 10.5003 21.9999 10.9969V15.7852C21.9999 17.6104 20.5458 19.0902 18.752 19.0902H18.7366C16.9429 19.0905 15.4885 17.611 15.4883 15.7857V10.5677C15.4883 10.506 15.5002 10.4449 15.5234 10.3878C15.5466 10.3308 15.5806 10.279 15.6235 10.2353C15.6664 10.1917 15.7173 10.1571 15.7734 10.1334C15.8294 10.1098 15.8895 10.0977 15.9502 10.0977Z", fill: "#5059C9" }),
            React.createElement("path", { d: "M19.4427 9.15419C20.5986 9.15419 21.5357 8.20063 21.5357 7.02436C21.5357 5.84809 20.5986 4.89453 19.4427 4.89453C18.2867 4.89453 17.3496 5.84809 17.3496 7.02436C17.3496 8.20063 18.2867 9.15419 19.4427 9.15419Z", fill: "#5059C9" }),
            React.createElement("path", { d: "M12.9295 9.15282C14.5992 9.15282 15.9528 7.77546 15.9528 6.07641C15.9528 4.37736 14.5992 3 12.9295 3C11.2598 3 9.90625 4.37736 9.90625 6.07641C9.90625 7.77546 11.2598 9.15282 12.9295 9.15282Z", fill: "#7B83EB" }),
            React.createElement("path", { d: "M16.9615 10.0977H8.4341C7.95184 10.1098 7.57035 10.5171 7.58153 11.0078V16.4691C7.51418 19.414 9.80379 21.8579 12.6978 21.93C15.5918 21.8579 17.8814 19.414 17.8141 16.4691V11.0078C17.8253 10.5171 17.4438 10.1098 16.9615 10.0977Z", fill: "#7B83EB" }),
            React.createElement("path", { opacity: "0.1", d: "M13.1638 10.0977V17.7508C13.1626 17.9224 13.1117 18.0899 13.0174 18.2322C12.9231 18.3745 12.7895 18.4853 12.6335 18.5507C12.532 18.5944 12.4228 18.6169 12.3126 18.6169H7.99157C7.9311 18.4607 7.87532 18.3045 7.82876 18.1436C7.66595 17.6005 7.58289 17.0359 7.58227 16.4681V11.0064C7.5711 10.5165 7.95196 10.1098 8.43344 10.0977H13.1637L13.1638 10.0977Z", fill: "black" }),
            React.createElement("path", { opacity: "0.2", d: "M12.6984 10.0977V18.2241C12.6984 18.3363 12.6763 18.4473 12.6334 18.5507C12.5691 18.7094 12.4603 18.8453 12.3204 18.9413C12.1806 19.0373 12.016 19.0891 11.8473 19.0902H8.21016C8.1311 18.934 8.05665 18.7778 7.99157 18.6169C7.92905 18.4622 7.8747 18.3042 7.82876 18.1436C7.66595 17.6005 7.58289 17.0359 7.58227 16.4681V11.0064C7.5711 10.5165 7.95196 10.1098 8.43344 10.0977H12.6985L12.6984 10.0977Z", fill: "black" }),
            React.createElement("path", { opacity: "0.2", d: "M12.6985 10.0977V17.2775C12.695 17.7543 12.316 18.1401 11.8473 18.1436H7.82876C7.66594 17.6005 7.58289 17.0359 7.58227 16.4681V11.0064C7.5711 10.5165 7.95196 10.1098 8.43344 10.0977L12.6985 10.0977Z", fill: "black" }),
            React.createElement("path", { opacity: "0.2", d: "M12.2334 10.0977V17.2775C12.2298 17.7543 11.8509 18.1401 11.3823 18.1436H7.82876C7.66595 17.6005 7.58289 17.0359 7.58227 16.4681V11.0064C7.5711 10.5165 7.95196 10.1098 8.43344 10.0977L12.2334 10.0977Z", fill: "black" }),
            React.createElement("path", { opacity: "0.1", d: "M13.1623 7.65129V9.14211C13.0833 9.14688 13.0088 9.15165 12.9298 9.15165C12.8507 9.15165 12.7762 9.14688 12.6972 9.14211C12.5402 9.13152 12.3845 9.10619 12.2321 9.06643C11.7678 8.95454 11.3361 8.73239 10.9723 8.41816C10.6085 8.10393 10.323 7.70654 10.1391 7.25849C10.0749 7.10584 10.0251 6.94736 9.99023 6.78516H12.3112C12.7805 6.78698 13.1605 7.17366 13.1623 7.65129Z", fill: "black" }),
            React.createElement("path", { opacity: "0.2", d: "M12.6967 8.12386V9.14144C12.5397 9.13085 12.384 9.10551 12.2316 9.06575C11.7673 8.95385 11.3356 8.7317 10.9719 8.41747C10.6081 8.10324 10.3226 7.70585 10.1387 7.25781H11.8455C12.3149 7.25956 12.6949 7.64632 12.6967 8.12386Z", fill: "black" }),
            React.createElement("path", { opacity: "0.2", d: "M12.6967 8.12386V9.14144C12.5397 9.13085 12.384 9.10551 12.2316 9.06575C11.7673 8.95385 11.3356 8.7317 10.9719 8.41747C10.6081 8.10324 10.3226 7.70585 10.1387 7.25781H11.8455C12.3149 7.25956 12.6949 7.64632 12.6967 8.12386Z", fill: "black" }),
            React.createElement("path", { opacity: "0.2", d: "M12.2317 8.12394V9.06583C11.7674 8.95393 11.3357 8.73178 10.9719 8.41753C10.6081 8.10329 10.3226 7.70588 10.1387 7.25781H11.3805C11.8499 7.25964 12.2299 7.6464 12.2317 8.12394Z", fill: "black" }),
            React.createElement("path", { d: "M2.85258 7.25789H11.3799C11.8509 7.25789 12.2326 7.64632 12.2326 8.12545V16.8027C12.2326 17.2819 11.8509 17.6703 11.38 17.6703H2.85258C2.38172 17.6703 2 17.2819 2 16.8027V8.12553C2 7.64624 2.38172 7.25789 2.85258 7.25789Z", fill: "url(#paint0_linear_4941_311185)" }),
            React.createElement("path", { d: "M9.36047 10.5608H7.65578V15.2843H6.56977V10.5608H4.87305V9.64453H9.36047V10.5608Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_4941_311185", x1: "179.76", y1: "-60.5274", x2: "865.105", y2: "1105.95", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#5A62C3" }),
                React.createElement("stop", { offset: "0.5", stopColor: "#4D55BD" }),
                React.createElement("stop", { offset: "1", stopColor: "#3940AB" })),
            React.createElement("clipPath", { id: "clip0_4941_311185" },
                React.createElement("rect", { width: "20", height: "19", fill: "white", transform: "translate(2 3)" })))));
};
export default MicrosoftTeamsIcon;
