var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectTranscripts } from '../../selectors/legacy-conversation';
import { selectAvaId, selectFeatures } from '../../selectors/userProfile';
import { updateFeatures } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { sendSaveTranscriptUpdateMessage } from '../../utils/ws-v1';
import SaveTranscriptDialog1 from './SaveTranscriptDialog1';
import SaveTranscriptDialog2 from './SaveTranscriptDialog2';
const SaveTranscriptsPopup = ({ onFinish, Dialog1Component, Dialog2Component }) => {
    const transcripts = useSelector(selectTranscripts);
    const dispatch = useDispatch();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const features = useAppSelector(selectFeatures);
    const avaId = useAppSelector(selectAvaId);
    const [socket] = useV1Socket();
    const [isOpen, setIsOpen] = useState(true);
    const [dialogNumber, setDialogNumber] = useState(1);
    const [changingUserFeatures, setChangingUserFeatures] = useState(false);
    const isSaveTranscript = features.saveTranscript;
    const isForbidConversationSaving = !!features['forbid-conversation-saving'];
    useLayoutEffect(() => {
        if (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) {
            setIsOpen(false);
            onFinish();
            return;
        }
        const shouldCallCallback = isSaveTranscript || isForbidConversationSaving;
        if (shouldCallCallback || !Object.keys(transcripts).length) {
            setIsOpen(false);
            onFinish();
        }
    }, []);
    const onSaveClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        setChangingUserFeatures(true);
        yield dispatch(updateFeatures({ saveTranscript: true }));
        if (socket) {
            sendSaveTranscriptUpdateMessage(socket, true);
        }
        onFinish();
        setChangingUserFeatures(false);
    });
    const onNotNowClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        if (dialogNumber === 2) {
            onFinish();
        }
        else {
            setDialogNumber(2);
        }
    });
    const Dialog1 = Dialog1Component !== null && Dialog1Component !== void 0 ? Dialog1Component : SaveTranscriptDialog1;
    const Dialog2 = Dialog2Component !== null && Dialog2Component !== void 0 ? Dialog2Component : SaveTranscriptDialog2;
    return dialogNumber === 1 ? (React.createElement(Dialog1, { open: isOpen, disabled: changingUserFeatures, onSaveClicked: onSaveClicked, onNotNowClicked: onNotNowClicked })) : (React.createElement(Dialog2, { open: isOpen, disabled: changingUserFeatures, onSaveClicked: onSaveClicked, onContinueClicked: onNotNowClicked }));
};
export default SaveTranscriptsPopup;
