import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    scrollPaper: {
        background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.1)',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '10px 20px',
        minHeight: 250,
        maxWidth: 464,
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark2,
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            margin: 10,
            padding: 10,
        },
    },
    heading: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 20,
        lineHeight: '26px',
    },
    text: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '27px',
    },
    dialogTitle: {
        padding: 0,
        maxWidth: '60%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'inherit',
        },
    },
    dialogContent: {
        flex: 'initial',
        padding: 0,
        maxWidth: '94%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'inherit',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-around',
        },
    },
    continueButton: {
        width: 147,
        padding: '7px 27px',
        border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.ava.blue2 : theme.palette.ava.blue2}`,
        borderRadius: 100,
        color: theme.palette.mode === 'light' ? theme.palette.ava.blue2 : theme.palette.ava.blue2,
        fontSize: 20,
        lineHeight: '26px',
        '&:hover': {
            background: theme.palette.mode === 'light' ? theme.palette.ava.navy5 : theme.palette.ava.blue5,
        },
        [theme.breakpoints.down('sm')]: {
            width: 'initial',
            padding: '6px 16px',
        },
    },
    saveButton: {
        width: 148,
        padding: '7px 31px',
        background: theme.palette.ava.blue2,
        borderRadius: 100,
        color: '#FFFFFF',
        fontSize: 20,
        lineHeight: '26px',
        '&:hover': {
            background: theme.palette.ava.blue1,
        },
        [theme.breakpoints.down('sm')]: {
            width: 'initial',
            padding: '6px 34px',
        },
    },
}));
const SaveTranscriptDialog2 = ({ open, onContinueClicked, onSaveClicked, disabled }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Dialog, { className: classes.root, classes: {
            scrollPaper: classes.scrollPaper,
            paper: classes.paper,
        }, open: open },
        React.createElement(DialogTitle, { className: classes.heading, classes: { root: classes.dialogTitle } }, t('saveTranscript.dialog2.heading')),
        React.createElement(DialogContent, { className: classes.text, classes: { root: classes.dialogContent } }, t('saveTranscript.dialog2.text')),
        React.createElement("div", { className: classes.buttonContainer },
            React.createElement(Button, { disabled: disabled, className: classes.continueButton, "data-qa": "saveTranscriptContinueButton", onClick: onContinueClicked }, t('saveTranscript.dialog2.continueButton')),
            React.createElement(Button, { disabled: disabled, className: classes.saveButton, "data-qa": "saveTranscriptSaveButton", onClick: onSaveClicked }, t('saveTranscript.dialog2.saveButton')))));
};
export default SaveTranscriptDialog2;
