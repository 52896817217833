// Hash function to generate numbers based on a string, to be used for
// applications that need some predictable variety based on a string, but
// don't need guarantees on minimizing collisions.
export function hashStringToInteger(s) {
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
        const charCode = s.charCodeAt(i);
        // Multiplying 'spreads out the hash and subtracting the current value
        // slows down the growth of the number.
        hash = (hash << 5) - hash;
        // Adding the char code makes the value jump around by relatively small
        // amounts, but the effects of these are increased by the previous step
        hash += charCode;
        // In case the number is becoming too large, do a bitwise noop which
        // makes JS convert it to a 32 bits integer.
        hash = hash | 0;
    }
    return Math.abs(hash);
}
