import HeadsetRoundedIcon from '@mui/icons-material/HeadsetRounded';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../components/Buttons/RoundedButton';
import { selectHasScribe, selectPendingScribe } from '../../selectors/legacy-conversation';
import { setIsOneClickScribeModalOpen } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import OneClickScribeModal from './OneClickScribeModal';
const useStyles = makeStyles((theme) => ({
    button: {
        height: 44,
        backgroundColor: (props) => (props.hasScribe ? theme.palette.ava.deepBlue : 'white'),
        '&:hover': {
            backgroundColor: (props) => (props.hasScribe ? theme.palette.ava.deepBlue : 'rgba(0, 0, 0, 0.04)'),
            opacity: (props) => (props.hasScribe ? 0.9 : 1),
        },
        flexShrink: 0,
        padding: '0 16px',
        gap: 8,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        '&:disabled': {
            backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
            boxShadow: 'none',
            opacity: 0.8,
        },
    },
    text: {
        fontSize: 18,
        textTransform: 'none',
        fontWeight: 600,
        color: (props) => (props.hasScribe ? 'white' : theme.palette.ava.navy),
    },
    icon: {
        color: (props) => (props.hasScribe ? 'white' : 'black'),
        '&disabled': {
            color: 'black',
            opacity: 1,
        },
    },
}));
function OneClickScribeButtonV1({ hideText }) {
    const { t } = useTranslation();
    const pendingScribe = useAppSelector(selectPendingScribe);
    const hasScribe = useAppSelector(selectHasScribe);
    const classes = useStyles({ hasScribe });
    const dispatch = useAppDispatch();
    const openModal = useCallback(() => {
        dispatch(setIsOneClickScribeModalOpen(true));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(RoundedButton, { disabled: pendingScribe, className: classes.button, onClick: openModal },
            React.createElement(HeadsetRoundedIcon, { className: classes.icon }),
            !hideText && React.createElement(Typography, { className: classes.text }, t('oneClickScribe.v1'))),
        React.createElement(OneClickScribeModal, { hasScribe: hasScribe })));
}
export default OneClickScribeButtonV1;
