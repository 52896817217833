import { IconButton, Tooltip, Typography } from '@mui/material';
import Input from '@mui/material/Input';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import SendIcon from '../../components/Icons/SendIcon';
import { useKeyboardInput } from '../../hooks/useKeyboardInput';
import { selectIsTextToSpeechV2 } from '../../selectors/auth';
import { selectIsUsingTts } from '../../selectors/textToSpeech';
import { selectFontSize, selectKeyboardInputShown } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import TtsButton from '../textToSpeech/TtsButton';
import V1TtsButton from '../textToSpeech/V1Button';
const useStyles = makeStyles((theme) => ({
    keyboardContainer: {
        position: 'absolute',
        bottom: 10,
        right: 120,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        borderRadius: 8,
        background: theme.palette.mode === 'light' ? 'white' : 'rgba(255, 255, 255, 0.08)',
        border: theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
        flexShrink: 0,
        padding: '0px 6px 0px 14px',
        resize: 'none',
        width: 'calc(100% - 140px)',
    },
    input: {
        fontSize: (props) => props.fontSize,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 0,
        color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.48)' : 'rgba(255, 255, 255, 0.85)',
        margin: 0,
        padding: 0,
    },
    errorToolTip: {
        fontSize: 16,
    },
    sendButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 8px',
        gap: 12,
    },
    sendButton: {
        display: 'flex',
        backgroundColor: 'transparent',
        padding: 0,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    tooltip: {
        padding: '2.5px 8px',
        maxWidth: 230,
        backgroundColor: theme.palette.ava.grey4,
        borderRadius: 4,
        fontSize: 14,
        textAlign: 'center',
    },
    sendTooltipPlacementTop: {
        margin: '4px 0',
    },
    verticalSeparatorContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    verticalSeparator: {
        width: 1,
        height: 26,
        background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.08)',
    },
    slideEnter: {
        transform: 'translateX(100%)',
    },
    slideEnterActive: {
        transform: 'translateX(0)',
        transition: 'transform 150ms ease-out',
    },
    slideExit: {
        transform: 'translateX(0)',
    },
    slideExitActive: {
        transform: 'translateX(100%)',
        transition: 'transform 150ms ease-in',
    },
}));
const KeyboardInput = () => {
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ fontSize });
    const { t } = useTranslation();
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const isTextToSpeechV2 = useAppSelector(selectIsTextToSpeechV2);
    const { message, onInputChange, onInputKeyDown, onSendClick, buttonState, charLimitReached } = useKeyboardInput();
    return (React.createElement(CSSTransition
    // don't use Slide Component from MUI as it doesn't work on Safari/MacOS Desktop
    , { 
        // don't use Slide Component from MUI as it doesn't work on Safari/MacOS Desktop
        in: keyboardInputShown, timeout: 150, classNames: {
            enter: classes.slideEnter,
            enterActive: classes.slideEnterActive,
            exit: classes.slideExit,
            exitActive: classes.slideExitActive,
        }, mountOnEnter: true, unmountOnExit: true },
        React.createElement("div", { className: classes.keyboardContainer },
            React.createElement(Tooltip, { arrow: true, title: React.createElement(Typography, { className: classes.errorToolTip }, t('ccMode.keyboard.tooltip.charLimit')), open: charLimitReached },
                React.createElement(Input, { classes: { root: classes.input }, onChange: onInputChange, value: message, "data-qa": "ttsInputField", placeholder: isUsingTts ? t('ccMode.keyboard.placeholderTTS') : t('conversation.typeTranscriptHere'), onKeyDown: onInputKeyDown, autoFocus: true, multiline: true, maxRows: 3, disableUnderline: true, error: charLimitReached })),
            React.createElement(Tooltip, { classes: { tooltip: classes.tooltip, tooltipPlacementTop: classes.sendTooltipPlacementTop }, title: isUsingTts ? t('ccMode.keyboard.sendAsVoiceMessage') : t('ccMode.keyboard.tooltip.send'), placement: "top", disableInteractive: true },
                React.createElement("div", { className: classes.sendButtonContainer },
                    React.createElement(IconButton, { disabled: buttonState === 'disabled', className: classes.sendButton, onClick: onSendClick, size: "large" },
                        React.createElement(SendIcon, { buttonState: buttonState })))),
            React.createElement("div", { className: classes.verticalSeparatorContainer },
                React.createElement("div", { className: classes.verticalSeparator })),
            isTextToSpeechV2 ? React.createElement(TtsButton, null) : React.createElement(V1TtsButton, null))));
};
export default KeyboardInput;
