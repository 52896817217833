import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../hooks/usePrevious';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import { isMac } from '../../utils';
import LoadingText from '../LoadingText';
const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: (props) => props.electronCaptionMode
            ? Z_INDICES.websocketReconnectingBannerElectron
            : Z_INDICES.websocketReconnectingBannerWeb,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: (props) => (props.electronCaptionMode ? 30 : 60),
        backgroundColor: (props) => props.status.background,
        color: (props) => props.status.color,
        fontSize: 24,
        lineHeight: '30px',
        animation: (props) => (props.shouldShowReconnectingBanner ? '$slideIn 0.5s forwards' : '$slideOut 0.5s forwards'),
    },
    '@keyframes slideIn': {
        '0%': {
            top: -200,
            opacity: 0,
        },
        '100%': {
            top: (props) => props.top,
            opacity: 1,
        },
    },
    '@keyframes slideOut': {
        '0%': {
            top: (props) => props.top,
            opacity: 1,
        },
        '100%': {
            top: -200,
            opacity: 0,
        },
    },
}));
const getStatusInfo = (status) => {
    switch (status) {
        case 'offline':
            return {
                type: 'offline',
                background: '#DADCE0',
                color: '#000',
                translation: 'network.nowOffline',
            };
        case 'pending':
        case 'closed':
        case 'disconnected':
        case 'reconnecting':
            return {
                type: 'reconnecting',
                background: '#ED8822',
                color: '#fff',
                translation: 'network.reconnecting',
            };
        case 'online':
        default:
            return {
                type: 'online',
                background: '#34C54F',
                color: '#fff',
                translation: 'network.nowConnected',
            };
    }
};
const WebsocketReconnectingBanner = ({ className }) => {
    const [_, websocketStatus] = useV1Socket();
    const previousWebsocketStatus = usePrevious(websocketStatus);
    const statusInfo = getStatusInfo(websocketStatus);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const [shouldShowReconnectingBanner, setShouldShowReconnectingBanner] = useState(false);
    useEffect(() => {
        if (websocketStatus === 'reconnecting' ||
            websocketStatus === 'offline' ||
            websocketStatus === 'disconnected' ||
            websocketStatus === 'closed') {
            setShouldShowReconnectingBanner(true);
        }
        if (previousWebsocketStatus === 'reconnecting' ||
            previousWebsocketStatus === 'offline' ||
            previousWebsocketStatus === 'disconnected' ||
            previousWebsocketStatus === 'closed') {
            if (websocketStatus === 'pending') {
                setShouldShowReconnectingBanner(true);
            }
        }
        if (websocketStatus === 'online') {
            setTimeout(() => {
                setShouldShowReconnectingBanner(false);
            }, 1500);
        }
    }, [websocketStatus]);
    let top = 0;
    if (window.isElectron) {
        top += isMac ? 28 : 32;
    }
    if (electronCaptionMode) {
        top = 0;
    }
    else {
        top += 68;
    }
    const classes = useStyles({
        electronCaptionMode,
        shouldShowReconnectingBanner,
        status: statusInfo,
        top,
    });
    const { t } = useTranslation();
    if (!shouldShowReconnectingBanner)
        return null;
    return (React.createElement("div", { className: classnames(classes.root, className) },
        React.createElement(LoadingText, { className: classnames({ [classes.ellipsis]: statusInfo.type === 'reconnecting' }) }, t(statusInfo.translation))));
};
export default WebsocketReconnectingBanner;
