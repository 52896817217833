import { connect } from 'react-redux';
import { selectFurthestObservedByScribe, selectLangForTranscripts, selectUserRoleInConversation, UserRoleInConversation, } from '../../selectors/combined';
import { RecordingStatus } from '../../store/slices/audioV2';
import ScribeTranscriptList from './ScribeTranscriptList';
const mapStateToProps = (state) => {
    const { ui: { fontSize, correctionMode, electronCaptionMode }, scribeConversation: { speakers, status, transcripts, transcriptsCurrent, transcriptsFinal }, userProfile: { features }, v1Session: { conferenceCallRequested }, audioV2: { status: audioV2Status }, } = state;
    return {
        features,
        fontSize,
        electronCaptionMode,
        furthestObservedByScribe: selectFurthestObservedByScribe(state),
        langForTranscripts: selectLangForTranscripts(state),
        conferenceCallRequested,
        correctionMode,
        speakers,
        transcripts,
        transcriptsCurrent,
        transcriptsFinal,
        recording: audioV2Status === RecordingStatus.RECORDING,
        twilioCallsLength: Object.keys(status.twilioCalls || {}).length,
        scribing: selectUserRoleInConversation(state) === UserRoleInConversation.SCRIBE,
    };
};
export default connect(mapStateToProps)(ScribeTranscriptList);
