import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SendIcon from '../../../components/Icons/SendIcon';
import { useKeyboardInput } from '../../../hooks/useKeyboardInput';
import TtsButton from '../../../modules/textToSpeech/TtsButton';
import V1TtsButton from '../../../modules/textToSpeech/V1Button';
import { selectIsTextToSpeechV2 } from '../../../selectors/auth';
import { selectIsUsingTts } from '../../../selectors/textToSpeech';
import { selectElectronCaptionMode, selectFontSize } from '../../../selectors/ui';
import { setErrorAndReset } from '../../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import useStyles from './KeyboardInput.styles';
const KeyboardInput = ({ onCloseKeyboard }) => {
    const fontSize = useAppSelector(selectFontSize);
    const { t } = useTranslation();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const dispatch = useAppDispatch();
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const volumeRef = useRef();
    const [showErrorOnce, setShowErrorOnce] = useState(false);
    const { message, onInputChange, onInputKeyDown, onSendClick, buttonState, charLimitReached } = useKeyboardInput();
    const classes = useStyles({ fontSize, buttonState });
    const isTextToSpeechV2 = useAppSelector(selectIsTextToSpeechV2);
    useEffect(() => {
        if (electronCaptionMode && window.electronIPC) {
            window.electronIPC.invokeGetVolume().then((volume) => {
                volumeRef.current = volume;
            });
        }
    }, []);
    useEffect(() => {
        if (volumeRef.current !== undefined &&
            volumeRef.current <= 50 &&
            isUsingTts &&
            message.length > 0 &&
            !showErrorOnce &&
            !isTextToSpeechV2) {
            dispatch(setErrorAndReset('unableToHear'));
            setShowErrorOnce(true);
        }
    }, [isUsingTts, message, isTextToSpeechV2]);
    return (React.createElement("div", { className: classes.root },
        React.createElement("section", { className: classes.inputContainer },
            React.createElement("button", { className: classes.closeKeyboardInputButton, onClick: onCloseKeyboard },
                React.createElement(KeyboardArrowUpIcon, { className: classes.keyboardArrowUpIcon })),
            React.createElement("div", { className: classes.messageContainer },
                React.createElement(Tooltip, { arrow: true, title: React.createElement(Typography, { className: classes.errorToolTip }, t('ccMode.keyboard.tooltip.charLimit')), open: charLimitReached },
                    React.createElement("textarea", { className: classes.input, rows: 1, placeholder: isUsingTts ? t('ccMode.keyboard.placeholderTTS') : t('conversation.typeTranscriptHere'), value: message, onChange: onInputChange, onKeyDown: onInputKeyDown, spellCheck: false, autoFocus: true }))),
            React.createElement(Tooltip, { classes: { tooltip: classes.tooltip, tooltipPlacementTop: classes.sendTooltipPlacementTop }, title: isUsingTts ? t('ccMode.keyboard.sendAsVoiceMessage') : t('ccMode.keyboard.tooltip.send'), placement: "top", disableInteractive: true },
                React.createElement("div", { className: classes.sendButtonContainer },
                    React.createElement(IconButton, { disabled: buttonState === 'disabled', className: classes.sendButton, onClick: onSendClick, size: "large" },
                        React.createElement(SendIcon, { buttonState: buttonState })))),
            React.createElement("div", { className: classes.verticalSeparatorContainer },
                React.createElement("div", { className: classes.verticalSeparator })),
            isTextToSpeechV2 ? React.createElement(TtsButton, null) : React.createElement(V1TtsButton, null))));
};
export default KeyboardInput;
