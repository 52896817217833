import { makeStyles } from '@mui/styles';
import { Z_INDICES } from '../../../styles';
const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: Z_INDICES.inviteCard,
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        backgroundBlendMode: 'overlay',
        backdropFilter: 'blur(50px)',
        borderRadius: 8,
        overflow: 'hidden',
        WebkitAppRegion: 'no-drag',
    },
    header: {
        display: 'flex',
        height: 32,
    },
    tab: {
        flex: 1,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 30,
        paddingRight: 30,
        background: 'transparent',
        border: 'none',
        borderBottom: `1px solid ${theme.palette.ava.grey5}`,
        fontSize: 16,
        textAlign: 'center',
        outline: 'none',
        whiteSpace: 'nowrap',
        '&:hover': {
            background: 'linear-gradient(229.3deg, #000000 5.57%, #000E34 80.9%)',
        },
    },
    activeTab: {
        background: theme.palette.ava.deepBlue,
        borderBottom: `1px solid ${theme.palette.ava.deepBlue}`,
        '&:hover': {
            background: theme.palette.ava.deepBlue,
        },
    },
    panel: {
        height: '100%',
    },
    inviteFriendsPanel: {
        display: 'flex',
        padding: 16,
        justifyContent: 'center',
        fontSize: 16,
    },
    linkContainer: {
        flex: 1,
        display: 'inline-block',
        marginRight: 10,
        paddingLeft: 12,
        paddingRight: 12,
        height: 32,
        backgroundColor: theme.palette.ava.blue5,
        borderRadius: 8,
        overflowX: 'auto',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    avaLink: {
        // Line height is the height of the container. It is used to vertically
        // center the text. Flexbox cannot be used because right padding gets
        // ignored.
        lineHeight: '32px',
        color: theme.palette.ava.dark1,
    },
    avaName: {
        color: theme.palette.ava.blue1,
    },
    copyLink: {
        padding: 4,
        paddingLeft: 6,
        paddingRight: 6,
        flexShrink: 0,
        borderRadius: 8,
    },
    joinRoomPanel: {
        padding: 16,
    },
}));
export default useStyles;
