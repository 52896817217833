import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { selectAvaConnectAvailable } from '../../selectors/combined';
import { useAppSelector } from '../../store/store';
import { ConvoV2AvaConnect } from './ConvoV2AvaConnect';
import { ConvoV2ConversationLinkButton } from './ConvoV2ConversationLinkButton';
import { ConvoV2ParticipantListBadge } from './ConvoV2ParticipantListBadge';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
        alignItems: 'center',
        width: '100%',
        flexShrink: 0,
        height: 96,
        padding: '0px 40px',
        backgroundColor: theme.palette.ava.white,
        borderLeft: `1px solid ${theme.palette.ava.grey7}`,
        borderBottom: `1px solid ${theme.palette.ava.grey7}`,
    },
    rootMobile: {
        padding: '0px 16px',
    },
    filler: {
        flexGrow: 1,
    },
}));
export const ConvoV2ConversationHeader = React.memo(({ onSidebarToggle }) => {
    const classes = useStyles();
    const theme = useTheme();
    const avaConnectAvailable = useAppSelector(selectAvaConnectAvailable);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) && !window.isElectron;
    return (React.createElement(Box, { className: classNames(classes.root, { [classes.rootMobile]: isMobile }) },
        isMobile && (React.createElement(React.Fragment, null,
            React.createElement(MenuOutlinedIcon, { onClick: onSidebarToggle }),
            React.createElement(Box, { className: classes.filler }))),
        avaConnectAvailable && React.createElement(ConvoV2AvaConnect, null),
        !isMobile && React.createElement(Box, { className: classes.filler }),
        React.createElement(ConvoV2ParticipantListBadge, null),
        React.createElement(ConvoV2ConversationLinkButton, null)));
});
