var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 48,
        minWidth: 44,
        height: 48,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
    },
}));
export const ConvoV2ControlBarButton = React.memo((_a) => {
    var { children, className, title } = _a, props = __rest(_a, ["children", "className", "title"]);
    const classes = useStyles();
    return (React.createElement(Tooltip, { title: title },
        React.createElement(Button, Object.assign({}, props, { className: classNames(classes.root, className) }), children)));
});
