import React from 'react';
import GoogleMeetIcon from './GoogleMeetIcon';
import MicrosoftTeamsIcon from './MicrosoftTeamsIcon';
import ZoomIcon from './ZoomIcon';
export const MeetingServiceIcon = React.memo(({ service, className }) => {
    if (service === 'Zoom') {
        return React.createElement(ZoomIcon, { className: className });
    }
    else if (service === 'Google Meet') {
        return React.createElement(GoogleMeetIcon, { className: className });
    }
    else if (service === 'MS Teams') {
        return React.createElement(MicrosoftTeamsIcon, { className: className });
    }
    else {
        console.error(`Tried to render MeetingServiceIcon for unknown service:`, service);
        return null;
    }
});
