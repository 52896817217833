import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSpeechLangSwitcher } from '../../hooks/useSpeechLangSwitcher';
export function ConvoV2SpeechLangSelector({ className }) {
    const { activeSpeechLang, allSpeechLangs, switchSpeechLang } = useSpeechLangSwitcher();
    return (React.createElement(Select, { className: className, value: activeSpeechLang === null || activeSpeechLang === void 0 ? void 0 : activeSpeechLang.value, onChange: (event) => switchSpeechLang(event.target.value), IconComponent: KeyboardArrowDownIcon, 
        // Without this, the whole settings card is hidden as soon as you open the select
        MenuProps: { disablePortal: true } }, allSpeechLangs.map((speechLang) => (React.createElement(MenuItem, { key: speechLang.value, value: speechLang.value }, speechLang.label)))));
}
