import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SnackbarContent } from 'notistack';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    inner: {
        display: 'inline-flex',
        gap: 16,
        padding: '12px 24px',
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: theme.palette.ava.white,
        fontWeight: 400,
        fontSize: 16,
        borderRadius: 100,
    },
}));
const ConvoV2Notification = React.forwardRef(({ icon, message }, ref) => {
    const classes = useStyles();
    return (React.createElement(SnackbarContent, { className: classes.wrapper, ref: ref },
        React.createElement(Box, { className: classes.inner },
            icon,
            message)));
});
export default ConvoV2Notification;
