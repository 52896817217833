import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.ava.white,
        width: 16,
        height: 16,
        borderRadius: 3,
    },
}));
export const ConvoV2StopIcon = React.memo(() => {
    const classes = useStyles();
    return React.createElement(Box, { className: classes.root });
});
