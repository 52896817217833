var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { selectOpenTauriAttempted } from '../selectors/ui';
import { fetchCustomToken, users } from '../services/api/saas';
import { setOpenTauriAttempted } from '../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../store/store';
import { isMobile } from '../utils';
import { simulateClick } from '../utils/domUtils';
import * as segment from '../utils/segment';
export function useLaunchApp() {
    const tauriAttempted = useAppSelector(selectOpenTauriAttempted);
    const dispatch = useAppDispatch();
    const launchApp = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        // If we're already in app, do nothing
        if (window.isElectron) {
            return { launchTried: false };
        }
        if (isMobile()) {
            // On mobile, immediately
            // let branch do the work
            window.open('https://ava.app.link/download');
            return { launchTried: false };
        }
        // On desktop web try to open the the app, and show the modal in case
        // the link does not open for the user.
        if (tauriAttempted) {
            simulateClick(yield users.getCustomTokenURL());
            segment.track('Web - Open Electron attempted');
        }
        else {
            const customToken = yield fetchCustomToken();
            const tauriLink = 'ava.cc://login-token/' + customToken;
            segment.track('Web - Open Tauri attempted');
            simulateClick(tauriLink);
            dispatch(setOpenTauriAttempted(true));
        }
        return { launchTried: true };
    }), [tauriAttempted, dispatch]);
    return { launchApp };
}
