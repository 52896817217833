var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Box, ListItemIcon, Slider, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { scribeSetFontSizeAndSave } from '../../actions';
import { selectFontSize } from '../../selectors/ui';
import { useAppDispatch, useAppSelector } from '../../store/store';
// When clicking the buttons to increase or decrease the font size,
// the current font size has this added or subtracted from it.
const FONT_SIZE_STEP = 1;
const MIN_FONT_SIZE = 13;
const MAX_FONT_SIZE = 50;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    stepSize: {
        color: theme.palette.contrast[theme.palette.mode],
        cursor: 'pointer',
        opacity: 0.9,
        minWidth: 0,
    },
    decreaseSize: {
        marginRight: 12,
    },
    increaseSize: {
        marginRight: 0,
        marginLeft: 12,
    },
    slider: {
        flexGrow: 1,
    },
}));
export const FontSizeInput = React.memo((_a) => {
    var _b, _c, _d, _e;
    var { className, onSizeChanged } = _a, props = __rest(_a, ["className", "onSizeChanged"]);
    const classes = useStyles();
    const { t } = useTranslation();
    const fontSize = useAppSelector(selectFontSize);
    const dispatch = useAppDispatch();
    const setFontSize = useCallback((size) => {
        dispatch(scribeSetFontSizeAndSave(size));
        onSizeChanged === null || onSizeChanged === void 0 ? void 0 : onSizeChanged();
    }, [dispatch, scribeSetFontSizeAndSave, onSizeChanged]);
    const onDecreaseFontSize = useCallback(() => {
        setFontSize(Math.max(fontSize - FONT_SIZE_STEP, MIN_FONT_SIZE));
    }, [setFontSize, fontSize]);
    const onIncreaseFontSize = useCallback(() => {
        setFontSize(Math.min(fontSize + FONT_SIZE_STEP, MAX_FONT_SIZE));
    }, [setFontSize, fontSize]);
    const onChangeFontSize = useCallback((event, value) => {
        setFontSize(value);
    }, [setFontSize]);
    return (React.createElement(Box, { className: classNames(classes.root, className) },
        React.createElement(Tooltip, { disableInteractive: true, title: t('theme.decreaseFontSize') },
            React.createElement(ListItemIcon, { className: classNames(classes.stepSize, classes.decreaseSize, (_b = props.classNames) === null || _b === void 0 ? void 0 : _b.stepSize, (_c = props.classNames) === null || _c === void 0 ? void 0 : _c.decreaseSize), onClick: onDecreaseFontSize },
                React.createElement(RemoveOutlinedIcon, null))),
        React.createElement(Slider, { className: classes.slider, value: fontSize, onChange: onChangeFontSize, min: MIN_FONT_SIZE, max: MAX_FONT_SIZE, step: FONT_SIZE_STEP }),
        React.createElement(Tooltip, { disableInteractive: true, title: t('theme.increaseFontSize') },
            React.createElement(ListItemIcon, { className: classNames(classes.stepSize, classes.increaseSize, (_d = props.classNames) === null || _d === void 0 ? void 0 : _d.stepSize, (_e = props.classNames) === null || _e === void 0 ? void 0 : _e.increaseSize), onClick: onIncreaseFontSize },
                React.createElement(AddOutlinedIcon, null)))));
});
