import { useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectIsHost, selectSpeechLang } from '../selectors/legacy-conversation';
import { selectRecallAIError, selectRecallAIStatus } from '../selectors/recallAI';
import { selectElectronCaptionMode } from '../selectors/ui';
import { addNotification } from '../store/slices/ccMode';
import { setConnectToMeetingsOpen } from '../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../store/store';
import { useNotifications } from './useNotifications';
import useRecallAIManager from './useRecallAIManager';
const MEETING_LINK_REGEXES = {
    Zoom: /(https:\/\/[\w-]*\.?zoom\.us\/(j|my)\/[\d\w?=-]+)/,
    'Google Meet': /(?:https?:\/\/)?(?:[\w-]+\.)?meet\.google\.com\/[\w-]+/,
    // teams has different urls depending on business or personal accounts
    // https://stackoverflow.com/a/72807772
    'MS Teams': /(https:\/\/(teams\.live\.com|teams\.microsoft\.com)\/[^\s]+)/,
};
// This hook builds on useRecallAIManager to provide the following elements used in the Ava Connect UI:
// * Validation of meeting links and detection of meeting link type
// * Texts to show during the entire process of connecting and disconnecting from a meeting
// * The next action a user can execute from connecting, to (comfirming) disconnection
// * Notifications on e.g. succeesful connection and error
// * Closing the notification card on successful connect on mobile
export const useAvaConnectUIHelpers = ({ isTabletOrMobile, notificationOptions, }) => {
    const { t } = useTranslation();
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const recallAIErrorMessage = useAppSelector(selectRecallAIError);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const isHost = useAppSelector(selectIsHost);
    const speechLang = useAppSelector(selectSpeechLang);
    const theme = useTheme();
    const verifiedMeetingLinkRef = useRef('');
    const [meetingLink, setMeetingLink] = useState('');
    const [meetingLinkService, setMeetingLinkService] = useState(undefined);
    const meetingLinkInvalid = meetingLink.length > 0 && !meetingLinkService;
    const meetingLinkEditable = recallAIStatus === 'NOT_CONNECTED' && isHost;
    const connected = recallAIStatus === 'CONNECTED' || recallAIStatus === 'CAPTIONING';
    const canConnectBot = verifiedMeetingLinkRef.current.length > 0 && meetingLinkService;
    const { notify } = useNotifications();
    const connecting = recallAIStatus === 'LOADING' || recallAIStatus === 'WAITING_FOR_HOST' || recallAIStatus === 'CONNECTING_TO_AUDIO';
    const dispatch = useAppDispatch();
    const recallAIManager = useRecallAIManager();
    const connectBot = useCallback(() => {
        recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.createBot(speechLang, verifiedMeetingLinkRef.current, true);
    }, [recallAIManager, speechLang, verifiedMeetingLinkRef.current]);
    const sendNotification = useCallback((notification, link) => {
        if (electronCaptionMode) {
            dispatch(addNotification({
                text: t(notification),
                timestamp: Date.now(),
                type: 'default',
                link,
                linkText: link,
            }));
        }
        else {
            notify(t(notification), Object.assign({ autoHideDuration: 5000, anchorOrigin: { vertical: 'top', horizontal: 'center' }, style: {
                    marginTop: '50px',
                    textAlign: 'center',
                }, color: theme.palette.mode === 'dark' ? 'black' : 'white' }, notificationOptions), link ? { url: link, text: link } : undefined);
        }
    }, [electronCaptionMode, dispatch, addNotification, notify, theme]);
    const prepareDisconnectBot = useCallback(() => recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.prepareDisconnect(), [recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.prepareDisconnect]);
    const disconnectBot = useCallback(() => {
        if (recallAIManager) {
            recallAIManager.destroyBot();
            setMeetingLink('');
            verifiedMeetingLinkRef.current = '';
            sendNotification('connectToOnlineMeetings.disconnected');
            dispatch(setConnectToMeetingsOpen(false));
        }
    }, [recallAIManager, setMeetingLink, verifiedMeetingLinkRef, sendNotification, setConnectToMeetingsOpen]);
    let action = undefined;
    let actionLabel = undefined;
    let connectionMessage = undefined;
    if (recallAIStatus === 'NOT_CONNECTED') {
        action = canConnectBot ? connectBot : undefined;
        actionLabel = t('connectToOnlineMeetings.connect');
    }
    else if (recallAIStatus === 'LOADING') {
        connectionMessage = t('connectToOnlineMeetings.loading');
        //   action = connectBot;
    }
    else if (recallAIStatus === 'WAITING_FOR_HOST') {
        connectionMessage = t('connectToOnlineMeetings.waitingForHost');
    }
    else if (recallAIStatus === 'CONNECTING_TO_AUDIO') {
        connectionMessage = t('connectToOnlineMeetings.connectingToAudio');
    }
    else if (recallAIStatus === 'CONNECTED') {
        connectionMessage = t('connectToOnlineMeetings.connectedBot');
    }
    else if (recallAIStatus === 'CAPTIONING') {
        connectionMessage = t('connectToOnlineMeetings.connectedBot');
        action = isHost ? prepareDisconnectBot : undefined;
    }
    else if (recallAIStatus === 'DISCONNECT') {
        action = disconnectBot;
        actionLabel = t('connectToOnlineMeetings.disconnectBot');
    }
    else if (recallAIErrorMessage && recallAIErrorMessage !== 'connectToOnlineMeetings.failed') {
        connectionMessage = t(recallAIErrorMessage);
    }
    const onMeetingLinkKeyDown = useCallback((event) => {
        if (event.key === 'Enter' && canConnectBot) {
            connectBot();
        }
    }, [canConnectBot, connectBot]);
    const onMeetingLinkChange = useCallback((event) => {
        setMeetingLink(event.currentTarget.value);
    }, [setMeetingLink]);
    useEffect(() => {
        if (meetingLink.length === 0) {
            setMeetingLinkService(undefined);
            return;
        }
        for (const [service, regex] of Object.entries(MEETING_LINK_REGEXES)) {
            if (regex.test(meetingLink)) {
                // fix long link tricking the backend:
                const match = meetingLink.match(regex);
                if (match)
                    verifiedMeetingLinkRef.current = match[0];
                setMeetingLinkService(service);
                break;
            }
        }
    }, [meetingLink]);
    useEffect(() => {
        switch (recallAIStatus) {
            case 'CONNECTED': {
                sendNotification('connectToOnlineMeetings.captioning');
                break;
            }
            case 'CAPTIONING': {
                // close modal if on mobile
                if (isTabletOrMobile)
                    dispatch(setConnectToMeetingsOpen(false));
                break;
            }
            case 'DISCONNECT': {
                break;
            }
            case 'ERROR':
                if (recallAIErrorMessage) {
                    if (recallAIErrorMessage !== 'connectToOnlineMeetings.failed') {
                        const link = recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.link;
                        sendNotification(recallAIErrorMessage, link);
                    }
                }
                break;
        }
    }, [recallAIStatus]);
    return {
        // Link entered in the connect input field by the user collected through
        // the `onMeetingLinkChange` input field event handler.
        meetingLink,
        // If a valid link has been entered, this will be set to the meeting service
        // of the link (determined by the MEETING_LINK_REGEXES),
        // even if we're not connected to the meeting yet.
        meetingLinkService,
        // Whether to show the user they've entered text in the text field that does not match
        // any of the MEETING_LINK_REGEXES. False if text field is empty.
        meetingLinkInvalid,
        // It only makes sense to edit the meeting link if you're not connected to a
        // meeting yet for any reason, so you can use this to disable the text input field
        // in case you do want to still show the link (e.g. confirming disconnect).
        meetingLinkEditable,
        // Key down handler for the text input field, which detects enter key presses
        // as an alternative to clicking the button to connect to a meeting.
        onMeetingLinkKeyDown,
        // Collects the text input value and puts in in `meetingLink`.
        onMeetingLinkChange,
        // Function triggering the next action the user can take like connecting to a meeting or
        // initiating disconnect. Undefined if no action can be taken, such as
        // during the different connection stages. Should be triggered when clicking
        // the button showing `actionLabel`.
        action,
        // The text to show on the button or tooltip of what `action` will do,
        // such as "Connect". Undefined when action is undefined.
        actionLabel,
        // The message to show to the user corresponding to a Recall AI status.
        // Undefined for statuses that do not require a message be shown.
        connectionMessage,
        // Is a Recall AI status active representing being successfully connected to a meeting?
        connected,
        // Is a Recall AI status active representing any stage of an establishing connection?
        connecting,
    };
};
