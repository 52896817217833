import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles({
    title: {
        fontWeight: 500,
    },
});
const PageTitle = ({ children, dataQA }) => {
    const classes = useStyles();
    return (React.createElement(Typography, { className: classes.title, variant: "h5", "data-qa": dataQA }, children));
};
export default PageTitle;
