/* eslint-disable no-shadow */
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { CircularProgress, Tooltip, useMediaQuery } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import makeComponentTrashable from 'trashable-react';
import AvaLogoImg from '../../../assets/images/logo.png';
import { fetchRemainingCredits } from '../../actions/session';
import ConnectToMeetingsContainer from '../../modules/AvaConnect/ConnectToMeetingsContainer';
import ConnectToMeetingsMobileContainer from '../../modules/AvaConnect/ConnectToMeetingsMobileContainer';
import OneClickScribeButtonV1 from '../../modules/oneClickScribe/OneClickScribeButtonV1';
import AddParticipant from '../../modules/scribe/AddParticipant';
import AudioChunkBuffer from '../../modules/scribe/AudioChunkBuffer';
import { BackendBadge, getBadge } from '../../modules/scribe/BackendBadge';
import CallList from '../../modules/scribe/CallList';
import FullScreenHamburger from '../../modules/scribe/FullScreenHamburger';
import ScribeSpeakerAudioSwitch from '../../modules/scribe/ScribeSpeakerAudioSwitch';
import SelectAccuracyButton from '../../modules/scribe/SelectAccuracyButton';
import UpdateConvoMeta from '../../modules/scribe/UpdateConvoMeta';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectAvaConnectAvailable, selectQuickSetupShown, selectUserRoleInConversation, UserRoleInConversation, } from '../../selectors/combined';
import { selectInTwilioCalls, selectIsInConversation } from '../../selectors/conversation';
import { selectIsDefaultRoomId, selectIsHost, selectParticipantsLength, selectStatusConversationMode, selectStatusLinksRo, selectStatusPast, selectStatusRoomId, selectStatusTitle, selectTranscriptOwnersLength, } from '../../selectors/legacy-conversation';
import { selectDisplayShortcuts, selectElectronCaptionMode, selectFullScreen, selectIsConnectToMeetingOpen, selectIsSideBarOpen, } from '../../selectors/ui';
import { getScribeRemainingTime, selectAvaId, selectFeature } from '../../selectors/userProfile';
import { selectBackends, selectDisplayDev } from '../../selectors/v1Session';
import { setFullScreen } from '../../store/slices/uiState';
import { toggleSideBar } from '../../store/slices/uiState';
import { HAVENT_FETCHED_SCRIBE_CREDITS } from '../../store/slices/userProfile';
import { useAppDispatch } from '../../store/store';
import { useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import { SNCF } from '../../utils';
import { useShouldShowNewSidebarAndHeader } from '../../utils/layout';
import AppBar from '../AppBar';
import JoinConversationButton from '../JoinConversation/Button';
import ParticipantList from '../ParticipantList';
import HomeView from './HomeView';
import { SNCFConsentButton } from './SNCFConsentButton';
const getConversationModeColor = (conversationMode) => {
    switch (conversationMode) {
        case 'public-muted':
            return 'darkgreen';
        case 'public':
            return 'darkhaki';
        case 'private':
            return 'tomato';
        case 'group':
            return 'steelblue';
        default:
            return 'red';
    }
};
const hideHeaderButtonsText = (width, participantCount, isConnectToMeetingsOpen) => {
    // width of logo + hamburger
    const logoWidth = 300;
    // aprox width of buttons based on language
    const buttonsWidth = i18next.language === 'fr' ? 500 : 430;
    // width of all avatars
    const avatarWidth = participantCount * 52;
    // recallAI adds more density:
    const recallWidth = isConnectToMeetingsOpen ? 220 : 150;
    return logoWidth + buttonsWidth + avatarWidth + recallWidth > width;
};
const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.border}`,
        },
        signIn: {
            '&:hover': {
                color: theme.palette.text.primary,
            },
            color: theme.palette.text.primary,
            fontWeight: 600,
            marginRight: 4,
        },
        windowsTitlebarButton: {
            '&:focus': {
                outline: 'none',
            },
            background: 'transparent',
            border: 'none',
            cursor: 'default',
            fontSize: 12,
            height: '100%',
            padding: 0,
            WebkitAppearance: 'none',
            width: 46,
        },
        windowsTitlebarButtonLight: {
            '&:hover': {
                background: 'rgba(23,23,23,0.1)',
            },
            '&:active': {
                background: 'rgba(23,23,23,0.2)',
            },
            '&>svg>g': {
                fill: '#171717',
            },
        },
        windowsTitlebarButtonDark: {
            '&:hover': {
                background: 'rgba(255,255,255,0.1)',
            },
            '&:active': {
                background: 'rgba(255,255,255,0.2)',
            },
            color: '#FFFFFF',
        },
        windowsTitlebarCloseButton: {
            '&:hover': {
                background: '#E81123',
                '&>svg>g': {
                    fill: '#FFFFFF',
                },
            },
            '&:active': {
                background: '#F1707A',
                '&>svg>g': {
                    fill: '#171717',
                },
            },
        },
        scribeLogo: {
            margin: '0px',
            marginBottom: '5px',
            marginLeft: '10px',
            '--webkit-app-region': 'drag',
        },
        logo: {
            width: '100px',
        },
        joinConversation: {
            display: 'flex',
            alignItems: 'center',
        },
        existingRoom: {
            color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
            fontSize: 24,
            lineHeight: '31.2px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        arrowRight: {
            marginRight: 11,
            color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
            fontSize: 24,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    };
});
function ScribeHeader({ className }) {
    let ref;
    const [hideText, setHideText] = useState(false);
    const theme = useTheme();
    const classes = useStyles();
    const displayDev = useAppSelector(selectDisplayDev);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const avaId = useAppSelector(selectAvaId);
    const fullScreen = useAppSelector(selectFullScreen);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const displayShortcuts = useAppSelector(selectDisplayShortcuts);
    const isSidebarOpen = useAppSelector(selectIsSideBarOpen);
    const backends = useAppSelector(selectBackends);
    const userRoleIsScribe = useAppSelector(selectUserRoleInConversation) === UserRoleInConversation.SCRIBE;
    const isInConversation = useAppSelector(selectIsInConversation);
    const inTwilioCalls = useAppSelector(selectInTwilioCalls);
    const dispatch = useAppDispatch();
    const tabletOrMobile = useMediaQuery(theme.breakpoints.down('md')) && !window.isElectron;
    const isHost = useAppSelector(selectIsHost);
    const isConnectToMeetingsOpen = useAppSelector(selectIsConnectToMeetingOpen);
    const quickSetupShown = useAppSelector(selectQuickSetupShown);
    const featureOk = useAppSelector(selectFeature('ok'));
    const avaConnectAvailable = useAppSelector(selectAvaConnectAvailable);
    const participantsLength = useAppSelector(selectParticipantsLength);
    const transcriptOwnersLength = useAppSelector(selectTranscriptOwnersLength);
    const roomId = useAppSelector(selectStatusRoomId);
    const defaultRoomId = useAppSelector(selectIsDefaultRoomId);
    const statusConversationMode = useAppSelector(selectStatusConversationMode);
    const statusTitle = useAppSelector(selectStatusTitle);
    const statusLinksRo = useAppSelector(selectStatusLinksRo);
    const statusPast = useAppSelector(selectStatusPast);
    const scribeRemainingTime = useAppSelector(getScribeRemainingTime);
    const hostIsInConversation = Boolean(roomId && !defaultRoomId && isHost && isInConversation);
    const location = useLocation();
    const { t } = useTranslation();
    const handleUpdateFullScreen = useCallback((e) => {
        e.preventDefault();
        dispatch(setFullScreen(!fullScreen));
        dispatch(toggleSideBar());
    }, [fullScreen]);
    useEffect(() => {
        if (ref && ref.off) {
            ref.off();
        }
    }, []);
    useEffect(() => {
        if (firebaseUser) {
            Sentry.setUser({ email: firebaseUser.email, id: avaId });
        }
    }, [avaId, firebaseUser]);
    const backgroundColor = theme.palette.secondary[theme.palette.mode];
    const backend = getBadge(backends);
    useEffect(() => {
        const handleResize = () => {
            setHideText(hideHeaderButtonsText(window.innerWidth, participantsLength + transcriptOwnersLength, isConnectToMeetingsOpen));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [participantsLength, transcriptOwnersLength]);
    const showNewSidebarAndHeader = useShouldShowNewSidebarAndHeader({
        electronCaptionMode,
        firebaseUser,
        isInConversation,
    });
    useEffect(() => {
        if (avaId && hostIsInConversation && scribeRemainingTime === HAVENT_FETCHED_SCRIBE_CREDITS) {
            fetchRemainingCredits(avaId)(dispatch);
        }
    }, [hostIsInConversation, scribeRemainingTime, avaId]);
    if (quickSetupShown)
        return null;
    if (showNewSidebarAndHeader) {
        return (React.createElement(HomeView, { className: className, style: {
                width: isSidebarOpen ? 'calc(100% - 272px)' : 'calc(100% - 90px)',
            } }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, { className: classnames(classes.appBar, className), style: {
                zIndex: Z_INDICES.headerAppBar,
                display: fullScreen ? 'none' : 'flex',
            } },
            React.createElement(Toolbar, { style: {
                    background: backgroundColor,
                    height: 67,
                    justifyContent: 'center',
                    display: fullScreen ? 'none' : 'flex',
                } },
                firebaseUser && !location.pathname.includes('/login') && (
                // eslint-disable-next-line
                React.createElement("div", { 
                    // {...getTogglerProps()}
                    role: "button", onClick: () => dispatch(toggleSideBar()), style: {
                        outline: 'none',
                        display: 'flex',
                    } },
                    React.createElement(MenuOutlinedIcon, { style: {
                            fontSize: 30,
                            marginRight: 20,
                            cursor: 'pointer',
                            color: theme.palette.text.primary,
                        } }))),
                React.createElement(Hidden, { smDown: true }, isInConversation ? (React.createElement("img", { className: classes.logo, src: AvaLogoImg, alt: "ava logo", "data-qa": "avaLogo" })) : (React.createElement("a", { href: "/", "data-qa": "avaLogo" },
                    React.createElement("img", { className: classes.logo, src: AvaLogoImg, alt: "ava logo" })))),
                userRoleIsScribe && React.createElement(ScribeSpeakerAudioSwitch, null),
                React.createElement(CallList, null),
                userRoleIsScribe && React.createElement(AudioChunkBuffer, null),
                roomId && !defaultRoomId && isInConversation && (React.createElement(ParticipantList, { displayShortcuts: displayShortcuts, scribe: userRoleIsScribe, share: true, theme: theme })),
                React.createElement("div", { style: { flexGrow: 1 } }),
                displayDev && (React.createElement(Hidden, { smDown: true },
                    React.createElement(BackendBadge, { style: {
                            backgroundColor: backend.backgroundColor,
                        } },
                        backend.name,
                        featureOk))),
                userRoleIsScribe && statusConversationMode && (React.createElement(BackendBadge, { style: {
                        backgroundColor: getConversationModeColor(statusConversationMode),
                    } }, statusConversationMode)),
                userRoleIsScribe && statusTitle !== undefined && (React.createElement(UpdateConvoMeta, { title: statusTitle, roLink: statusLinksRo || '' })),
                React.createElement(Tooltip, { disableInteractive: true, title: !isHost ? t('connectToOnlineMeetings.onlyHost') : t('connectToOnlineMeetings.title'), "aria-label": "share-link", enterDelay: 300, leaveDelay: 200 },
                    React.createElement("div", { style: { order: `${tabletOrMobile ? 1 : 0}` } }, avaConnectAvailable && tabletOrMobile ? (React.createElement("div", null,
                        React.createElement(ConnectToMeetingsMobileContainer, null))) : avaConnectAvailable && !tabletOrMobile ? (React.createElement(ConnectToMeetingsContainer, { hideText: hideText })) : (React.createElement("div", { style: { display: 'none' } })))),
                React.createElement(Hidden, { smDown: true },
                    React.createElement("div", { style: { flexGrow: 1 } }),
                    React.createElement("div", { style: { flexGrow: 1 } })),
                React.createElement("div", { style: { flexGrow: 1 } }),
                hostIsInConversation && scribeRemainingTime === HAVENT_FETCHED_SCRIBE_CREDITS ? (React.createElement("div", { style: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 20px' } },
                    React.createElement(CircularProgress, { size: '1.5rem' }))) : hostIsInConversation && scribeRemainingTime !== undefined && scribeRemainingTime > 0 ? (React.createElement(OneClickScribeButtonV1, { hideText: hideText })) : hostIsInConversation && scribeRemainingTime !== undefined && scribeRemainingTime <= 0 ? (React.createElement(SelectAccuracyButton, { hideText: hideText })) : (React.createElement(React.Fragment, null)),
                roomId && !statusPast && transcriptOwnersLength > 0 && !defaultRoomId && isInConversation && (React.createElement(React.Fragment, null, !inTwilioCalls && React.createElement(AddParticipant, { scribe: userRoleIsScribe, hideText: hideText }))),
                !window.isElectron && (!avaId || (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous)) && location.pathname === '/web/login' && (React.createElement("div", { className: classes.joinConversation },
                    React.createElement(JoinConversationButton, { header: true }))),
                SNCF && isInConversation && React.createElement(SNCFConsentButton, null))),
        !electronCaptionMode && (React.createElement(FullScreenHamburger, { updateFullScreen: handleUpdateFullScreen, fullScreen: fullScreen }))));
}
export default makeComponentTrashable(ScribeHeader);
