import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useState } from 'react';
import { mouseDownListenerForDrag, mouseMoveListenerForDrag, mouseUpListenerForDrag, tauriWindowGetCurrent, } from '../services/desktopIntegration';
import { Z_INDICES } from '../styles';
import { getIsMac } from '../utils/electron';
import WindowsTitlebarButton from './Buttons/WindowsTitlebarButton';
const useStyles = makeStyles((theme) => {
    const backgroundColor = theme.palette.secondary[theme.palette.mode];
    const lineColor = theme.palette.divider;
    return {
        windowFramePadding: {
            height: (props) => (props.isMac ? 28 : 32),
            backgroundColor,
        },
        appBar: {
            WebkitAppRegion: 'drag',
            position: 'fixed',
            alignItems: 'center',
            width: '100%',
            backgroundColor,
            borderBottom: `solid 1px ${lineColor}`,
            zIndex: Z_INDICES.desktopExpandedAppBar,
        },
        macAppBar: {
            top: 0,
            left: 0,
            height: 28,
        },
        notMacAppBar: {
            top: 2,
            left: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            height: 30,
        },
        titleBarBtns: {
            WebkitAppRegion: 'no-drag',
            height: '100%',
        },
    };
});
const DesktopExpandedAppBar = ({ className }) => {
    const isMac = getIsMac();
    const currentWindow = window.electronCurrentWindow;
    const classes = useStyles({ isMac });
    const [electronWindowMaximized, setElectronWindowMaximized] = useState(false);
    const maybeStartDragging = () => {
        var _a;
        if (window.__TAURI__) {
            (_a = tauriWindowGetCurrent()) === null || _a === void 0 ? void 0 : _a.startDragging();
        }
    };
    return (React.createElement("div", { className: classnames(className) },
        React.createElement("div", { className: classnames(classes.windowFramePadding, 'window-frame-padding') }),
        isMac && React.createElement("div", { className: classnames(classes.appBar, classes.macAppBar), onMouseDown: maybeStartDragging }),
        !isMac && (React.createElement("div", { id: "title-bar", onMouseDown: mouseDownListenerForDrag, onMouseUp: mouseUpListenerForDrag, onMouseMove: mouseMoveListenerForDrag, className: classnames(classes.appBar, classes.notMacAppBar) },
            React.createElement("div", { id: "title-bar-btns", className: classes.titleBarBtns },
                React.createElement(WindowsTitlebarButton, { id: "min-btn", onClick: () => currentWindow.minimize() },
                    React.createElement("svg", { width: "46", height: "32", viewBox: "0 0 46 32" },
                        React.createElement("g", { id: "minimize", stroke: "none", strokeWidth: "1", fillRule: "evenodd" },
                            React.createElement("polygon", { fillRule: "nonzero", points: "16 17 30 17 30 16 16 16" })))),
                React.createElement(WindowsTitlebarButton, { id: "max-btn", onClick: () => {
                        if (electronWindowMaximized) {
                            currentWindow.unmaximize();
                        }
                        else {
                            currentWindow.maximize();
                        }
                        setElectronWindowMaximized(!electronWindowMaximized);
                    } }, electronWindowMaximized ? (React.createElement("svg", { width: "46", height: "32", viewBox: "0 0 46 32" },
                    React.createElement("g", { id: "unmaximize", stroke: "none", strokeWidth: "1", fillRule: "evenodd" },
                        React.createElement("path", { d: "M18.3310547,23 L30,23 L30,11.3310547 L27.6689453,11.3310547 L27.6689453,9 L16,9 L16,20.6689453 L18.3310547,20.6689453 L18.3310547,23 Z M26.5,10.1689453 L26.5,19.5 L17.1689453,19.5 L17.1689453,10.1689453 L26.5,10.1689453 Z M28.8310547,12.5 L28.8310547,21.8310547 L19.5,21.8310547 L19.5,20.6689453 L27.6689453,20.6689453 L27.6689453,12.5 L28.8310547,12.5 Z", fillRule: "nonzero" })))) : (React.createElement("svg", { width: "46", height: "32", viewBox: "0 0 46 32" },
                    React.createElement("g", { id: "close", fillRule: "evenodd", stroke: "none", strokeWidth: "1" },
                        React.createElement("path", { fillRule: "nonzero", d: "M28.527 22a.473.473 0 00.473-.473V10.473a.474.474 0 00-.473-.473H17.473a.473.473 0 00-.473.473v11.054a.474.474 0 00.473.473h11.054zM17.923 11h10.154v10H17.923V11z" }))))),
                React.createElement(WindowsTitlebarButton, { id: "close-btn", type: "button", close: true, onClick: () => currentWindow.close() },
                    React.createElement("svg", { width: "46", height: "32", viewBox: "0 0 46 32" },
                        React.createElement("g", { id: "close", stroke: "none", strokeWidth: "1", fillRule: "evenodd" },
                            React.createElement("polygon", { fillRule: "nonzero", points: "23.2114478 15.3240981 28.5355459 10 29.4228956 10.8873497 24.0987975 16.2114478 29.4228956 21.5355459 28.5355459 22.4228956 23.2114478 17.0987975 17.8873497 22.4228956 17 21.5355459 22.3240981 16.2114478 17 10.8873497 17.8873497 10" })))))))));
};
export default DesktopExpandedAppBar;
