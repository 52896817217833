import HeadsetRoundedIcon from '@mui/icons-material/HeadsetRounded';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { selectHasScribe, selectPendingScribe } from '../../selectors/legacy-conversation';
import { setIsOneClickScribeModalOpen } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import OneClickScribeModal from '../oneClickScribe/OneClickScribeModal';
import { ConvoV2ControlBarButton } from './ConvoV2ControlBarButton';
const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: (props) => (props.hasScribe ? theme.palette.ava.grey8 : 'white'),
        '&:hover': {
            backgroundColor: (props) => (props.hasScribe ? theme.palette.ava.deepBlue : 'rgba(0, 0, 0, 0.04)'),
        },
    },
    icon: { color: (props) => (props.hasScribe ? 'white' : 'inherit') },
}));
function ConvoV2OneClickScribeButton() {
    const hasScribe = useAppSelector(selectHasScribe);
    const pendingScribe = useAppSelector(selectPendingScribe);
    const classes = useStyles({ hasScribe });
    const dispatch = useAppDispatch();
    const openModal = useCallback(() => {
        dispatch(setIsOneClickScribeModalOpen(true));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConvoV2ControlBarButton, { disabled: pendingScribe, onClick: openModal, className: classes.button },
            React.createElement(HeadsetRoundedIcon, { className: classes.icon })),
        React.createElement(OneClickScribeModal, { hasScribe: hasScribe })));
}
export default ConvoV2OneClickScribeButton;
