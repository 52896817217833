var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Button, IconButton, InputAdornment, Link, Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AppleLogoIcon from '../../../assets/icons/apple_logo.svg';
import EyeClosed from '../../../assets/icons/eye_closed.svg';
import EyeOpen from '../../../assets/icons/eye_open.svg';
import FacebookIcon from '../../../assets/icons/facebook_login.svg';
import Loading from '../../components/Loading';
import firebase, { appleProvider, facebookProvider, googleProvider } from '../../firebase';
import { useDebounce } from '../../hooks/useDebounce';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseLoginInProgress } from '../../selectors/combined';
import { selectLoading } from '../../selectors/ui';
import { createUserNameWithEmailAndPassword, fetchSignInMethods, getUserInstance, initiateFirebaseLoginWithProvider, loginWithEmailAndPassword, logout, sendResetPasswordEmail, } from '../../store/slices/auth';
import { setLoading as setShowLoader } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import AccountProviders from '../../utils/classes/AccountProviders';
import { firebaseFunctionsEndpoint } from '../../utils/http';
import { getAvaLanguageUrl } from '../../utils/i18n';
import { AuthErrorCategory, checkForSSOProvider, EmailErrorType, evaluatePassword, GeneralAuthErrorType, initiateSSOLogin, PasswordErrorType, validateEmail, validatePassword, } from '../../utils/onboardingV2';
import { sendEmailQueryMessage } from '../../utils/ws-v1';
import ScribeLoginEmailVerification from '../scribe/ScribeLoginEmailVerification';
import LoginTextField from './LoginTextField';
import LoginWithProviderButton from './LoginWithProviderButton';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import PhoneLogin from './PhoneLogin';
import SignUpSignInButton from './SignUpSignInButton';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '85%',
        maxWidth: '424px',
        height: '100%',
        background: theme.palette.ava.white,
        borderRadius: 20,
        padding: '20px 40px 32px 40px',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '20px 30px 32px 30px',
        },
        // tablet
        ['@media (min-width: 600px) and (max-width: 850px)']: {
            width: '95vw',
        },
    },
    title: {
        fontWeight: 600,
        color: theme.palette.ava.deepBlue,
        [theme.breakpoints.up('sm')]: {
            fontSize: 34,
        },
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    eyeButton: {
        padding: 0,
        marginRight: 0,
        background: 'transparent',
    },
    redirectLinks: {
        padding: 0,
        marginRight: 0,
        background: 'transparent',
        color: theme.palette.ava.blue2,
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: (props) => (props.fr ? 13 : 18),
        },
        '&:hover': {
            background: 'transparent',
            color: theme.palette.ava.deepBlue,
        },
    },
    lowerButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        gap: 16,
        marginTop: 16,
    },
    logoButtonText: {
        marginLeft: 10,
        fontSize: 18,
    },
    lineBreak: {
        width: '100%',
        color: theme.palette.ava.text1,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before, &::after': {
            content: '""',
            flex: '1 1',
            height: '1px',
            marginTop: 3,
            background: theme.palette.ava.grey7,
            marginLeft: 8,
            marginRight: 8,
        },
    },
    iconButton: {
        borderRadius: 100,
        boxShadow: '4px 2px 12px 0px rgba(112, 144, 176, 0.25)',
        padding: '12px 24px',
        width: 44,
    },
    phoneIcon: {
        color: theme.palette.ava.deepBlue,
        width: 24,
        height: 24,
    },
    fbIcon: {
        width: 20,
        height: 20,
    },
    notYou: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: 8,
    },
    notYouText: {
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: (props) => (props.fr ? 13 : 18),
        },
    },
    continuing: {
        [theme.breakpoints.up('md')]: { width: '70%' },
        color: theme.palette.ava.grey5,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    continuingText: {
        fontSize: (props) => (props.isMobileLandscape ? 14 : 16),
    },
    semiBold: {
        fontWeight: 600,
        color: theme.palette.ava.text1,
    },
    terms: {
        display: 'flex',
        gap: 2,
    },
    providerNotYou: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    back: {
        display: 'flex',
        width: '100%',
        marginRight: '15%',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-5%',
            marginRight: '10%',
            marginBottom: '5%',
        },
        // iphone SE landscape
        ['@media (min-width: 660px) and (min-height: 365px) and (max-width: 667px) and (max-height: 375px)']: {
            // marginTop: '-20%',
            marginRight: '10%',
        },
    },
    typeIn: {
        color: theme.palette.ava.grey5,
        marginBottom: '5%',
    },
}));
const SignInSignUp = forwardRef(({ setAuthError, setError, authError, mode, setMode, isMobileLandscape }, ref) => {
    var _a, _b, _c;
    const { t, i18n: { language }, } = useTranslation();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [emailToShow, setEmailToShow] = useState('');
    const debouncedEmail = useDebounce(email, 700);
    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('weak');
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [showRestOfAccountCreation, setShowRestOfAccountCreation] = useState(false);
    const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);
    const [waitForEmailVerification, setWaitForEmailVerification] = useState(false);
    const [showPasswordValue, setShowPasswordValue] = useState(false);
    const [phonePage, setPhonePage] = useState(false);
    const [loading, setLoading] = useState(false);
    const showLoader = useAppSelector(selectLoading);
    const [checkedForProviders, setCheckedForProviders] = useState(false);
    const classes = useStyles({
        showPasswordField,
        phonePage,
        mode,
        fr: language === 'fr',
        isMobileLandscape,
    });
    const accountProvidersRef = useRef(new AccountProviders().getProviders());
    const firebaseLoginInProgress = useAppSelector(selectFirebaseLoginInProgress);
    const showLoading = email.length > 0 && (firebaseLoginInProgress || loading);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [ws] = useV1Socket();
    useImperativeHandle(ref, () => ({ reset }));
    useEffect(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('onboardingV2', {
            size: 'invisible',
        });
    }, []);
    useEffect(() => {
        let timer;
        if (Object.keys(authError).length > 0) {
            timer = setTimeout(() => {
                setAuthError({});
            }, 3500);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [authError]);
    useEffect(() => {
        // make sure we only run this for sign-up
        if (showRestOfAccountCreation) {
            setPasswordStrength(evaluatePassword(password));
        }
    }, [password, showRestOfAccountCreation]);
    const doesAccountHaveLoginProviders = (email, reset) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        setCheckedForProviders(true);
        const ssoProvider = (_d = (yield checkForSSOProvider(email))) !== null && _d !== void 0 ? _d : '';
        if (ssoProvider) {
            tryLoginWithSSO(ssoProvider);
        }
        const providers = yield fetchSignInMethods(dispatch, email);
        if (providers.length === 0 || (providers.includes('password') && reset)) {
            return false;
        }
        const accountProviders = new AccountProviders();
        accountProviders.updateProviders(providers);
        accountProvidersRef.current = accountProviders.getProviders();
        if (reset && location.pathname.split('/')[1] === 'web') {
            navigate('/web/onboarding/account-exists');
        }
        return true;
    });
    const handleEmailChange = (email) => {
        setEmailToShow(email);
        setEmail(email.toLowerCase());
    };
    useEffect(() => {
        if (validateEmail(email)) {
            setAuthError({});
            if (ws) {
                sendEmailQueryMessage(ws, email);
                return;
            }
        }
        else if (email.includes('@') && !validateEmail(email)) {
            setError(AuthErrorCategory.Email, EmailErrorType.InvalidFormat);
        }
    }, [debouncedEmail, ws]);
    const sendVerificationEmailIfNeeded = () => __awaiter(void 0, void 0, void 0, function* () {
        const user = getUserInstance();
        if (!verificationEmailSent && user) {
            setLoading(true);
            yield fetch(`${firebaseFunctionsEndpoint}/widgets/sendEmailVerification?uid=${user.uid}`, { method: 'POST' });
            setLoading(false);
            setVerificationEmailSent(true);
            setWaitForEmailVerification(true);
        }
    });
    const reset = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(false);
        if (getUserInstance())
            logout(dispatch, true);
        setAuthError({});
        setEmail('');
        setEmailToShow('');
        setPassword('');
        setResetPasswordEmailSent(false);
        setShowPasswordField(false);
        setPasswordStrength('weak');
        setShowRestOfAccountCreation(false);
        setVerificationEmailSent(false);
        setWaitForEmailVerification(false);
        setCheckedForProviders(false);
        setPhonePage(false);
        if (name.length)
            setName('');
        accountProvidersRef.current = new AccountProviders().getProviders();
    });
    const loginWithProvider = (provider) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield initiateFirebaseLoginWithProvider(dispatch, provider);
        }
        catch (error) {
            if (error.code === 'auth/account-exists-with-different-credential') {
                // This doesn't work for Google as the provider, I can't test it with Facebook
                // or Apple before we push this to app-canary
                doesAccountHaveLoginProviders(error.email);
                if (mode === 'signup') {
                    setMode('signin');
                    navigate('/web/login', { replace: true });
                }
                if (location.pathname.split('/')[1] === 'web') {
                    navigate('/web/onboarding/account-exists');
                }
            }
            else {
                reset();
                Sentry.captureException(error);
            }
        }
    });
    const tryLoginWithSSO = (provider) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(setShowLoader(true));
        try {
            yield initiateSSOLogin(provider);
        }
        catch (error) {
            console.log(error);
            reset();
        }
    });
    const handleLoginCustomMail = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        setLoading(true);
        const response = yield loginWithEmailAndPassword(email, password);
        switch (response) {
            case 'unverified': {
                setLoading(false);
                setWaitForEmailVerification(true);
                break;
            }
            case 'success': {
                setLoading(false);
                break;
            }
            case 'auth/user-not-found': {
                setMode('signup');
                navigate('/web/signup', { replace: true });
                reset();
                break;
            }
            case 'auth/wrong-password': {
                setError(AuthErrorCategory.Password, PasswordErrorType.Wrong);
                setLoading(false);
                break;
            }
            default: {
                reset();
                Sentry.captureException(response);
                break;
            }
        }
    });
    const resetPassword = () => __awaiter(void 0, void 0, void 0, function* () {
        if (validateEmail(email)) {
            const hasOtherProviders = yield doesAccountHaveLoginProviders(email, true);
            if (!hasOtherProviders) {
                setLoading(true);
                try {
                    yield sendResetPasswordEmail(email);
                }
                finally {
                    setResetPasswordEmailSent(true);
                    setLoading(false);
                }
            }
        }
    });
    const disabled = () => {
        return (!validateEmail(email) ||
            (showPasswordField && !validatePassword(password)) ||
            (showRestOfAccountCreation && name.length < 2));
    };
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        event.preventDefault();
        // last step of signup, lets create an account
        if (showRestOfAccountCreation) {
            // if the password is weak, lets show the criteria:
            if (passwordStrength === 'weak') {
                setError(AuthErrorCategory.Password, PasswordErrorType.PasswordRequirements);
                setLoading(false);
                return;
            }
            else {
                try {
                    localStorage.setItem('onboarding_name_chosen', name);
                    yield createUserNameWithEmailAndPassword(email, password);
                    yield sendVerificationEmailIfNeeded();
                }
                catch (error) {
                    setLoading(false);
                    setError(AuthErrorCategory.General, GeneralAuthErrorType.unknown);
                }
            }
        }
        // check if user exists, if so change to signin
        // if not continue with signup
        try {
            const hasOtherProviders = yield doesAccountHaveLoginProviders(email);
            if (mode === 'signup') {
                if (!hasOtherProviders) {
                    // user does not exist
                    setShowPasswordField(true);
                    setLoading(false);
                    setShowRestOfAccountCreation(true);
                }
                else {
                    setMode('signin');
                    navigate('/web/login', { replace: true });
                    if (accountProvidersRef.current.email) {
                        setShowPasswordField(true);
                    }
                }
                setLoading(false);
            }
        }
        catch (error) {
            if (error.code === 'auth/invalid-email') {
                setError(AuthErrorCategory.Email, EmailErrorType.InvalidFormat);
                setLoading(false);
            }
        }
        if (mode === 'signin') {
            if ((accountProvidersRef.current.google ||
                accountProvidersRef.current.apple ||
                accountProvidersRef.current.facebook) &&
                !accountProvidersRef.current.email) {
                setLoading(false);
                return;
            }
            else if (accountProvidersRef.current.email) {
                if (showPasswordField) {
                    handleLoginCustomMail(event);
                }
                else {
                    setShowPasswordField(true);
                    setLoading(false);
                }
            }
            else {
                setMode('signup');
                setLoading(false);
                navigate('/web/signup', { replace: true });
            }
        }
    });
    if (showLoader) {
        return React.createElement(Loading, null);
    }
    if (waitForEmailVerification) {
        return React.createElement(ScribeLoginEmailVerification, { email: email, loading: showLoading, reset: () => reset() });
    }
    if (resetPasswordEmailSent) {
        return (React.createElement(Typography, { variant: "body1", align: "center", "data-qa": "password_reset_confirmation" }, t('signin-messages.resetLinkSent', { email })));
    }
    return (React.createElement(Paper, { elevation: 2, className: classes.container },
        (showRestOfAccountCreation || phonePage) && (React.createElement("div", { className: classes.back },
            React.createElement(Button, { className: classes.redirectLinks, startIcon: React.createElement(ArrowBackIcon, null), onClick: () => reset() }, t('onboardingV2.signup.back')))),
        !showRestOfAccountCreation && (React.createElement(Typography, { textAlign: 'center', className: classes.title, "data-qa": "signInTitle" }, phonePage
            ? t('onboardingV2.signin.continueWithPhone')
            : Object.values(accountProvidersRef.current).some((provider) => provider == true)
                ? t('onboardingV2.signin.welcomeBack')
                : mode === 'signin'
                    ? t('onboardingV2.signin.title')
                    : t('onboardingV2.signup.title'))),
        mode === 'signup' && !showRestOfAccountCreation && (React.createElement(React.Fragment, null,
            React.createElement(LoginWithProviderButton, { size: 'medium', provider: "google", onClick: () => loginWithProvider(googleProvider), dataQA: 'continueWithGoogleButton' }),
            React.createElement(Typography, { className: classes.lineBreak, textAlign: 'center' }, t('onboardingV2.signup.orSignUpWithEmail')))),
        phonePage && React.createElement(PhoneLogin, null),
        React.createElement("form", { className: classes.form, noValidate: true, onSubmit: (event) => handleSubmit(event) },
            !phonePage && (React.createElement(LoginTextField, { size: 'medium', value: emailToShow, label: showPasswordField || mode === 'signup' ? t('onboardingV2.labels.email') : '', name: "email", autoFocus: !showPasswordField, type: "email", onChange: (email) => handleEmailChange(email), placeholder: t('onboardingV2.signin.placeholder'), helperText: ((_a = authError.email) === null || _a === void 0 ? void 0 : _a.invalidFormat) || '', error: !!authError.email, endAdornment: showLoading && (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(CircularProgress, { size: 24 }))) })),
            showRestOfAccountCreation && (React.createElement(LoginTextField, { size: 'medium', name: "name", type: "text", autoFocus: true, label: t('onboardingV2.signup.name'), value: name, onChange: (name) => setName(name), placeholder: t('onboardingV2.signup.john') })),
            showPasswordField && (React.createElement(LoginTextField, { size: 'medium', name: "password", type: showPasswordValue ? 'text' : 'password', autoFocus: showPasswordField && !showRestOfAccountCreation, value: password, onChange: (password) => setPassword(password), helperText: ((_b = authError.password) === null || _b === void 0 ? void 0 : _b.passwordRequirements) || ((_c = authError.password) === null || _c === void 0 ? void 0 : _c.wrong), error: !!authError.password, placeholder: showRestOfAccountCreation ? t('onboardingV2.signup.createPassword') : '', endAdornment: showPasswordValue ? (React.createElement(IconButton, { className: classes.eyeButton, onClick: () => setShowPasswordValue(false), "data-qa": "showPasswordOrNot" },
                    React.createElement(InputAdornment, { position: "end" },
                        React.createElement("img", { src: EyeOpen, alt: "Show password" })))) : (React.createElement(IconButton, { className: classes.eyeButton, onClick: () => setShowPasswordValue(true), "data-qa": "showPasswordOrNot" },
                    React.createElement(InputAdornment, { position: "end" },
                        React.createElement("img", { src: EyeClosed, alt: "Don't show password" })))), label: t('onboardingV2.labels.password') })),
            showPasswordField && mode === 'signin' && (React.createElement(Button, { className: classes.redirectLinks, onClick: () => resetPassword(), "data-qa": "forgotPasswordLink" }, t('onboardingV2.signin.forgotPassword'))),
            showRestOfAccountCreation && password && React.createElement(PasswordStrengthIndicator, { passwordStrength: passwordStrength }),
            (mode === 'signup' ||
                !checkedForProviders ||
                ((accountProvidersRef.current.email || accountProvidersRef.current.sso) && checkedForProviders)) &&
                !phonePage && (React.createElement(SignUpSignInButton, { size: 'medium', firstButton: true, showPasswordField: showPasswordField, blue: true, showRestOfAccountCreation: showRestOfAccountCreation, disabled: disabled() || loading, dataQA: 'continueButton' }))),
        mode === 'signup' ? (React.createElement("div", { className: classes.continuing },
            React.createElement(Typography, { className: classes.continuingText },
                t('onboardingV2.signup.byContinuing'),
                ' ',
                React.createElement(Link, { className: classes.semiBold, "data-qa": "termsLink", underline: "hover", target: "_blank", href: `${getAvaLanguageUrl()}/terms` }, t('onboardingV2.signup.terms')),
                ' ',
                t('onboardingV2.signup.and'),
                ' ',
                React.createElement(Link, { className: classes.semiBold, target: "_blank", href: `${getAvaLanguageUrl()}/privacy`, "data-qa": "privacyLink", underline: "hover" }, t('onboardingV2.signup.privacy'))))) : mode === 'signin' &&
            (showPasswordField ||
                accountProvidersRef.current.google ||
                accountProvidersRef.current.apple ||
                accountProvidersRef.current.facebook) ? (React.createElement("div", { className: classes.providerNotYou },
            accountProvidersRef.current.google && (React.createElement(LoginWithProviderButton, { size: 'medium', provider: "google", onClick: () => loginWithProvider(googleProvider), dataQA: 'continueWithGoogleButton' })),
            accountProvidersRef.current.facebook && (React.createElement(LoginWithProviderButton, { size: 'medium', onClick: () => loginWithProvider(facebookProvider), provider: "facebook", dataQA: 'continueWithFacebookButton' })),
            accountProvidersRef.current.apple && (React.createElement(LoginWithProviderButton, { size: 'medium', onClick: () => loginWithProvider(appleProvider), provider: "apple", dataQA: 'continueWithAppleButton' })),
            accountProvidersRef.current.phone && (React.createElement(LoginWithProviderButton, { size: 'medium', onClick: () => setPhonePage(true), provider: "phone", dataQA: 'continueWithPhoneButton' })),
            React.createElement("div", { className: classes.notYou },
                React.createElement(Typography, { className: classes.notYouText }, t('onboardingV2.signin.notYou')),
                React.createElement(Button, { className: classes.redirectLinks, onClick: () => reset(), "data-qa": "useAnotherAccountLink" }, t('onboardingV2.signin.useAnotherAccount'))))) : !showRestOfAccountCreation && !phonePage ? (React.createElement(React.Fragment, null,
            (!checkedForProviders || accountProvidersRef.current.google) && (React.createElement(LoginWithProviderButton, { size: 'medium', provider: "google", onClick: () => loginWithProvider(googleProvider), dataQA: 'continueWithGoogleButton' })),
            React.createElement(Typography, { className: classes.lineBreak, textAlign: 'center' }, t('onboardingV2.signin.orContinue')),
            React.createElement("section", { className: classes.lowerButtonContainer },
                React.createElement(IconButton, { onClick: () => loginWithProvider(facebookProvider), className: classes.iconButton, "data-qa": "facebookIconLogin" },
                    React.createElement("img", { className: classes.fbIcon, src: FacebookIcon, alt: "Facebook Icon" })),
                React.createElement(IconButton, { onClick: () => loginWithProvider(appleProvider), className: classes.iconButton, "data-qa": "appleIconLogin" },
                    React.createElement("img", { className: classes.fbIcon, src: AppleLogoIcon, alt: "Apple Icon" })),
                React.createElement(IconButton, { onClick: () => {
                        setPhonePage(true);
                    }, className: classes.iconButton, "data-qa": "phoneIconLogin" },
                    React.createElement(LocalPhoneIcon, { className: classes.phoneIcon }))))) : (React.createElement(React.Fragment, null)),
        React.createElement("div", { style: { display: 'none' }, id: "onboardingV2" })));
});
export default SignInSignUp;
