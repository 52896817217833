var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        height: 48,
        borderRadius: 8,
        boxShadow: '0px 4px 2px rgba(112, 144, 176, 0.01)',
    },
    blue: {
        backgroundColor: theme.palette.ava.blue2,
        color: theme.palette.ava.white,
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
        },
        '&:active': {
            backgroundColor: theme.palette.ava.grey6,
        },
    },
}));
const FilledButton = (_a) => {
    var { children, className, color, dataQA } = _a, otherProps = __rest(_a, ["children", "className", "color", "dataQA"]);
    const classes = useStyles();
    return (React.createElement(Button, Object.assign({ className: classnames(classes.root, classes[color], className) }, otherProps, { "data-qa": dataQA }), children));
};
export default FilledButton;
