/* eslint-disable no-shadow */
import React from 'react';
import MicSelector from '../../modules/scribe/MicSelector';
import { selectConversationMuted, selectUserRoleInConversation, UserRoleInConversation, } from '../../selectors/combined';
import { selectInTwilioCalls, selectIsInConversation } from '../../selectors/conversation';
import { useAppSelector } from '../../store/store';
function CaptionsSettings() {
    const userRoleIsParticipant = useAppSelector(selectUserRoleInConversation) === UserRoleInConversation.PARTICIPANT;
    const isInConversation = useAppSelector(selectIsInConversation);
    const inTwilioCalls = useAppSelector(selectInTwilioCalls);
    const muted = useAppSelector(selectConversationMuted);
    const showComponent = !muted && userRoleIsParticipant && isInConversation && !inTwilioCalls;
    return (React.createElement("div", { style: { display: showComponent ? 'inherit' : 'none' } },
        React.createElement(MicSelector, null)));
}
export default CaptionsSettings;
