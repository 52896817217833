import HeadsetRoundedIcon from '@mui/icons-material/HeadsetRounded';
import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectUserRoleInConversation, UserRoleInConversation } from '../../selectors/combined';
import { selectHasScribe, selectPendingScribe, selectStatusRoomId } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import OneClickScribeScheduleLaterButton from './OneClickScribeScheduleLaterButton';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: (props) => props.pendingScribe ? theme.palette.ava.blue1 : theme.palette.ava.green2,
        padding: '12px 16px',
        gap: 8,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    text: {
        color: 'white',
        fontSize: 16,
    },
    pending: {
        fontWeight: 600,
        lineHeight: '122%',
    },
    stillPending: {
        fontWeight: 400,
        lineHeight: '124%',
    },
    later: {
        display: 'flex',
        padding: '6px 20px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        color: 'white',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 'normal',
        background: 'rgba(255, 255, 255, 0.12)',
        borderRadius: 100,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.12)',
            boxShadow: 'inset 3px 0px 10px rgba(0, 0, 0, 0.3)',
        },
    },
}));
function OneClickScribeStatusBar() {
    const { t } = useTranslation();
    const [showRest, setShowRest] = useState(false);
    const pendingScribe = useAppSelector(selectPendingScribe);
    const hasScribe = useAppSelector(selectHasScribe);
    const roomId = useAppSelector(selectStatusRoomId);
    const [notificationShowed, setNotificationShowed] = useState(sessionStorage.getItem('scribeStatusBarConfirmation') === roomId);
    const userRoleIsScribe = useAppSelector(selectUserRoleInConversation) === UserRoleInConversation.SCRIBE;
    const timer = useRef(null);
    const classes = useStyles({ pendingScribe });
    useEffect(() => {
        if (pendingScribe && !hasScribe) {
            timer.current = setTimeout(() => {
                setShowRest(true);
            }, 60 * 1000);
        }
        else if (hasScribe) {
            setShowRest(false);
            if (timer.current)
                clearTimeout(timer.current);
        }
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
                setShowRest(false);
            }
        };
    }, [pendingScribe, hasScribe]);
    useEffect(() => {
        if (hasScribe && !notificationShowed) {
            if (roomId)
                setTimeout(() => {
                    setNotificationShowed(true);
                    sessionStorage.setItem('scribeStatusBarConfirmation', roomId);
                }, 2 * 1000);
        }
    }, [hasScribe, notificationShowed]);
    if ((!hasScribe && !pendingScribe) || notificationShowed || userRoleIsScribe) {
        return null;
    }
    if (pendingScribe) {
        return (React.createElement(Box, { className: classes.root },
            React.createElement(CircularProgress, { style: { color: '#FAF9F6' }, size: '1.5rem' }),
            React.createElement(Typography, { className: classNames(classes.text, classes.pending) }, t('oneClickScribe.statusBar.pending')),
            showRest && (React.createElement(Typography, { className: classNames(classes.text, classes.stillPending) }, t('oneClickScribe.statusBar.stillPending'))),
            showRest && React.createElement(OneClickScribeScheduleLaterButton, { className: classes.later, statusBar: true })));
    }
    else {
        return (React.createElement(Box, { className: classes.root },
            React.createElement(HeadsetRoundedIcon, { style: { color: 'white' } }),
            React.createElement(Typography, { className: classNames(classes.text, classes.pending) }, t('oneClickScribe.statusBar.connected'))));
    }
}
export default OneClickScribeStatusBar;
