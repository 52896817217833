import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 7,
        borderRadius: 100,
        background: theme.palette.ava.white,
        boxShadow: '2px 2px 8px 0px rgba(112, 144, 176, 0.35)',
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        userSelect: 'none',
    },
    disabled: {
        opacity: '50%',
        cursor: 'default',
    },
}));
export const ConvoV2SecondaryButton = React.memo(({ children, disabled, className, onClick }) => {
    const classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.root, className, { [classes.disabled]: disabled }), onClick: onClick }, children));
});
