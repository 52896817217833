var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Card, ClickAwayListener, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../hooks/usePrevious';
import { selectCurrentSelectedVoice, selectDisplayedItemsCount, selectIsUsingTts, selectTtsGender, } from '../../selectors/textToSpeech';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { setIsUsingTts } from '../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { sleep } from '../../utils';
import { TtsSelector } from './TtsSelector';
import TtsToggle from './TtsToggle';
const useStyles = makeStyles((theme) => ({
    popover: {
        marginLeft: 33,
        borderRadius: '20px',
        overflowY: 'hidden',
        height: 'max-content',
        // have to use !important to override the default transform
        transform: 'translateY(-20px) !important',
    },
    popoverContainer: {
        width: (props) => (props.showVoices || !props.electronCaptionMode ? 350 : 400),
        height: '100%',
        display: 'inline-flex',
        flexDirection: 'column',
        padding: (props) => (props.showVoices ? '10px 20px 0' : '20px'),
        gap: (props) => (props.showVoices ? 10 : 16),
        alignItems: 'center',
        background: (props) => (props.electronCaptionMode ? theme.palette.ava.darkBg2 : 'transparent'),
        borderRadius: '20px',
        transition: 'width 0.5s ease',
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    title: {
        fontWeight: 700,
        color: (props) => (props.electronCaptionMode || theme.palette.mode === 'dark' ? 'white' : 'black'),
        fontSize: 20,
    },
    bottomContainer: {
        display: 'flex',
        maxHeight: 300,
        overflowY: (props) => (props.showVoices ? 'auto' : 'unset'),
        flexDirection: (props) => (props.electronCaptionMode && !props.showVoices ? 'row' : 'column'),
        gap: 12,
        width: '100%',
        alignItems: 'center',
        marginBottom: (props) => (props.electronCaptionMode ? 0 : 16),
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    close: {
        position: 'absolute',
        right: -5,
    },
    choicesContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    paragraph: {
        textAlign: 'center',
        marginTop: -15,
    },
    choices: {
        color: theme.palette.ava.navy,
        background: 'white',
        border: '1px solid white',
        '&:hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
        fontSize: (props) => (props.lang === 'fr' ? 12 : 14),
        width: 120,
        height: 32,
        padding: '8px 15px',
    },
    ttsContainer: {
        display: 'flex',
        padding: '6px 8px',
        alignItems: 'center',
        gap: 6,
    },
}));
function TtsButton() {
    const [open, setOpen] = useState(false);
    const parentRef = useRef(null);
    const { t, i18n } = useTranslation();
    const genderPreference = useAppSelector(selectTtsGender);
    const displayedItemsCount = useAppSelector(selectDisplayedItemsCount);
    const dispatch = useAppDispatch();
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const currentTtsVoiceSelected = useAppSelector(selectCurrentSelectedVoice);
    const prevOpen = usePrevious(open);
    const showVoices = genderPreference !== undefined;
    const classes = useStyles({
        isUsingTts,
        lang: i18n.language,
        electronCaptionMode,
        showVoices,
        items: displayedItemsCount,
    });
    const handleToggle = useCallback(() => {
        dispatch(setIsUsingTts(!isUsingTts));
    }, [isUsingTts]);
    const handleCancel = () => {
        setOpen(false);
        dispatch(setIsUsingTts(false));
    };
    useEffect(() => {
        // we need to force re-render of popover to prevent the awkward positioning of the popover on change of height or width
        // when we change from gender selection screen to voice selection screen
        if (showVoices && open) {
            const asyncFunc = () => __awaiter(this, void 0, void 0, function* () {
                yield sleep(0);
                setOpen(false);
                yield sleep(0);
                setOpen(true);
            });
            asyncFunc();
        }
    }, [genderPreference]);
    useEffect(() => {
        // we want to set the isUsingTts to true only if the user has selected a voice and closes the menu
        if (prevOpen && !open && currentTtsVoiceSelected) {
            dispatch(setIsUsingTts(true));
        }
    }, [open, currentTtsVoiceSelected]);
    useEffect(() => {
        if (currentTtsVoiceSelected) {
            dispatch(setIsUsingTts(true));
        }
    }, [currentTtsVoiceSelected]);
    useEffect(() => {
        if ((!genderPreference || !currentTtsVoiceSelected) && isUsingTts) {
            setTimeout(() => setOpen(true), 400);
        }
    }, [genderPreference, currentTtsVoiceSelected, isUsingTts]);
    return (React.createElement("div", { style: { marginRight: 10 } },
        React.createElement("div", { ref: electronCaptionMode ? parentRef : undefined },
            React.createElement(Popover, { anchorEl: open ? parentRef.current : null, open: open, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, PaperProps: { className: classes.popover } },
                React.createElement("div", { style: { visibility: open ? 'visible' : 'hidden' } },
                    React.createElement(ClickAwayListener, { onClickAway: () => handleCancel() },
                        React.createElement(Card, { className: classes.popoverContainer },
                            React.createElement("div", { className: classes.topContainer },
                                React.createElement(Typography, { variant: "h4", align: "center", width: '100%', className: classes.title }, t('profiling.voiceSelection.heading2'))),
                            React.createElement("div", { className: classes.bottomContainer },
                                React.createElement(TtsSelector, { closeMenu: () => {
                                        setOpen(false);
                                    }, buttonPopupMenu: true, showVoices: genderPreference !== undefined }))))))),
        React.createElement(TtsToggle, { handleToggle: handleToggle, ref: !electronCaptionMode ? parentRef : undefined })));
}
export default TtsButton;
