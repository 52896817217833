import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutoScroll } from '../../hooks/useAutoScroll';
import { selectTranscripts } from '../../selectors/legacy-conversation';
import { selectElectronCaptionMode, selectFullScreen, selectIsIntercomOpen, selectIsSideBarOpen, selectKeyboardInputShown, } from '../../selectors/ui';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import { getOS } from '../../utils';
import { openOrClosed } from '../../utils/intercom';
const useStyles = makeStyles((theme) => ({
    wrapper: {
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        zIndex: Z_INDICES.scribeScrollWithTranscriptWrapper,
    },
    scrollTarget: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    inCaptionMore: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    newTranscriptsButton: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 4,
        position: 'fixed',
        right: 20,
        backgroundColor: theme.palette.ava.blue2,
        border: 'none',
        '-webkit-app-region': 'no-drag',
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
            cursor: 'pointer',
        },
        zIndex: Z_INDICES.scribeScrollWithTranscriptFollowBtn,
    },
}));
const ScribeScrollWithTranscript = (props) => {
    const { children } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const scrollableContainer = useRef(null);
    const fullScreen = useAppSelector(selectFullScreen);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const sidebar = useAppSelector(selectIsSideBarOpen);
    const intercomOpen = useAppSelector(selectIsIntercomOpen);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const dispatch = useAppDispatch();
    const { update, follow, showMoreButton } = useAutoScroll(scrollableContainer, selectTranscripts);
    useEffect(() => {
        // This will get re-registered every time this component gets recreated,
        // but it's okay since it's very lightweight. There is currently no way
        // in intercom to clean up callbacks.
        openOrClosed(dispatch);
    }, []);
    useEffect(() => {
        if (electronCaptionMode) {
            follow();
        }
    }, [electronCaptionMode]);
    useEffect(() => {
        update();
    }, [children, electronCaptionMode]);
    const headerHeight = fullScreen ? 0 : 99;
    const height = keyboardInputShown ? 100 : 20;
    return (React.createElement("div", { className: `${classes.wrapper} ${electronCaptionMode ? classes.inCaptionMore : ''}`, 
        // the IntersectionObserver in ScribeTranscriptList looks for this ID, do not remove
        id: "TRANSCRIPT_CONTAINER", style: {
            position: electronCaptionMode ? 'relative' : 'fixed',
            height: electronCaptionMode ? '100%' : `calc(100% - ${headerHeight}px - ${keyboardInputShown ? 70 : 0}px)`,
            width: electronCaptionMode ? '100%' : `calc(100% - ${sidebar ? 270 : 0}px)`,
            // @ts-ignore
            WebkitAppRegion: getOS() === 'Mac' && electronCaptionMode ? 'drag' : '',
            left: electronCaptionMode ? 0 : undefined,
            top: electronCaptionMode ? 0 : undefined,
            paddingRight: electronCaptionMode ? 'initial' : intercomOpen ? '450px' : '100px',
        }, ref: scrollableContainer },
        showMoreButton && (React.createElement("button", { className: classes.newTranscriptsButton, style: { bottom: height }, onClick: follow },
            t('ccMode.conversation.newTranscripts'),
            " \u2193")),
        React.createElement("div", { className: `${classes.scrollTarget} ${electronCaptionMode ? classes.inCaptionMore : ''}` }, children)));
};
export default ScribeScrollWithTranscript;
