import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scribeCreateTranscript, scribeDeselectTranscriptWord } from '../actions';
import { selectAudioV2Status } from '../selectors/audioV2';
import { selectIsTextToSpeechV2 } from '../selectors/auth';
import { selectRecallAIStatus } from '../selectors/recallAI';
import { selectCurrentSelectedVoice, selectIsUsingTts, selectV1TtsBestVoice } from '../selectors/textToSpeech';
import { selectAvaId, selectUserName } from '../selectors/userProfile';
import { RecordingStatus, startRecording, stopRecording } from '../store/slices/audioV2';
import { setKaraokeState, setWasMutedBeforeKaraoke, updateKeyboardInputShown } from '../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../store/store';
import { KARAOKE_PRE_DELAY_MAPPING } from '../utils/karaoke';
import { attemptSpeakWithVoice, TTS_PLACEHOLDER_GENDER, TTS_PLACEHOLDER_VOICE_ID } from '../utils/textToSpeech';
import useTextToSpeechManager from './useTextToSpeechManager';
export function useKeyboardInput() {
    const avaId = useAppSelector(selectAvaId);
    const recording = useAppSelector(selectAudioV2Status) === RecordingStatus.RECORDING;
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const isTextToSpeechV2 = useAppSelector(selectIsTextToSpeechV2);
    const currentSelectedVoice = useAppSelector(selectCurrentSelectedVoice);
    const v1TtsBestVoice = useAppSelector(selectV1TtsBestVoice);
    const userName = useAppSelector(selectUserName);
    const textToSpeechManager = useTextToSpeechManager();
    const dispatch = useAppDispatch();
    const { i18n: { language }, } = useTranslation();
    const [message, setMessage] = useState('');
    const [isAlreadyTyping, setIsAlreadyTyping] = useState(false);
    const [animating, setAnimating] = useState(false);
    const [buttonState, setButtonState] = useState('disabled');
    const [charLimitReached, setCharLimitReached] = useState(false);
    const sendMessage = useCallback((karaoke) => {
        dispatch(scribeDeselectTranscriptWord());
        const newTranscript = dispatch(scribeCreateTranscript(undefined, message, avaId, 1, false, undefined));
        if (karaoke && newTranscript && currentSelectedVoice) {
            // for some reason the french male voices take slightly longer to start speaking
            let extraDelay = 0;
            const lang = language.split('-')[0];
            if (lang === 'fr' && currentSelectedVoice.gender === 'male') {
                extraDelay = 100;
            }
            const startDelay = KARAOKE_PRE_DELAY_MAPPING[currentSelectedVoice.voiceProviderId] + extraDelay;
            setTimeout(() => {
                dispatch(setKaraokeState({ wordIndex: 0, transcriptId: newTranscript.id }));
            }, startDelay);
            if (recording) {
                dispatch(stopRecording());
                dispatch(setWasMutedBeforeKaraoke(false));
            }
            else {
                dispatch(setWasMutedBeforeKaraoke(true));
            }
        }
        dispatch(scribeDeselectTranscriptWord());
        setMessage('');
    }, [message, avaId, dispatch, scribeDeselectTranscriptWord, scribeCreateTranscript, setKaraokeState, recording]);
    const synthesizeSpeech = useCallback((karaoke) => {
        if (isTextToSpeechV2) {
            textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleTextToSpeech({
                text: message,
                voiceGender: currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.gender,
                voiceId: currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.id,
            });
            sendMessage(karaoke);
        }
        else {
            sendMessage(false);
            if (recallAIStatus === 'CAPTIONING' && !isTextToSpeechV2) {
                //! This is a hack to make TTS 1.0? work with Ava Connect remove this when we remove TTS 1.0
                textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleTextToSpeech({
                    text: message,
                    voiceGender: TTS_PLACEHOLDER_GENDER,
                    voiceId: TTS_PLACEHOLDER_VOICE_ID,
                });
            }
            else {
                if (!v1TtsBestVoice)
                    return;
                attemptSpeakWithVoice({
                    message,
                    recording,
                    startRecord: () => dispatch(startRecording()),
                    stopRecord: () => dispatch(stopRecording()),
                    voice: v1TtsBestVoice,
                });
            }
        }
    }, [
        isTextToSpeechV2,
        message,
        recallAIStatus,
        recording,
        v1TtsBestVoice,
        textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleTextToSpeech,
        sendMessage,
        attemptSpeakWithVoice,
        dispatch,
        startRecording,
        stopRecording,
    ]);
    const sendOrVoiceMessage = useCallback(() => {
        setAnimating(true);
        setButtonState('active');
        if (!isUsingTts) {
            sendMessage(false);
        }
        else {
            synthesizeSpeech(true);
        }
        setTimeout(() => {
            setAnimating(false);
        }, 300);
    }, [isUsingTts, sendMessage, synthesizeSpeech]);
    const onInputChange = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length < 200) {
            scribeDeselectTranscriptWord();
            setMessage(event.target.value);
            if (charLimitReached) {
                setCharLimitReached(false);
            }
        }
        else {
            setCharLimitReached(true);
        }
    }, [isUsingTts, message, scribeDeselectTranscriptWord, setMessage]);
    const onInputKeyDown = useCallback((event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            // User is pressing Enter without Shift, allow the default action of
            // sending a message.
            event.preventDefault();
            sendOrVoiceMessage();
        }
        else if (event.key === 'Escape') {
            dispatch(updateKeyboardInputShown(false));
        }
    }, [sendOrVoiceMessage, dispatch, updateKeyboardInputShown]);
    const onSendClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        sendOrVoiceMessage();
    }, [isUsingTts, message]);
    useEffect(() => {
        if (!userName || !avaId)
            return;
        if (message.length > 0 && !isAlreadyTyping) {
            setIsAlreadyTyping(true);
            textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleSendIAmTyping({ userName, avaId });
        }
        if (message.length === 0 && isAlreadyTyping) {
            setIsAlreadyTyping(false);
        }
    }, [message, isAlreadyTyping]);
    useEffect(() => {
        let buttonValue;
        if (!message.length && !animating) {
            buttonValue = 'disabled';
        }
        else if (animating) {
            buttonValue = 'active';
        }
        else {
            buttonValue = 'inactive';
        }
        setButtonState(buttonValue);
    }, [message, animating]);
    return {
        message,
        isUsingTts,
        sendMessage,
        onInputChange,
        onInputKeyDown,
        onSendClick,
        buttonState,
        charLimitReached,
    };
}
