import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    '@keyframes scale': {
        '0%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(1.4)' },
        '100%': { transform: 'scale(1)' },
    },
    glowingDot: {
        animation: '$scale 2s infinite ease-in-out',
        width: 6,
        height: 6,
        borderRadius: '50%',
        background: theme.palette.ava.green2,
    },
}));
function OneClickButtonGlowingDot() {
    const classes = useStyles();
    return React.createElement("div", { className: classes.glowingDot });
}
export default OneClickButtonGlowingDot;
