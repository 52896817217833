import { Fade, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '../../components/Loading';
import Default from '../../Layouts/Default';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectCurrentConversationEndingStage } from '../../selectors/conversationEnd';
import { selectLoading } from '../../selectors/ui';
import { ConversationEndingStage } from '../../store/slices/conversationEnd';
import { useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import ConversationPage from '../../views/conversation/ConversationPage/ConversationPage';
import ConversationEndModals from '../conversation/ConversationEndModals';
import OneClickScribeStatusBar from '../oneClickScribe/OneClickScribeStatusBar';
import { ConvoV2ConnectionStatus } from './ConvoV2ConnectionStatus';
import { ConvoV2ControlBar } from './ConvoV2ControlBar';
import { ConvoV2ConversationHeader } from './ConvoV2ConversationHeader';
import { ConvoV2LeftSidebar } from './ConvoV2LeftSidebar';
import { ConvoV2SaveTranscriptPopup } from './ConvoV2SaveTranscriptPopup';
import { ConvoV2TranscriptList } from './ConvoV2TranscriptList';
import { ConvoV2TranscriptPlaceholder } from './ConvoV2TranscriptPlaceholder';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    sidebar: {
        height: '100vh',
    },
    sidebarMobile: {
        position: 'absolute',
        left: -80,
        top: 0,
        zIndex: Z_INDICES.leftSidebarMobile,
        transition: '300ms left ease',
    },
    sidebarMobileShown: {
        left: 0,
    },
    darkenScreenPositioning: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: Z_INDICES.darkenScreen,
    },
    darkenScreen: {
        background: '#000',
        opacity: 0.6,
    },
    right: {
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: theme.palette.ava.transparent.blue[theme.palette.mode],
    },
    transcripts: {
        width: '100%',
        overflow: 'auto',
        flexGrow: 2,
    },
}));
export const ConvoV2ConversationPage = React.memo(() => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) && !window.isElectron;
    const isInConversation = useAppSelector(selectIsInConversation);
    const loading = useAppSelector(selectLoading);
    const conversationEndingStage = useAppSelector(selectCurrentConversationEndingStage);
    const [showMobileSidebar, setShowMobileSidebar] = useState(false);
    const onSidebarToggle = useCallback(() => {
        setShowMobileSidebar(!showMobileSidebar);
    }, [showMobileSidebar, setShowMobileSidebar]);
    const hasBeenInConversation = useRef(false);
    useEffect(() => {
        hasBeenInConversation.current || (hasBeenInConversation.current = isInConversation);
    }, [isInConversation]);
    // Until we have implemented the new post-convo experience, we show the old one.
    if (!isInConversation && !loading && hasBeenInConversation) {
        return (React.createElement(Default, null,
            React.createElement(ConversationPage, null)));
    }
    if (!isInConversation || loading || conversationEndingStage === ConversationEndingStage.END_ON_BACKEND) {
        return React.createElement(Loading, null);
    }
    const showSidebar = !isMobile || showMobileSidebar;
    return (React.createElement(React.Fragment, null,
        React.createElement(ConvoV2ConnectionStatus, null),
        React.createElement(Box, { className: classes.root },
            React.createElement(ConvoV2LeftSidebar, { className: classNames(classes.sidebar, {
                    [classes.sidebarMobile]: isMobile,
                    [classes.sidebarMobileShown]: isMobile && showSidebar,
                }), onSidebarToggle: onSidebarToggle }),
            isMobile && (React.createElement(Fade, { in: showSidebar },
                React.createElement(Box, { className: classes.darkenScreenPositioning },
                    React.createElement(Box, { className: classNames(classes.darkenScreenPositioning, classes.darkenScreen) })))),
            React.createElement(Box, { className: classes.right },
                React.createElement(ConvoV2ConversationHeader, { onSidebarToggle: onSidebarToggle }),
                React.createElement(OneClickScribeStatusBar, null),
                React.createElement(ConvoV2TranscriptPlaceholder, { className: classes.transcripts }),
                React.createElement(ConvoV2TranscriptList, { className: classes.transcripts }),
                React.createElement(ConvoV2ControlBar, null))),
        React.createElement(ConversationEndModals, { SaveTranscriptPopupComponent: ConvoV2SaveTranscriptPopup })));
});
