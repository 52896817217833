import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AvaLanding from '../../../assets/images/ava_landing.svg';
import AvaLogo from '../../../assets/images/logo.png';
import JoinConversationButton from '../../components/JoinConversation/Button';
import Loading from '../../components/Loading';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectLoggedIn, selectLoggedOut } from '../../selectors/combined';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectLoading } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { AuthErrorCategory, EmailErrorType, GeneralAuthErrorType, PasswordErrorType } from '../../utils/onboardingV2';
import LanguagePicker from './LanguagePicker';
import SignInSignUp from './SigninSignUp';
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.ava.transparent.blue.light,
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            gap: 16,
        },
        // mobile landscape
        ['@media (max-width: 1000px) and (max-height: 550px)']: {
            overflowY: 'scroll',
            height: 'auto',
            minHeight: 0,
        },
        // iphone SE
        ['@media (max-width: 395px)']: { overflowY: 'scroll' },
    },
    headerGridRow: {
        height: '15%',
        // mobile and tablet
        ['@media (max-width: 850px)']: {
            height: 'auto',
        },
    },
    middleGridRow: {
        minHeight: 'max-content',
        height: 'auto',
        alignItems: 'center',
        ['@media (max-width: 850px)']: {
            alignItems: 'center',
            justifyContent: (props) => !props.isMobileLandscape && 'center',
        },
        // iphone SE
        ['@media (max-width: 375px)']: { marginTop: '5%' },
        // mobile portrait
        ['@media (max-width: 600px) and (max-height: 1000px)']: {
            alignItems: 'flex-start',
        },
        // mobile landscape
        ['@media (max-width: 1000px) and (max-height: 550px)']: {
            justifyContent: 'center',
            marginTop: '6%',
        },
    },
    bottomGridRow: {
        height: '15%',
        // when image hidden
        ['@media (max-width: 850px)']: {
            height: 'auto',
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
        // mobile landscape
        ['@media (max-width: 1000px) and (max-height: 550px)']: {
            height: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 'auto',
        },
        // mobile portrait
        ['@media (max-width: 600px) and (max-height: 1000px)']: {
            height: 'auto',
            flexDirection: 'row-reverse',
            alignItems: 'flex-start',
        },
        // iphone 14 Pro max portrait
        ['@media (min-width: 425px) and (min-height: 925px) and (max-width: 435px) and (max-height: 935px)']: {
            marginBottom: '10%',
            marginTop: '-5%',
        },
    },
    leftColumnJoinRoom: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    rightColumnGridFlexItem: {
        alignSelf: 'flex-start',
        ['@media (min-width: 850px) and (min-height: 460px)']: {
            marginTop: '-2%',
        },
    },
    rightColumnSignUpSignIn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: (props) => (props.error ? 5 : 0),
    },
    mobileTopRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        gap: 40,
        // iphone SE
        ['@media (max-width: 395px)']: { gap: 12 },
        // mobile landscape
        ['@media (min-width: 600px) and (max-width: 1000px) and (max-height: 550px)']: {
            gap: 0,
            flexDirection: 'row',
        },
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        ['@media (max-width: 850px)']: {
            alignItems: 'center',
        },
    },
    header: {
        marginRight: '2%',
        paddingTop: '2%',
        // mobile portrait and hidden image
        ['@media (max-width: 850px) and (max-height: 1000px)']: {
            margin: 0,
            paddin: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    link: {
        color: theme.palette.ava.blue2,
    },
    image: {
        maxWidth: 600,
        maxHeight: 416,
        height: 'auto',
        width: '100%',
        marginBottom: '7vh',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            minHeight: 300,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            minHeight: 200,
        },
    },
    logo: {
        maxWidth: 163,
        maxHeight: 100,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 40,
        },
        ['@media (max-width: 850px)']: {
            marginBottom: 0,
        },
        // mobile landscape
        ['@media (max-width: 1000px) and (max-height: 550px)']: {
            marginBottom: 0,
            paddingLeft: '5%',
            paddingTop: 10,
        },
    },
    mobileLogoContainer: {
        paddingTop: (props) => (props.error ? 5 : 28),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // mobile landscape
        ['@media (min-width: 600px) and (max-width: 1000px) and (max-height: 550px)']: {
            paddingTop: '0px !important',
            justifyContent: 'flex-start',
            flexBasis: '30%',
        },
    },
    gotCode: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // mobile portrait
        ['@media (max-width: 850px) and (max-height: 1000px) and (orientation: portrait)']: {
            flexDirection: 'column',
            gap: 16,
        },
        // mobile landscape
        ['@media (max-width: 1000px) and (max-height: 550px) and (orientation: landscape)']: {
            justifyContent: 'flex-end',
            paddingRight: '2%',
        },
        // iphone SE landscape
        ['@media (min-width: 650px) and (max-width: 680px) and (max-height: 390px) and (orientation: landscape)']: {
            gap: 0,
            flexDirection: 'row',
            paddingRight: '2%',
        },
    },
    joinExisting: {
        display: 'flex',
        color: theme.palette.ava.blue2,
        marginLeft: '2%',
    },
    LangPickContainer: {
        display: 'flex',
        height: '100%',
        paddingLeft: '5%',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: (props) => (props.error ? '2.5vh' : 0),
        },
        ['@media (max-width: 850px)']: {
            paddingLeft: 0,
            justifyContent: 'center',
            width: '100%',
            paddingBottom: '1vh',
        },
    },
    langPickerGridItem: {
        ['@media (min-width: 600) and (min-height: 501px)']: {
            order: 1,
        },
        // mobile landscape
        ['@media (max-width: 1000px) and (max-height: 550px)']: {
            order: 2,
        },
        // mobile portrait
        ['@media (max-width: 600px) and (max-height: 1000px)']: { order: 2 },
    },
    redirectToAccountGridItem: {
        ['@media (min-width: 600) and (min-height: 501px)']: {
            order: 2,
        },
        // mobile landscape
        ['@media (max-width: 1000px) and (max-height: 550px)']: { order: 1 },
        // mobile portrait
        ['@media (max-width: 600px) and (max-height: 1000px)']: { order: 1 },
    },
    redirectToAccount: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: (props) => (props.isMobileLandscape ? '5%' : 0),
    },
    passwordRequirementsTitle: {
        padding: 0,
        margin: 0,
        fontSize: 12,
    },
    passwordRequirementsText: {
        margin: 0,
        paddingLeft: 20,
        fontSize: 12,
    },
}));
function Onboarding({ changeModeToLogin }) {
    var _a;
    const { t, i18n: { language }, } = useTranslation();
    const [mode, setMode] = useState(changeModeToLogin ? 'signin' : 'signup');
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const loading = useAppSelector(selectLoading);
    const isInConversation = useAppSelector(selectIsInConversation);
    const loggedIn = useAppSelector(selectLoggedIn);
    const loggedOut = useAppSelector(selectLoggedOut);
    const isMobile = useMediaQuery('(max-width:850px');
    const isLandscape = useMediaQuery('(orientation: landscape)');
    const mediumWidth = useMediaQuery('(max-width: 960px)');
    const mediumHeight = useMediaQuery('(max-height: 500px)');
    const isMobileLandscape = isLandscape && mediumWidth && mediumHeight;
    const [_, wsStatus] = useV1Socket();
    const resetRef = useRef(null);
    const resetInSignIn = () => {
        var _a;
        (_a = resetRef.current) === null || _a === void 0 ? void 0 : _a.reset();
    };
    function formatPasswordRequirementsError(passwordRequirements) {
        const { title, chars, uppercase, lowercase, symbol } = passwordRequirements;
        return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', marginLeft: -12 } },
            React.createElement("div", null,
                React.createElement("p", { className: classes.passwordRequirementsTitle }, title)),
            React.createElement("ul", { className: classes.passwordRequirementsText },
                React.createElement("li", null, chars),
                React.createElement("li", null, uppercase),
                React.createElement("li", null, lowercase),
                React.createElement("li", null, symbol))));
    }
    const errorMessages = {
        [AuthErrorCategory.Email]: {
            [EmailErrorType.InvalidFormat]: t('onboardingV2.errors.invalidEmail'),
        },
        [AuthErrorCategory.Password]: {
            [PasswordErrorType.PasswordRequirements]: {
                title: t('onboardingV2.errors.passwordRequirements.title'),
                chars: t('onboardingV2.errors.passwordRequirements.chars'),
                uppercase: t('onboardingV2.errors.passwordRequirements.uppercase'),
                lowercase: t('onboardingV2.errors.passwordRequirements.lowercase'),
                symbol: t('onboardingV2.errors.passwordRequirements.symbol'),
            },
            [PasswordErrorType.Wrong]: t('onboardingV2.errors.emailAndOrPasswordMismatch'),
        },
        [AuthErrorCategory.General]: {
            [GeneralAuthErrorType.unknown]: t('onboardingV2.errors.unknown'),
        },
    };
    const [authError, setAuthError] = useState({});
    const classes = useStyles({
        mode,
        error: !!((_a = authError.password) === null || _a === void 0 ? void 0 : _a.passwordRequirements),
        fr: language === 'fr',
        isMobileLandscape,
    });
    // helper function to make setting errors easier
    const setError = (category, errorType) => {
        setAuthError((prevErrors) => (Object.assign(Object.assign({}, prevErrors), { [category]: Object.assign(Object.assign({}, (prevErrors[category] || {})), { [errorType]: errorType === PasswordErrorType.PasswordRequirements
                    ? formatPasswordRequirementsError(errorMessages[category][errorType])
                    : errorMessages[category][errorType] }) })));
    };
    const navigate = useNavigate();
    const redirectToLoginOrSignup = () => {
        resetInSignIn();
        if (mode === 'signup') {
            setMode('signin');
            navigate('/web/login', { replace: true });
        }
        else {
            setMode('signup');
            navigate('/web/signup', { replace: true });
        }
    };
    // If the user is logged in - they will be redirected soon, so we show the
    // loading banner. This is also true if we are loading or already in conversation.
    if (loggedIn || loading || isInConversation) {
        return React.createElement(Loading, null);
    }
    // If the user is not logged in and not logged out - we show
    // the loading banner as long as they are not logged in anonymously.
    if (!loggedOut && !(firebaseUser && firebaseUser.isAnonymous)) {
        return React.createElement(Loading, null);
    }
    return (React.createElement(Grid, { container: true, className: classes.root },
        React.createElement(Grid, { className: classes.headerGridRow, item: true, xs: 12 },
            (isMobile || isMobileLandscape) && (React.createElement("div", { className: classes.mobileTopRow },
                React.createElement("div", { className: classes.mobileLogoContainer },
                    React.createElement("img", { className: classes.logo, src: AvaLogo, alt: t('title') })),
                React.createElement("div", { className: classes.gotCode },
                    React.createElement(Typography, null, t('onboardingV2.signin.gotAnAvaCode')),
                    React.createElement("div", { className: classes.joinExisting },
                        React.createElement(JoinConversationButton, { header: true }))))),
            mode === 'signin' && !isMobile && !isMobileLandscape && (React.createElement("div", { className: classes.headerContainer },
                React.createElement("div", { className: classes.header },
                    React.createElement(Typography, { textAlign: 'right' }, t('onboardingV2.signin.newToAva')),
                    React.createElement(Button, { style: { padding: 0 }, className: classes.link, onClick: () => redirectToLoginOrSignup(), "data-qa": "createAnAccountLink" },
                        React.createElement(Typography, { fontWeight: 600, fontSize: 16 }, t('onboardingV2.signin.createAnAccount'))))))),
        React.createElement(Grid, { className: classes.middleGridRow, container: true },
            !isMobile && !isMobileLandscape && (React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                React.createElement("div", { className: classes.leftColumnJoinRoom },
                    React.createElement("img", { className: classes.image, src: AvaLanding, alt: t('title') }),
                    React.createElement("div", { className: classes.gotCode },
                        React.createElement(Typography, null, t('onboardingV2.signin.gotAnAvaCode')),
                        React.createElement("div", { className: classes.joinExisting },
                            React.createElement(JoinConversationButton, { header: true })))))),
            React.createElement(Grid, { className: classes.rightColumnGridFlexItem, item: true, xs: 12, sm: 6 },
                React.createElement("div", { className: classes.rightColumnSignUpSignIn },
                    !isMobile && !isMobileLandscape && React.createElement("img", { className: classes.logo, src: AvaLogo, alt: t('title') }),
                    React.createElement(SignInSignUp, { setAuthError: setAuthError, setError: setError, authError: authError, mode: mode, setMode: setMode, ref: resetRef, isMobile: isMobile, isMobileLandscape: isMobileLandscape })))),
        React.createElement(Grid, { className: classes.bottomGridRow, container: true },
            React.createElement(Grid, { className: classes.langPickerGridItem, item: true, xs: 12, sm: 6 },
                React.createElement("div", { className: classes.LangPickContainer },
                    React.createElement(LanguagePicker, null))),
            React.createElement(Grid, { className: classes.redirectToAccountGridItem, item: true, xs: 12, sm: 6 },
                mode === 'signup' && (React.createElement("div", { className: classes.redirectToAccount },
                    React.createElement(Typography, { color: 'black' }, t('onboardingV2.signup.alreadyHaveAnAccount')),
                    React.createElement(Button, { className: classes.link, onClick: () => redirectToLoginOrSignup(), "data-qa": "signInLink" },
                        React.createElement(Typography, { fontWeight: 600, fontSize: 16 }, t('onboardingV2.signup.redirect'))))),
                mode === 'signin' && (isMobile || isMobileLandscape) && (React.createElement("div", { className: classes.header },
                    React.createElement(Typography, null, t('onboardingV2.signin.newToAva')),
                    React.createElement(Button, { className: classes.link, onClick: () => redirectToLoginOrSignup(), "data-qa": "signInLink" },
                        React.createElement(Typography, { fontWeight: 600, fontSize: 16 }, t('onboardingV2.signin.createAnAccount')))))))));
}
export default Onboarding;
