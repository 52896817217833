import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { capitalize } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NO_TRANSLATION, useTranslationLangSwitcher } from '../../hooks/useTranslationLangSwitcher';
export function ConvoV2TranslationLangSelector({ className }) {
    var _a;
    const { t } = useTranslation();
    const { activeTranslationLang, allTranslationLangs, switchTranslationLang } = useTranslationLangSwitcher();
    const onChange = useCallback((event) => {
        switchTranslationLang(event.target.value);
    }, [switchTranslationLang]);
    const chooseLanguageText = useMemo(() => capitalize(t('sidebar.chooseLanguage').toLowerCase()), [t]);
    return (React.createElement(Select, { className: className, value: (_a = activeTranslationLang === null || activeTranslationLang === void 0 ? void 0 : activeTranslationLang.languageCode) !== null && _a !== void 0 ? _a : NO_TRANSLATION, onChange: onChange, IconComponent: KeyboardArrowDownIcon, 
        // Without this, the whole settings card is hidden as soon as you open the select
        MenuProps: { disablePortal: true } },
        React.createElement(MenuItem, { key: NO_TRANSLATION, value: NO_TRANSLATION, divider: true }, activeTranslationLang ? t('sidebar.stopTranslation') : chooseLanguageText),
        allTranslationLangs.map((translationLang) => (React.createElement(MenuItem, { key: translationLang.languageCode, value: translationLang.languageCode }, translationLang.label)))));
}
