import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRateConversation } from '../../hooks/useRateConversation';
import { setRateConversationOpen } from '../../store/slices/rateConversation';
import { useAppDispatch } from '../../store/store';
import { ConvoV2FeedbackPopup } from './ConvoV2FeedbackPopup';
import { ConvoV2StarIcon } from './ConvoV2StarIcon';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: '24px',
    },
    stars: {
        display: 'flex',
        gap: 20,
    },
    star: {
        cursor: 'pointer',
    },
    thanks: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    thanksIconContainer: {
        position: 'relative',
        marginBottom: 12,
        width: 48,
        height: 48,
    },
    thanksIconPosition: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    thanksIconBackground: {
        width: 40,
        height: 40,
        backgroundColor: theme.palette.ava.white,
        borderRadius: '50%',
    },
    thanksIcon: {
        width: 48,
        height: 48,
        color: theme.palette.ava.blue2,
    },
    thanksTitle: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 8,
    },
    thanksDescription: {
        fontSize: 18,
        fontWeight: 400,
    },
}));
export const ConvoV2RatingFeedbackPopup = React.memo(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ratingComplete, setRatingComplete] = useState(false);
    const dispatch = useAppDispatch();
    const { rateHover, onRateChange, onRateHover, finishRating } = useRateConversation();
    const rateHoverOrZero = rateHover !== null && rateHover !== void 0 ? rateHover : 0;
    const inactiveStarColor = '#E3E5E9';
    const ratingColors = [inactiveStarColor, '#DE423E', '#DE423E', '#ED8822', '#02C874', '#02C874'];
    const activeStarColor = ratingColors[rateHoverOrZero];
    const onThanksClose = useCallback(() => {
        // When this is shown, `finishConversation` has already been called and the popup should be
        // closed automatically after a timeout, but the user wants to close it before it triggers.
        dispatch(setRateConversationOpen(false));
    }, []);
    const onRateClose = useCallback(() => {
        finishRating(0);
    }, []);
    // No useCallback used, because the conditional rendering of the dialog that uses this makes
    // React sad (it expects all hooks to be called the same number of times each render). All
    // attempts to remedy this have made the code harder to read or impact perf in other ways.
    const rateUpdater = (value) => {
        return () => {
            setRatingComplete(true);
            onRateChange(value);
        };
    };
    const rateHoverUpdater = (value) => {
        return () => {
            onRateHover(value);
        };
    };
    if (ratingComplete) {
        return (React.createElement(ConvoV2FeedbackPopup, { onClose: onThanksClose },
            React.createElement(Box, { className: classes.thanks },
                React.createElement(Box, { className: classes.thanksIconContainer },
                    React.createElement(Box, { className: classNames(classes.thanksIconPosition, classes.thanksIconBackground) }),
                    React.createElement(CheckCircleIcon, { className: classNames(classes.thanksIconPosition, classes.thanksIcon) })),
                React.createElement(Box, { className: classes.thanksTitle }, t('convoV2.rate.thanksTitle')),
                React.createElement(Box, { className: classes.thanksDescription }, t('convoV2.rate.thanksDescription')))));
    }
    return (React.createElement(ConvoV2FeedbackPopup, { onClose: onRateClose },
        React.createElement(Box, { className: classes.root },
            React.createElement(Box, { className: classes.title }, t('convoV2.rate.howDidAvaWork')),
            React.createElement(Box, { className: classes.stars }, [1, 2, 3, 4, 5].map((value) => (React.createElement(ConvoV2StarIcon, { className: classes.star, color: rateHoverOrZero > value - 1 ? activeStarColor : inactiveStarColor, onMouseEnter: rateHoverUpdater(value), onClick: rateUpdater(value) })))))));
});
