var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from 'axios';
import { restName } from '../firebase';
import { getIDTokenFromServiceWorkerOrFirebase } from '../store/slices/auth';
import { getBackendConfig } from './backends';
export const httpRequestWithToken = ({ url, method, payload, options, }) => __awaiter(void 0, void 0, void 0, function* () {
    const token = yield getIDTokenFromServiceWorkerOrFirebase();
    const opts = Object.assign(Object.assign({}, options), { auth: { username: restName, password: token } });
    return httpRequest({ url, method, payload, options: opts });
});
export const httpRequest = ({ url, method, payload, options, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    try {
        const res = yield axios(Object.assign({ url,
            method, data: payload }, (options || {})));
        // monkey-patch response object for easier logging
        res.request.url = url;
        res.request.payload = payload;
        res.request.method = method;
        return res;
    }
    catch (err) {
        // Exception may be raised at different points of request lifecycle
        // and we want to monkey-patch the object the same as the
        // regular response above.
        if (err.response) {
            // Http request was made, but server replied with a non-2xx status code
            // (by default, axios raises an exception in such case).
            err.request = { url, method, payload };
            // Backend may send also specific error message embedded in the response json.
            // This can be formatted differently, depending on particular api implementation.
            const { data } = err.response;
            err.message = ((_c = (_b = (_a = data.error) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.content) === null || _c === void 0 ? void 0 : _c.message) || data.msg || data.name || err.message;
        }
        else if (err.request) {
            // Http request was made, but no response was received
            err.request.url = url;
            err.request.payload = payload;
            err.request.method = method;
        }
        else {
            // Error happened during request setup or serialization
            err.request = { url, method, payload };
        }
        throw err;
    }
});
export const getAvaBackendEndpoint = () => localStorage.getItem('avaBackendUrl') || getBackendConfig('captionerUrl');
export const getScribeBackendEndpoint = () => localStorage.getItem('scribeBackendUrl') || getBackendConfig('scribeUrl');
export const firebaseFunctionsEndpoint = getBackendConfig('firebaseFunctionsEndpoint');
export const cloudTranscriptsEndpoint = `${firebaseFunctionsEndpoint}/widgets/cloudTranscripts`;
export const smartRecapEndpoint = `${firebaseFunctionsEndpoint}/generateSmartRecap`;
export const getSubscriptionBackendEndpoint = () => getBackendConfig('subscriptionUrl');
export const getSaasBackendEndpoint = () => localStorage.getItem('saasBackendUrl') || getBackendConfig('saasUrl');
export const getSearchValueFromString = (searchString, key, _default) => {
    if (!searchString) {
        return _default;
    }
    const params = new URLSearchParams(searchString);
    return params.get(key) || _default;
};
export const getSearchValue = (window, key, _default = undefined) => {
    // I don't know why, but window.location can sometimes be undefined. This looks
    // like browser bug, but even when window.location is undefined,
    // document.location is set.
    const location = window.location || document.location;
    const searchString = location.search;
    return getSearchValueFromString(searchString, key, _default);
};
export const setSearchValue = (window, key, value) => {
    const location = window.location || document.location;
    const url = new URL(location.href);
    const oldUrlString = url.toString();
    const params = new URLSearchParams(url.search);
    params.set(key, value);
    url.search = params.toString();
    const newUrlString = url.toString();
    if (oldUrlString !== newUrlString) {
        window.history.replaceState(null, '', newUrlString);
    }
};
export const clearSearchValue = (window, key) => {
    const location = window.location || document.location;
    const url = new URL(location.href);
    const oldUrlString = url.toString();
    const params = new URLSearchParams(url.search);
    params.delete(key);
    url.search = params.toString();
    const newUrlString = url.toString();
    if (oldUrlString !== newUrlString) {
        window.history.replaceState(null, '', newUrlString);
    }
};
const initBackend = getSearchValue(window, 'endpoint', undefined);
if (initBackend && initBackend.endsWith('ava.me')) {
    const url = new URL(initBackend);
    if (url.hostname.endsWith('ava.me')) {
        localStorage.setItem('avaBackendUrl', initBackend);
    }
}
export const updateBackendAva = (backendId, backendsAva) => {
    localStorage.setItem('avaBackendUrl', backendsAva[backendId].url);
    window.location.replace('/');
};
export const fetchWithTimeout = (url, options = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const { timeout = 5000 } = options, fetchOptions = __rest(options, ["timeout"]);
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = yield fetch(url, Object.assign(Object.assign({}, fetchOptions), { signal: controller.signal }));
    clearTimeout(id);
    return response;
});
