var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectHasScribe } from '../../selectors/legacy-conversation';
import { resetSelectedCaptions } from '../../store/slices/conversation';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { fetchWithTimeout } from '../../utils/http';
import { sendTypeOnly } from '../../utils/ws-v1';
import { OneClickScribeScheduleIcon } from './OneClickScribeScheduleIcon';
function OneClickScribeScheduleLaterButton({ className, statusBar }) {
    const { t } = useTranslation();
    const [calendlyLink, setCalendlyLink] = useState('');
    const [loadingLink, setLoadingLink] = useState(false);
    const hasScribe = useAppSelector(selectHasScribe);
    const dispatch = useAppDispatch();
    const [ws] = useV1Socket();
    const fetchCalendlyLink = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoadingLink(true);
            const response = yield fetchWithTimeout('https://ipapi.co/json/');
            const data = yield response.json();
            const country = data.country_code;
            if (country === 'FR') {
                setCalendlyLink('https://calendly.com/fr-ava-scribe');
            }
            else {
                setCalendlyLink('https://calendly.com/ava-scribe');
            }
            setLoadingLink(false);
        }
        catch (error) {
            console.error('Error getting location from IP, defaulting to US', error);
            setCalendlyLink('https://calendly.com/ava-scribe');
            setLoadingLink(false);
        }
    });
    const handleClick = () => {
        window.open(calendlyLink, '_blank');
        if (statusBar) {
            if (ws)
                sendTypeOnly(ws, 'end-scribe');
            dispatch(resetSelectedCaptions());
        }
    };
    useEffect(() => {
        if (!hasScribe && !calendlyLink) {
            fetchCalendlyLink();
        }
    }, [hasScribe, calendlyLink]);
    return (React.createElement(React.Fragment, null, loadingLink ? (React.createElement(CircularProgress, { size: '1.5rem' })) : (React.createElement(Button, { onClick: handleClick, className: className },
        React.createElement(OneClickScribeScheduleIcon, { fillOpacity: statusBar ? 0.64 : 1, fill: statusBar ? 'white' : '#006651' }),
        t('oneClickScribe.modal.preScribe.later')))));
}
export default OneClickScribeScheduleLaterButton;
