var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AVA_URL } from '../constants';
import { selectStatusHostAvaName } from '../selectors/legacy-conversation';
import { useAppSelector } from '../store/store';
import { isMobile, isSafari } from '../utils';
import { useNotifications } from './useNotifications';
export const useCopyInviteLink = (options) => {
    const { t } = useTranslation();
    const { notify } = useNotifications();
    const hostAvaName = useAppSelector(selectStatusHostAvaName);
    // the desktop Safari check is a workaround for a bug in Safari where the share API doesn't work properly
    const desktopSafari = !isMobile() && isSafari();
    const copyInviteLink = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        // TODO: Would it be possible to use location.origin if not Tauri?
        // Would be nicer for local development.
        const url = `${AVA_URL}/&${hostAvaName}`;
        if (window.__TAURI__) {
            yield window.desktopIntegration.writeToClipboard(url);
            notify(t('addParticipant.theConversationLinkHasBeenCopiedToYourClipboard'), options === null || options === void 0 ? void 0 : options.notificationOptions);
        }
        else if (window.navigator.share && !desktopSafari) {
            yield window.navigator.share({ url });
        }
        else {
            yield navigator.clipboard.writeText(url);
            notify(t('addParticipant.theConversationLinkHasBeenCopiedToYourClipboard'), options === null || options === void 0 ? void 0 : options.notificationOptions);
        }
    }), [hostAvaName, notify, t]);
    return { copyInviteLink };
};
