import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    filler: {
        flexGrow: 1,
        minWidth: 8,
    },
}));
export const ConvoV2DialogHeader = React.memo(({ children, onClose }) => {
    const classes = useStyles();
    return (React.createElement(Box, { className: classes.root },
        children,
        React.createElement(Box, { className: classes.filler }),
        React.createElement(CloseIcon, { onClick: onClose })));
});
