import { Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TtsWaveFormIcon from '../../components/Icons/TtsWaveFormIcon';
import { selectIsUsingTts } from '../../selectors/textToSpeech';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    ttsContainer: {
        display: 'flex',
        padding: '6px 8px',
        alignItems: 'center',
        gap: 6,
    },
    tooltip: {
        padding: '2.5px 8px',
        backgroundColor: theme.palette.ava.grey4,
        borderRadius: 4,
        fontSize: 14,
        textAlign: 'center',
    },
    sendToolTipBottom: {
        position: 'relative',
        top: (props) => (props.electronCaptionMode ? 75 : 10),
        right: 15,
    },
}));
const TtsToggle = React.forwardRef(({ handleToggle }, ref) => {
    const { t } = useTranslation();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const classes = useStyles({ electronCaptionMode });
    const isUsingTts = useAppSelector(selectIsUsingTts);
    return (React.createElement(Tooltip, { classes: { tooltip: classes.tooltip, tooltipPlacementBottom: classes.sendToolTipBottom }, title: isUsingTts ? t('ccMode.keyboard.tooltip.ttsOn') : t('ccMode.keyboard.tooltip.ttsOff'), placement: "bottom", disableInteractive: true },
        React.createElement("div", { className: classes.ttsContainer },
            React.createElement(TtsWaveFormIcon, { active: isUsingTts }),
            React.createElement(Switch, { checked: isUsingTts, onChange: handleToggle, color: "primary", ref: ref, inputProps: { 'aria-label': 'primary checkbox' } }))));
});
export default TtsToggle;
