import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FreeCaptionsImg from '../../../assets/images/free-captions.svg';
import ScribeImg from '../../../assets/images/scribe.svg';
import ScribeCaptionsImg from '../../../assets/images/scribe-captions.svg';
import PremiumCaptionsImg from '../../../assets/images/work-laptop.svg';
import { fetchRemainingCredits } from '../../actions/session';
import ScribeCorrectionModeBox from '../../components/Modals/ScribeCorrectionModeBox';
import { analytics } from '../../firebase';
import { useNotifications } from '../../hooks/useNotifications';
import { useV1Socket } from '../../hooks/useV1Socket';
import { getRecording } from '../../selectors';
import { selectHasRequestedScribe } from '../../selectors/combined';
import { selectCaptionQuality } from '../../selectors/conversation';
import { selectHasScribe, selectPendingScribe, selectStatus } from '../../selectors/legacy-conversation';
import { getPaidASRCreditTime, getScribeRemainingTime, selectAvaId, selectFeatures, selectOrganization, selectSubscription, } from '../../selectors/userProfile';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { setSelectedCaptions } from '../../store/slices/conversation';
import { decreasePaidASRCreditTime, decreaseScribeRemainingTime } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { showNewMessage } from '../../utils/intercom';
import * as segment from '../../utils/segment';
import { getPlanType, isPremiumCaptions, isUnlimitedPaidASR } from '../../utils/status';
import { sendTypeOnly, sendUsePaidAsrUpdateMessage } from '../../utils/ws-v1';
const parenthesize = (x) => x && `(${x})`;
const FR_TYPEFORM_URL = `https://ava-me.typeform.com/to/R8L5rC?lang=fr&src=$src&med=$med&mkt=ind#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
const EN_TYPEFORM_URL = `https://ava-me.typeform.com/to/q2n11h?lang=en&src=$src&med=$med&mkt=ind#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
const makeTypeformUrl = (url) => url.replace('$src', 'inb').replace('$med', window.isElectron ? 'cc' : 'webapp');
const launchURL = ({ source, organization, subscription, url, }) => {
    analytics.logEvent('app_submitted_typeform', {
        channel: 'inbound',
        source,
        market: (organization === null || organization === void 0 ? void 0 : organization.type) || 'individual',
        organization_name: organization === null || organization === void 0 ? void 0 : organization.name,
        plan: getPlanType(subscription),
    });
    if (window.desktopIntegration) {
        window.desktopIntegration.openExternalURL(url);
    }
    else {
        window.open(url, '_blank', 'noopener noreferrer');
    }
};
const MAILTO_BODY_EN = encodeURI(`Hello,

I have been using a fantastic app, Ava, that can definitely help me understand and participate much more effectively and easily in meetings and one-on-ones with you and my coworkers.

It is based on voice recognition and accurately transcribes what everyone says so I can follow in real-time.

I’d like to have a chance to show you the app, and also to discuss how we could get the rest of the team onboard as well. I’d love your help given that to function well in groups, other participants also need to have the app on their phone.

By the way, I'm copying a representative from the Ava team. Don't be too alarmed if they reach out! They're just here to help you get all set up.

Let me know when you have 10 minutes to chat,

Best`);
const MAILTO_SUBJECT_EN = encodeURI('Awesome app to help with last minute meetings - can we chat?');
const MAILTO_EN = `mailto:?cc=advocacy@ava.me&amp;subject=${MAILTO_SUBJECT_EN}&amp;body=${MAILTO_BODY_EN}`;
const MAILTO_BODY_FR = encodeURI(`Bonjour,

J'ai découvert une App fantastique qui peut m'aider à participer plus efficacement aux réunions et face à face avec mes collègues.

C'est une application basé sur une technologie de reconnaissance vocale qui transcrit en temps réel ce que disent les participants, pour que je puisse suivre en temps réel !

J'aimerais pouvoir vous faire une démo de l'app, et en profiter pour discuter de l'aménagement de cette solution pour nos réunions et le reste de l'équipe. Merci de m'aider à faire en sorte que ça fonctionne bien, et de faire en sorte que tous les participants utilisent l'app depuis leur mobile.

Je rajoute en copie un représentant de l'équipe Ava. Ne soyez pas surpris s'ils vous contactent. Ils sont là pour nous aider à mettre l'outil en place 👍

Merci de me dire quand vous avez quelques minutes pour en parler ensemble.

Bien à vous`);
const MAILTO_SUBJECT_FR = encodeURI('Superbe application ! Aide beaucoup pour les réunions de dernière minute - on peut en parler ?');
const MAILTO_FR = `mailto:?cc=advocacy@ava.me&amp;subject=${MAILTO_SUBJECT_FR}&amp;body=${MAILTO_BODY_FR}`;
function timeLeft(t, timeS) {
    let timeStr;
    if (timeS < 60) {
        timeStr = t('time.seconds').replace('$sec', timeS);
    }
    else {
        const timeM = Math.floor(timeS / 60);
        if (timeM < 60) {
            timeStr = t('time.minutes').replace('$min', timeM);
        }
        else {
            const timeH = Math.floor(timeM / 60);
            timeStr = t('time.hours').replace('$hr', timeH);
        }
    }
    return t('time.remaining').replace('$time', timeStr);
}
const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        borderRadius: 20,
        margin: 16,
    },
    usedAllPremiumContainer: {
        height: 130,
        width: '100%',
        marginTop: -130,
        fontSize: 36,
        textAlign: 'center',
        fontWeight: 600,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    usedAllPremiumInner: {
        maxWidth: 530,
    },
    content: {
        paddingBottom: 36,
        paddingLeft: 36,
        paddingRight: 36,
    },
    title: {
        fontWeight: 600,
        fontSize: 30,
        textAlign: 'center',
        paddingTop: 16,
        paddingLeft: 58,
        paddingRight: 58,
    },
    chooserTitle: {
        color: theme.palette.ava.blue,
    },
    ctaTitle: {
        color: theme.palette.ava.navy,
    },
    optionTitle: {
        fontWeight: 600,
    },
    italic: {
        fontStyle: 'italic',
    },
    iconContainer: {
        height: '100%',
    },
    icon: {
        fontSize: 36,
        color: 'white',
    },
    button: {
        '&:hover': {
            opacity: 0.9,
        },
        width: 265,
        height: 55,
        flexDirection: 'column',
        padding: 4,
        color: 'white',
        fontWeight: 600,
        fontSize: 16,
        borderRadius: 24.8,
        border: '1px solid',
        boxShadow: '-8.928px -8.928px 14.88px rgba(100, 218, 255, 0.160784)',
    },
    basicButton: {
        background: 'linear-gradient(46.71deg, #5BC6FF 16.55%, #4DA7DB 81.29%)',
        borderColor: theme.palette.ava.blue,
    },
    premiumButton: {
        background: 'linear-gradient(135.99deg, #50ACED -3.16%, #112967 88.89%)',
    },
    scribeButton: {
        background: '#02C874',
    },
    closeFooter: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#676666',
    },
    singularCTABox: {
        maxWidth: 600,
    },
    singularCTAGrid: {
        textAlign: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40,
    },
    closeIconContainer: {
        right: 24,
        position: 'absolute',
        top: 24,
        cursor: 'pointer',
    },
}));
function AccuracyOptionContainer(props) {
    const { theme, title, subtitle, accuracy, image, buttonClass, buttonText, buttonSubText, footer, selected, mdSize, onClick, dataQa, } = props;
    const classes = useStyles(theme);
    const addButtonClass = (klass) => `${classes.button} ${klass}`;
    return (React.createElement(Grid, { "data-qa": dataQa, item: true, xs: 12, md: mdSize },
        React.createElement(Grid, { container: true, direction: "column", justifyContent: "flex-start", alignItems: "center" },
            React.createElement(Typography, { variant: "h5", className: classes.optionTitle }, title),
            React.createElement(Typography, { noWrap: true }, subtitle),
            React.createElement(Typography, { noWrap: true, className: classes.italic }, accuracy),
            React.createElement(Box, { my: 2 },
                React.createElement("img", { src: image, alt: title, width: "230", height: "150" })),
            React.createElement(ButtonBase, { component: "div", className: addButtonClass(buttonClass), focusRipple: true, onClick: onClick },
                React.createElement(Grid, { container: true, direction: "row" },
                    selected && (React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", className: classes.iconContainer },
                            React.createElement(DoneIcon, { className: classes.icon })))),
                    React.createElement(Grid, { item: true, xs: selected ? 6 : 12 },
                        React.createElement(Box, { textAlign: "center", whiteSpace: "nowrap" }, buttonText),
                        React.createElement(Box, { textAlign: "center", whiteSpace: "nowrap" }, buttonSubText)))),
            React.createElement(Box, { mt: 2 },
                React.createElement(Typography, { noWrap: true }, footer)))));
}
function SingularCTA(props) {
    const { title, subtitle, image, buttonClass, buttonText, buttonSubText, closeText, onClick, onClose } = props;
    const classes = useStyles();
    const addButtonClass = (klass) => `${classes.button} ${klass}`;
    const addTitleClass = (klass) => `${classes.title} ${klass}`;
    return (React.createElement(Box, { className: classes.singularCTABox },
        React.createElement(DialogTitle, null,
            React.createElement(Typography, { className: addTitleClass(classes.ctaTitle), variant: "h5" },
                React.createElement(Box, { px: 4 }, title))),
        React.createElement(Grid, { container: true, direction: "column", justifyContent: "flex-start", alignItems: "center", className: classes.singularCTAGrid },
            React.createElement(Typography, null, subtitle),
            React.createElement(Box, { my: 2 },
                React.createElement("img", { src: image, alt: "{title}", width: "230", height: "150" })),
            React.createElement(ButtonBase, { component: "div", className: addButtonClass(classes[buttonClass]), focusRipple: true, onClick: onClick },
                React.createElement(Box, { textAlign: "center", whiteSpace: "nowrap" }, buttonText),
                React.createElement(Box, { textAlign: "center", whiteSpace: "nowrap" }, buttonSubText)),
            React.createElement(Box, { mt: 2 },
                React.createElement(Typography, { noWrap: true, onClick: onClose, className: classes.closeFooter }, closeText)))));
}
function SelectAccuracyDialog(props) {
    /* eslint-disable no-shadow */
    const { open, setDialogOpen } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const features = useAppSelector(selectFeatures);
    const { notify } = useNotifications();
    const navigate = useNavigate();
    const recording = useSelector(getRecording);
    const asrTime = Math.floor(useSelector(getPaidASRCreditTime) / 1000);
    const scribeRemainingTime = useAppSelector(getScribeRemainingTime) || 0;
    const scribeTime = Math.floor(scribeRemainingTime / 1000);
    const status = useSelector(selectStatus);
    const subscription = useSelector(selectSubscription);
    const selectedCaptions = useSelector(selectCaptionQuality);
    const avaId = useAppSelector(selectAvaId);
    const organization = useAppSelector(selectOrganization);
    const hasScribe = useAppSelector(selectHasScribe);
    const pendingScribe = useAppSelector(selectPendingScribe);
    const hasRequestedScribe = useAppSelector(selectHasRequestedScribe);
    // If defined, will show a singular CTA. If undefined -> will show 3 accuracy options.
    const [singularCTAConf, setSingularCTAConf] = useState(undefined);
    // If set to true, will show the scribe correction options instead of anything else.
    const [showScribeCorrectionModeBox, setShowScribeCorrectionModeBox] = useState(false);
    // If true, will show the "you use all your premium captions" form.
    const [usedAllPremium, setUsedAllPremium] = useState(false);
    const unlimitedPaidASR = isUnlimitedPaidASR(subscription, features);
    const canSelectPremium = unlimitedPaidASR || asrTime > 0;
    const scribeFlag = features.enableScribe;
    const canRequestScribe = scribeFlag && scribeTime > 0;
    const ongoingSubscription = subscription && subscription.ongoingSubscription;
    const subscriptionSubtype = subscription && subscription.ongoingSubscriptionSubtype;
    const [ws] = useV1Socket();
    const handleClose = () => {
        setDialogOpen(false);
        // The dialog takes some time to fade out, so this reduces flickering.
        setTimeout(() => {
            setSingularCTAConf(undefined);
            setUsedAllPremium(false);
            setShowScribeCorrectionModeBox(false);
        }, 500);
    };
    const requestScribe = () => {
        sendTypeOnly(ws, 'request-scribe');
        notify(t('scribe.3dRequest'));
    };
    const endScribe = () => {
        sendTypeOnly(ws, 'end-scribe');
    };
    let currentRecordingQuality;
    if (recording) {
        if (hasScribe || pendingScribe)
            currentRecordingQuality = 'scribe';
        //TODO but remove dependence on status for this
        else if (isPremiumCaptions(status, avaId))
            currentRecordingQuality = 'premium';
        else
            currentRecordingQuality = 'free';
    }
    useEffect(() => {
        if (hasScribe)
            dispatch(setSelectedCaptions('scribe'));
    }, [hasScribe]);
    useEffect(() => {
        const fetchRemaining = () => {
            if (!avaId)
                return;
            fetchRemainingCredits(avaId)(dispatch);
        };
        fetchRemaining();
        // Keep fetching the remaining time if recording is happening, otherwise fetch only once.
        // Also pre-emptively reducing the time second by second between fetches.
        if (recording) {
            const intervalFetch = setInterval(fetchRemaining, 15000);
            const intervalDecrease = setInterval(() => {
                if (currentRecordingQuality === 'premium')
                    dispatch(decreasePaidASRCreditTime());
                if (currentRecordingQuality === 'scribe')
                    dispatch(decreaseScribeRemainingTime());
            }, 1000);
            return () => {
                clearInterval(intervalFetch);
                clearInterval(intervalDecrease);
            };
        }
    }, [avaId, recording]);
    const defaultQuality = canSelectPremium ? 'premium' : 'free';
    const selected = selectedCaptions || currentRecordingQuality || defaultQuality;
    const previousQuality = useRef(currentRecordingQuality);
    useEffect(() => {
        if (recording &&
            currentRecordingQuality === 'free' &&
            previousQuality.current === 'premium' &&
            selectedCaptions !== 'free' &&
            !unlimitedPaidASR &&
            // We do not show the dialog to Community users.
            ongoingSubscription !== 'Freedom') {
            navigate('/web/transcript');
            // We dropped from premium to free, and that's not what the user wanted.
            setUsedAllPremium(true);
            setDialogOpen(true);
        }
        previousQuality.current = currentRecordingQuality;
    }, [currentRecordingQuality]);
    const freeClick = () => {
        segment.track('Basic Accuracy Clicked');
        if (hasRequestedScribe)
            endScribe();
        dispatch(setSelectedCaptions('free'));
        sendUsePaidAsrUpdateMessage(ws, false);
        handleClose();
    };
    const premiumClick = () => {
        segment.track('Premium Accuracy Clicked');
        if (usedAllPremium) {
            // usedAllPremium is never shown to Freedom users, so it's left out.
            if (ongoingSubscription === 'Free') {
                const onClick = () => {
                    launchURL({
                        source: 'premium',
                        organization: organization,
                        subscription,
                        url: makeTypeformUrl(i18n.language === 'fr' ? FR_TYPEFORM_URL : EN_TYPEFORM_URL),
                    });
                    handleClose();
                };
                setSingularCTAConf({
                    title: t('accuracy.getUnlimitedPremium'),
                    subtitle: t('accuracy.getUnlimitedPremiumSubtext'),
                    image: PremiumCaptionsImg,
                    buttonClass: 'premiumButton',
                    buttonText: t('accuracy.seeIfYouQualify'),
                    buttonSubText: '',
                    closeText: t('accuracy.downgradeToBasic'),
                    onClick,
                });
            }
            else if (subscriptionSubtype === 'Lite' || subscriptionSubtype === 'Trial') {
                window.open(i18n.language === 'fr' ? MAILTO_FR : MAILTO_EN, '_self');
                handleClose();
            }
            else
                handleClose();
        }
        else if (canSelectPremium) {
            if (hasRequestedScribe)
                endScribe();
            dispatch(setSelectedCaptions('premium'));
            sendUsePaidAsrUpdateMessage(ws, true);
            handleClose();
        }
        else if (ongoingSubscription) {
            if (ongoingSubscription === 'Free') {
                const onClick = () => {
                    launchURL({
                        source: 'accuracy',
                        organization: organization,
                        subscription,
                        url: makeTypeformUrl(i18n.language === 'fr' ? FR_TYPEFORM_URL : EN_TYPEFORM_URL),
                    });
                    handleClose();
                };
                setSingularCTAConf({
                    title: t('accuracy.getUnlimitedPremium'),
                    subtitle: t('accuracy.getUnlimitedPremiumSubtext'),
                    image: PremiumCaptionsImg,
                    buttonClass: 'premiumButton',
                    buttonText: t('accuracy.seeIfYouQualify'),
                    buttonSubText: '',
                    closeText: t('accuracy.downgradeToBasic'),
                    onClick,
                });
            }
            else if (ongoingSubscription === 'Freedom') {
                const price = subscriptionSubtype === 'Yearly' ? '$2.99' : '$3.99';
                const onClick = () => {
                    // TODO: Stripe
                    // if monthly -> show 'Buy 1 hour Premium ($3.99)'
                    // if yearly -> show 'Buy 1 hour Premium ($2.99)'
                    showNewMessage(t('accuracy.scribeIntercomBuyPremium'));
                    handleClose();
                };
                setSingularCTAConf({
                    title: t('accuracy.getHigherQuality'),
                    subtitle: t('accuracy.getHigherQualitySubtext'),
                    image: PremiumCaptionsImg,
                    buttonClass: 'premiumButton',
                    buttonText: t('accuracy.buy1HourPremium'),
                    buttonSubText: price,
                    closeText: t('accuracy.continueWithFree'),
                    onClick,
                });
            }
            else if (subscriptionSubtype === 'Lite') {
                const onClick = () => {
                    window.open(i18n.language === 'fr' ? MAILTO_FR : MAILTO_EN, '_self');
                    handleClose();
                };
                setSingularCTAConf({
                    title: t('accuracy.getHigherQuality'),
                    subtitle: t('accuracy.getHigherQualitySubtext'),
                    image: PremiumCaptionsImg,
                    buttonClass: 'premiumButton',
                    buttonText: t('accuracy.remindAdmin'),
                    buttonSubText: '',
                    closeText: t('accuracy.continueWithFree'),
                    onClick,
                });
            }
        }
    };
    const scribeClick = () => {
        segment.track('Scribe Accuracy Clicked');
        if (usedAllPremium) {
            if (ongoingSubscription === 'Free') {
                const onClick = () => {
                    launchURL({
                        source: 'scribe-used-all-premium',
                        organization: organization,
                        subscription,
                        url: makeTypeformUrl(i18n.language === 'fr' ? FR_TYPEFORM_URL : EN_TYPEFORM_URL),
                    });
                    handleClose();
                };
                setSingularCTAConf({
                    title: t('accuracy.professionalGradeCaptions'),
                    subtitle: t('accuracy.professionalGradeSubtext'),
                    image: ScribeImg,
                    buttonClass: 'scribeButton',
                    buttonText: t('accuracy.seeIfYouQualify'),
                    buttonSubText: '',
                    closeText: t('accuracy.downgradeToBasic'),
                    onClick,
                });
            }
            else if (subscriptionSubtype === 'Lite' || subscriptionSubtype === 'Trial') {
                window.open(i18n.language === 'fr' ? MAILTO_FR : MAILTO_EN, '_self');
                handleClose();
            }
            else
                handleClose();
        }
        else if (canRequestScribe) {
            // When user selects Scribe, we do not update the UI until an actual Scribe
            // joins the conversation.
            dispatch(setSelectedCaptions(undefined));
            requestScribe();
            setShowScribeCorrectionModeBox(true);
        }
        else if (ongoingSubscription === 'Free') {
            const onClick = () => {
                launchURL({
                    source: 'scribe',
                    organization: organization,
                    subscription,
                    url: makeTypeformUrl(i18n.language === 'fr' ? FR_TYPEFORM_URL : EN_TYPEFORM_URL),
                });
                handleClose();
            };
            setSingularCTAConf({
                title: t('accuracy.professionalGradeCaptions'),
                subtitle: t('accuracy.professionalGradeSubtext'),
                image: ScribeImg,
                buttonClass: 'scribeButton',
                buttonText: t('accuracy.seeIfYouQualify'),
                buttonSubText: '',
                closeText: t('accuracy.downgradeToLower'),
                onClick,
            });
        }
        else if (ongoingSubscription === 'Freedom') {
            const onClick = () => {
                showNewMessage(t('accuracy.scribeIntercomInterestPrompt'));
                handleClose();
            };
            setSingularCTAConf({
                title: t('accuracy.professionalGradeCaptions'),
                subtitle: t('accuracy.professionalGradeSubtext'),
                image: ScribeImg,
                buttonClass: 'scribeButton',
                buttonText: t('accuracy.requestAvaScribe'),
                buttonSubText: '',
                closeText: t('accuracy.downgradeToLower'),
                onClick,
            });
        }
        else if (ongoingSubscription && subscriptionSubtype !== 'Lite') {
            if (scribeFlag) {
                const onClick = () => {
                    showNewMessage(t('accuracy.scribeIntercomMorePrompt'));
                    handleClose();
                };
                // There is an assumption here that the user does not have any scribe
                // time left. This is true as long as canRequestScribe above expects
                // scribeFlag && scribeTime > 0. If those assumptions change, the code
                // needs to be evaluated.
                setSingularCTAConf({
                    title: t('accuracy.getHigherQualityWithAvaScribe'),
                    subtitle: t('accuracy.runOutOfScribe'),
                    image: ScribeImg,
                    buttonClass: 'scribeButton',
                    buttonText: t('accuracy.requestScribeTime'),
                    buttonSubText: '',
                    closeText: t('accuracy.keepPremium'),
                    onClick,
                });
            }
            else {
                const onClick = () => {
                    showNewMessage(t('accuracy.scribeIntercomInterestPrompt'));
                    handleClose();
                };
                setSingularCTAConf({
                    title: t('accuracy.professionalGradeCaptions'),
                    subtitle: t('accuracy.professionalGradeSubtext'),
                    image: ScribeImg,
                    buttonClass: 'scribeButton',
                    buttonText: t('accuracy.requestAvaScribe'),
                    buttonSubText: '',
                    closeText: t('accuracy.downgradeToLower'),
                    onClick,
                });
            }
        }
        else if (subscriptionSubtype === 'Lite') {
            const onClick = () => {
                showNewMessage(t('accuracy.scribeIntercomInterestPrompt'));
                handleClose();
            };
            setSingularCTAConf({
                title: t('accuracy.professionalGradeCaptions'),
                subtitle: t('accuracy.professionalGradeSubtext'),
                image: ScribeImg,
                buttonClass: 'scribeButton',
                buttonText: t('accuracy.requestAvaScribe'),
                buttonSubText: '',
                closeText: t('accuracy.downgradeToLower'),
                onClick,
            });
        }
    };
    let freeButtonText;
    let freeButtonSubText = '';
    if (usedAllPremium) {
        if (ongoingSubscription === 'Free') {
            freeButtonText = t('accuracy.useFreeCaptions');
        }
        else if (subscriptionSubtype === 'Lite' || subscriptionSubtype === 'Trial') {
            freeButtonText = t('accuracy.downgradeToFree');
        }
        else {
            // This should never happen
            freeButtonText = t('accuracy.useFreeCaptions');
        }
    }
    else {
        freeButtonText = t('accuracy.freeButton');
        freeButtonSubText = t('accuracy.unlimited');
    }
    let premiumButtonText;
    let premiumButtonSubText = '';
    if (usedAllPremium) {
        if (ongoingSubscription === 'Free') {
            premiumButtonText = t('accuracy.try15DayFree');
        }
        else if (subscriptionSubtype === 'Lite' || subscriptionSubtype === 'Trial') {
            premiumButtonText = t('accuracy.askYourAdmin');
        }
        else {
            // This should never happen
            premiumButtonText = t('accuracy.askYourAdmin');
        }
    }
    else {
        premiumButtonText = t('accuracy.premiumButton');
        if (unlimitedPaidASR) {
            premiumButtonSubText = t('accuracy.unlimited');
        }
        else if (asrTime > 0) {
            premiumButtonSubText = timeLeft(t, asrTime);
        }
        else if (ongoingSubscription === 'Free') {
            premiumButtonSubText = t('accuracy.freeTrial');
        }
        else {
            premiumButtonSubText = timeLeft(t, asrTime);
        }
    }
    let scribeButtonText;
    let scribeButtonSubText = '';
    if (usedAllPremium) {
        if (ongoingSubscription === 'Free') {
            scribeButtonText = t('accuracy.learnMore');
        }
        else if (subscriptionSubtype === 'Lite' || subscriptionSubtype === 'Trial') {
            scribeButtonText = t('accuracy.askYourAdmin');
        }
        else {
            // This should never happen
            scribeButtonText = t('accuracy.askYourAdmin');
        }
    }
    else {
        scribeButtonText = t('accuracy.scribeButton');
        if (features.enableScribe) {
            scribeButtonSubText = timeLeft(t, scribeTime);
        }
        else {
            scribeButtonSubText = t('accuracy.learnMore');
        }
    }
    let usedAllPremiumText;
    if (usedAllPremium) {
        if (ongoingSubscription === 'Free') {
            usedAllPremiumText = t('accuracy.usedAllPremium');
        }
        else if (subscriptionSubtype === 'Lite' || subscriptionSubtype === 'Trial') {
            usedAllPremiumText = t('accuracy.trialFinished');
        }
        else {
            // This should never happen
            usedAllPremiumText = t('accuracy.usedAllPremium');
        }
    }
    const mdSize = unlimitedPaidASR ? 6 : 4;
    const addTitleClass = (klass) => `${classes.title} ${klass}`;
    return (React.createElement(Dialog, { open: open, maxWidth: "lg", onClose: handleClose, classes: { paper: classes.dialogRoot } },
        React.createElement(Box, { className: classes.closeIconContainer, onClick: handleClose },
            React.createElement(CloseIcon, { fontSize: "large", style: { color: theme.palette.text } })),
        usedAllPremium && (React.createElement(Hidden, { mdDown: true },
            React.createElement(Box, { className: classes.usedAllPremiumContainer },
                React.createElement(Box, { className: classes.usedAllPremiumInner }, usedAllPremiumText)))),
        !showScribeCorrectionModeBox && singularCTAConf === undefined && (React.createElement(Box, null,
            React.createElement(DialogTitle, null,
                React.createElement(Typography, { className: addTitleClass(classes.chooserTitle), variant: "h5" }, t('accuracy.qualityHeader'))),
            React.createElement(DialogContent, { className: classes.content },
                React.createElement(Grid, { container: true, spacing: 5, direction: "row", justifyContent: "space-around", alignItems: "stretch" },
                    !unlimitedPaidASR && (React.createElement(AccuracyOptionContainer, { theme: theme, title: t('accuracy.freeCaptions'), subtitle: t('accuracy.freeSubtext'), accuracy: t('accuracy.freeAccuracy'), image: FreeCaptionsImg, buttonClass: classes.basicButton, buttonText: freeButtonText, buttonSubText: parenthesize(freeButtonSubText), footer: t('accuracy.freeFooter'), selected: !usedAllPremium && selected === 'free', mdSize: mdSize, dataQa: "freeCaptionsButton", 
                        // QA doesn't see this selector
                        onClick: freeClick })),
                    React.createElement(AccuracyOptionContainer, { theme: theme, title: t('accuracy.premiumCaptions'), subtitle: t('accuracy.premiumSubtext'), accuracy: t('accuracy.premiumAccuracy'), image: PremiumCaptionsImg, buttonClass: classes.premiumButton, buttonText: premiumButtonText, buttonSubText: parenthesize(premiumButtonSubText), footer: t('accuracy.premiumFooter'), selected: !usedAllPremium && selected === 'premium', mdSize: mdSize, dataQa: "premiumCaptionsButton", 
                        // QA doesn't see this selector -???
                        onClick: premiumClick }),
                    React.createElement(AccuracyOptionContainer, { theme: theme, title: t('accuracy.scribeCaptions'), subtitle: t('accuracy.scribeSubtext'), accuracy: t('accuracy.scribeAccuracy'), image: ScribeCaptionsImg, buttonClass: classes.scribeButton, buttonText: scribeButtonText, buttonSubText: parenthesize(scribeButtonSubText), footer: t('accuracy.scribeFooter'), selected: !usedAllPremium && selected === 'scribe', mdSize: mdSize, dataQa: "requestScribeButton", 
                        // QA doesn't see this selector
                        onClick: scribeClick }))))),
        !showScribeCorrectionModeBox && singularCTAConf !== undefined && (React.createElement(SingularCTA, Object.assign({ onClose: handleClose }, singularCTAConf))),
        showScribeCorrectionModeBox && React.createElement(ScribeCorrectionModeBox, { onClose: handleClose, theme: theme })));
}
export default SelectAccuracyDialog;
