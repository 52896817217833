import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useAutoScroll } from '../../hooks/useAutoScroll';
import { selectAllTranscripts, selectTotalTranscriptCount, selectTranscripts, } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { ConvoV2FollowTranscriptButton } from './ConvoV2FollowTranscriptButton';
import { ConvoV2TranscriptItem } from './ConvoV2TranscriptItem';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        width: '100%',
    },
    list: {
        display: 'flex',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        flexGrow: 2,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        WebkitScrollbar: {
            WebkitAppearance: 'none',
        },
        // Ideally we'd just set `justify-content: end`, but in that case
        // overflow at the top won't show a scrollbar. That behavior won't get fixed:
        // https://bugs.chromium.org/p/chromium/issues/detail?id=411624
        '&::before': {
            content: '""',
            display: 'block',
            marginTop: 'auto',
        },
    },
    followButtonContainer: {
        display: 'flex',
        width: '100%',
        maxWidth: 812,
        alignSelf: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            paddingRight: 16,
        },
    },
    followButtonHide: {
        visibility: 'hidden',
    },
}));
export const ConvoV2TranscriptListContent = React.memo((props) => {
    // To prevent re-rendering the entire list when only one transcript changes,
    // only trigger a re-render when the transcript count changes and let each
    // transcript item respond to its own updates.
    const allTranscripts = selectAllTranscripts(window.store.getState());
    const transcriptCount = useAppSelector(selectTotalTranscriptCount);
    const scrollableContainer = useRef(null);
    const { follow, showMoreButton } = useAutoScroll(scrollableContainer, selectTranscripts);
    const classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.root, props.className) },
        React.createElement(Box, { className: classes.list, ref: scrollableContainer }, allTranscripts.map((transcript) => (React.createElement(ConvoV2TranscriptItem, { key: transcript.id, transcriptId: transcript.id })))),
        React.createElement(Box, { className: classes.followButtonContainer },
            React.createElement(ConvoV2FollowTranscriptButton, { className: classNames({ [classes.followButtonHide]: !showMoreButton }), onClick: follow }))));
});
