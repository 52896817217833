var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useLaunchApp } from '../hooks/useLaunchApp';
import DesktopAppCaptionsModal from '../modules/scribe/DesktopAppCaptionsModal';
import { isMobile } from '../utils';
export default () => {
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const { launchApp } = useLaunchApp();
    useEffect(() => {
        const asyncInner = () => __awaiter(void 0, void 0, void 0, function* () {
            const { launchTried } = yield launchApp();
            if (launchTried) {
                setShowDownloadModal(true);
            }
        });
        asyncInner();
    }, [isMobile, launchApp]);
    if (!showDownloadModal)
        return null;
    return React.createElement(DesktopAppCaptionsModal, { onClose: () => setShowDownloadModal(false) });
};
