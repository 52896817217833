import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { useLaunchApp } from '../../hooks/useLaunchApp';
import { ConvoV2PrimaryButton } from './ConvoV2PrimaryButton';
import { ConvoV2RadiatingLogo } from './ConvoV2RadiatingLogo';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 160,
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 32,
        gap: 16,
        textAlign: 'center',
    },
    title: {
        fontWeight: 700,
        fontSize: 32,
        color: '#000',
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: '35px',
        fontSize: 18,
        color: '#000',
        opacity: 0.64,
    },
    launchButton: {
        width: 240,
    },
    downloadOrLaunch: {
        marginTop: 64,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: 24,
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.64)',
        '& a': {
            color: '#0679B4',
        },
    },
    notWorking: {
        background: 'rgba(0, 0, 0, 0.04)',
        padding: '4px 16px',
    },
}));
export const ConvoV2AppLaunchScreen = React.memo(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { launchApp } = useLaunchApp();
    const [showWebLink, setShowWebLink] = useState(false);
    const showWebLinkTimeout = useRef(undefined);
    useEffect(() => {
        showWebLinkTimeout.current = setTimeout(() => {
            setShowWebLink(true);
        }, 5000);
        launchApp();
        return () => clearTimeout(showWebLinkTimeout.current);
    }, []);
    return (React.createElement(Box, { className: classes.root },
        React.createElement(ConvoV2RadiatingLogo, null),
        React.createElement(Box, { className: classes.main },
            React.createElement(Box, { className: classes.title }, t('convoV2.download.launching')),
            React.createElement(Box, { className: classes.description },
                React.createElement(Box, null,
                    React.createElement(PhraseMarkdownText, { phraseKey: "convoV2.download.descriptionLine1" })),
                React.createElement(Box, null, t('convoV2.download.descriptionLine2'))),
            React.createElement(ConvoV2PrimaryButton, { className: classes.launchButton, onClick: launchApp }, t('convoV2.download.launch'))),
        React.createElement(Box, { className: classes.downloadOrLaunch },
            React.createElement(Box, null,
                t('convoV2.download.notInstalled'),
                "\u00A0",
                React.createElement(Link, { to: "/download" }, t('convoV2.download.downloadNow'))),
            showWebLink && (React.createElement(Box, { className: classes.notWorking },
                t('convoV2.download.notWorking'),
                "\u00A0",
                t('convoV2.download.youCanAlso'),
                "\u00A0",
                React.createElement(Link, { to: "/" }, t('convoV2.download.useAvaInBrowser')))))));
});
