import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkImage from '../../../assets/icons/convov2-checkmark.svg';
import { MeetingServiceIcon } from '../../components/Icons/MeetingServiceIcon';
import { useAvaConnectUIHelpers } from '../../hooks/useAvaConnectUIHelpers';
import { selectRecallAIStatus } from '../../selectors/recallAI';
import { useAppSelector } from '../../store/store';
import { ConvoV2PrimaryButton } from './ConvoV2PrimaryButton';
const ICON_COUNT = 3;
const ICON_WIDTH = 24;
const ICON_GAP = 4;
const INPUT_CONTAINER_GAP = 10;
const ICON_CONTAINER_LARGE_WIDTH = (ICON_COUNT - 1) * ICON_GAP + ICON_COUNT * ICON_WIDTH;
const ICON_CONTAINER_SMALL_WIDTH = ICON_WIDTH;
// When a valid link is entered, we show only one icon instead of all,
// so we want to grow the input to componsate for the smaller icon container,
// preventing jumping of the input container size
const ICON_CONTAINER_COMPENSATION = ICON_CONTAINER_LARGE_WIDTH - ICON_CONTAINER_SMALL_WIDTH;
const INPUT_SMALL_WIDTH = 196;
const INPUT_LARGE_WIDTH = INPUT_SMALL_WIDTH + ICON_CONTAINER_COMPENSATION;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        gap: 8,
    },
    rootMobile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        marginTop: 20,
        gap: 8,
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: INPUT_CONTAINER_GAP,
        height: 40,
        border: `1px solid #50ACED`,
        borderRadius: 8,
        paddingRight: 4,
        paddingLeft: 12,
    },
    inputContainerWithValue: {},
    inputContainerError: {
        borderColor: theme.palette.ava.red2,
    },
    inputIcons: {
        display: 'flex',
        alignItems: 'center',
        gap: ICON_GAP,
        width: ICON_CONTAINER_LARGE_WIDTH,
        maxWidth: ICON_CONTAINER_LARGE_WIDTH,
        transition: '300ms max-width ease, 300ms gap ease',
    },
    inputIconsSmall: {
        maxWidth: ICON_CONTAINER_SMALL_WIDTH,
        gap: 0,
    },
    inputIcon: {
        width: ICON_WIDTH,
        maxWidth: ICON_WIDTH,
        transition: '300ms max-width ease',
    },
    inputIconHidden: {
        maxWidth: 0,
    },
    input: {
        padding: '0 12px',
        paddingRight: 8,
        height: '100%',
        border: 0,
        outline: 0,
        fontFamily: theme.typography.fontFamily,
        width: INPUT_LARGE_WIDTH,
        maxWidth: INPUT_SMALL_WIDTH,
        transition: '300ms max-width ease',
        '&::placeholder': {
            color: '#778290',
            fontSize: 14,
        },
        '&:disabled': {
            background: 'none',
        },
    },
    inputLarge: {
        maxWidth: INPUT_LARGE_WIDTH,
    },
    connectButton: {
        borderRadius: 8,
        padding: '10px 16px',
        height: 40,
    },
    errorMessage: {
        color: theme.palette.ava.red2,
        fontSize: 16,
        fontWeight: 600,
    },
    connectionMessage: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    captioning: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        height: 48,
        borderRadius: 40,
        border: `1px solid ${theme.palette.ava.grey7}`,
        padding: '0 16px',
        color: '#1B367C',
        fontSize: 18,
        fontWeight: 600,
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.ava.navy5,
        },
    },
    disconnect: {
        padding: '10px 16px',
        borderRadius: 8,
        background: theme.palette.ava.red3,
        color: theme.palette.ava.white,
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
    },
}));
const SERVICE_ICON_ORDER = ['Zoom', 'Google Meet', 'MS Teams'];
export const ConvoV2AvaConnectCard = React.memo(() => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md')) && !window.isElectron;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) && !window.isElectron;
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const { meetingLink, meetingLinkInvalid, meetingLinkEditable, meetingLinkService, onMeetingLinkKeyDown, onMeetingLinkChange, action, actionLabel, connectionMessage, connecting, } = useAvaConnectUIHelpers({ isTabletOrMobile, notificationOptions: { variant: 'convoV2' } });
    const meetingLinkShown = recallAIStatus !== 'CAPTIONING' && (recallAIStatus !== 'DISCONNECT' || !isMobile);
    return (React.createElement(Box, { className: !isMobile ? classes.root : classes.rootMobile },
        meetingLinkShown && (React.createElement(Box, { className: classNames(classes.inputContainer, {
                [classes.inputContainerError]: meetingLinkInvalid,
                [classes.inputContainerWithValue]: !!meetingLinkService,
            }) },
            React.createElement(Box, { className: classNames(classes.inputIcons, { [classes.inputIconsSmall]: !!meetingLinkService }) }, SERVICE_ICON_ORDER.map((service) => (React.createElement(MeetingServiceIcon, { key: service, service: service, className: classNames(classes.inputIcon, {
                    [classes.inputIconHidden]: meetingLinkService && meetingLinkService !== service,
                }) })))),
            React.createElement("input", { className: classNames(classes.input, { [classes.inputLarge]: !!meetingLinkService }), placeholder: t('connectToOnlineMeetings.paste'), autoFocus: true, value: meetingLink, disabled: !meetingLinkEditable, onKeyDown: onMeetingLinkKeyDown, onChange: onMeetingLinkChange }))),
        recallAIStatus === 'NOT_CONNECTED' && (!meetingLinkInvalid || !isMobile) && (React.createElement(ConvoV2PrimaryButton, { className: classes.connectButton, disabled: !action, onClick: action }, actionLabel)),
        meetingLinkInvalid && React.createElement(Box, { className: classes.errorMessage }, t('connectToOnlineMeetings.invalid')),
        recallAIStatus === 'ERROR' && React.createElement(Box, { className: classes.errorMessage }, connectionMessage),
        connecting && (React.createElement(Box, { className: classes.connectionMessage },
            connectionMessage,
            React.createElement(CircularProgress, { size: 16 }))),
        recallAIStatus === 'CONNECTED' && (React.createElement(Box, { className: classes.connectionMessage },
            connectionMessage,
            React.createElement("img", { src: CheckmarkImage }))),
        recallAIStatus === 'CAPTIONING' && meetingLinkService && (React.createElement(Box, { className: classes.captioning, onClick: action },
            React.createElement(MeetingServiceIcon, { service: meetingLinkService }),
            connectionMessage)),
        recallAIStatus === 'DISCONNECT' && (React.createElement(Box, { className: classes.disconnect, onClick: action }, actionLabel))));
});
