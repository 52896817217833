import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: 75,
        right: 75,
        background: theme.palette.ava.grey8,
        borderRadius: 20,
        padding: '32px 48px',
        color: theme.palette.ava.white,
    },
    closeIcon: {
        position: 'absolute',
        top: 16,
        right: 16,
        cursor: 'pointer',
    },
}));
export const ConvoV2FeedbackPopup = React.memo(({ children, onClose }) => {
    const classes = useStyles();
    return (React.createElement(Box, { className: classes.root },
        React.createElement(CloseIcon, { className: classes.closeIcon, onClick: onClose }),
        children));
});
