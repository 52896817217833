import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ClickAwayListener, DialogTitle, IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Input from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvatarLogo from '../../components/Icons/AvatarLogo';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectConfigEnableSolodia } from '../../selectors/auth';
import { selectSoloDiaSpeakersWhoSpoke } from '../../selectors/legacy-conversation';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { sendAssignBlocToDifferentSpeaker, sendAssignVoicingSpearkerName } from '../../utils/ws-v1';
const SoloDiaNameModalContext = React.createContext({
    speaker: null,
    isInternalAudio: false,
    onSelect: () => ({}),
    transcriptId: '',
});
export const useSoloDiaSpeakerNameModalContext = () => {
    const context = React.useContext(SoloDiaNameModalContext);
    if (!context) {
        throw new Error('SoloDiaNameModalProvider is not in the component tree.');
    }
    return context;
};
export const SoloDiaNameModalProvider = ({ children }) => {
    const [state, setState] = React.useState({
        speaker: null,
        isInternalAudio: false,
        transcriptId: '',
    });
    function onSelect(speaker, isInternalAudio, transcriptId) {
        setState({ speaker, isInternalAudio, transcriptId });
    }
    return React.createElement(SoloDiaNameModalContext.Provider, { value: Object.assign(Object.assign({}, state), { onSelect }) }, children);
};
const useStyles = makeStyles((theme) => ({
    speakerNameWrapper: {
        border: '1px solid',
        borderColor: theme.palette.ava.blue2,
        borderRadius: '500px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: (props) => (props.electronCaptionMode ? 0 : undefined),
    },
    speakerNameInput: {
        paddingLeft: '20px',
    },
    speakerNameButton: {
        backgroundColor: theme.palette.ava.blue2,
        color: 'white',
        padding: (props) => (props.electronCaptionMode ? 2.5 : undefined),
        borderRadius: '500px',
        '&:disabled': {
            color: 'white',
            opacity: 0.2,
        },
        '&:hover': {
            background: theme.palette.ava.blue4,
        },
    },
    // container for solo dia names UI
    soloDiaParticipantContainer: {
        padding: 6,
        borderRadius: 15,
        maxWidth: 450,
        background: theme.palette.ccMode.backgroundGradient,
        marginLeft: 25,
    },
    soloDiaV2Container: {
        position: 'absolute',
        right: 0,
        top: 60,
        zIndex: 9999,
        padding: 6,
        borderRadius: 15,
        maxWidth: 450,
        background: theme.palette.ccMode.backgroundGradient,
        marginLeft: 25,
        borderLeft: '1px solid rgba(112, 144, 176, 0.2)',
        borderBottom: '1px solid rgba(112, 144, 176, 0.2)',
    },
    soloDiaV2DesktopSpeaker: {
        display: 'flex',
        gap: 14,
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: 0,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
const SoloDiaSpeakerNameModal = () => {
    const { t } = useTranslation();
    const { speaker, onSelect, transcriptId } = useSoloDiaSpeakerNameModalContext();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const [speakerName, setSpeakerName] = React.useState('');
    const [ws] = useV1Socket();
    const isSoloDiaV2 = useAppSelector(selectConfigEnableSolodia);
    const classes = useStyles({ electronCaptionMode });
    const solodiaSpeakersToShow = useAppSelector(selectSoloDiaSpeakersWhoSpoke);
    function onClose() {
        onSelect(null, false, '');
    }
    function assignSpeakerName() {
        if (speaker && ws) {
            sendAssignVoicingSpearkerName(ws, speaker.avaId, speakerName);
            onClose();
            setSpeakerName('');
        }
    }
    function reassignToExistingSpeaker(selectedSpeaker) {
        if (speaker && ws && transcriptId.length) {
            sendAssignBlocToDifferentSpeaker(ws, selectedSpeaker.avaId, transcriptId);
            onClose();
        }
    }
    if (!speaker) {
        return null;
    }
    if (electronCaptionMode) {
        if (isSoloDiaV2) {
            return (React.createElement(ClickAwayListener, { onClickAway: onClose },
                React.createElement("div", { className: classes.soloDiaV2Container },
                    React.createElement("div", { style: { display: 'flex', alignItems: 'center', flexDirection: 'column' } },
                        React.createElement("div", { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 10,
                                width: '100%',
                                maxHeight: solodiaSpeakersToShow.length > 2 ? '75px' : 'auto',
                                overflowY: solodiaSpeakersToShow.length > 2 ? 'scroll' : 'hidden',
                            } }, solodiaSpeakersToShow.map((speaker) => {
                            var _a;
                            return (React.createElement(Button, { className: classes.soloDiaV2DesktopSpeaker, key: speaker.userName, onClick: () => {
                                    reassignToExistingSpeaker(speaker);
                                } },
                                React.createElement("div", null,
                                    (speaker === null || speaker === void 0 ? void 0 : speaker.userAvatar) && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                                            width: 32,
                                            height: 32,
                                        } })),
                                    speaker && !speaker.userAvatar && (React.createElement(AvatarLogo, { color: (_a = speaker.theme) === null || _a === void 0 ? void 0 : _a.color, width: 32, height: 32 }))),
                                React.createElement(Typography, { sx: { color: (theme) => theme.palette.ava.grey7, fontSize: 18 } }, speaker.myself ? t('transcripts.you') : speaker.userName),
                                React.createElement("div", { style: { width: '5px' } })));
                        })),
                        React.createElement("div", { style: { display: 'flex', marginTop: 8 } },
                            React.createElement("div", { style: { marginRight: '10px' } },
                                (speaker === null || speaker === void 0 ? void 0 : speaker.userAvatar) && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                                        width: 32,
                                        height: 32,
                                    } })),
                                speaker && !speaker.userAvatar && React.createElement(AvatarLogo, { color: speaker.theme.color, width: 32, height: 32 })),
                            React.createElement("div", { className: classes.speakerNameWrapper },
                                React.createElement(Input, { placeholder: t('transcripts.suggestSpeakerPlaceholder'), fullWidth: true, autoFocus: true, className: classes.speakerNameInput, value: speakerName, onChange: (e) => setSpeakerName(e.currentTarget.value) }),
                                React.createElement(Button, { disabled: speakerName.length === 0, className: classes.speakerNameButton, onClick: assignSpeakerName }, t('transcripts.suggestSpeakerButtonText'))))))));
        }
        else
            return (React.createElement(ClickAwayListener, { onClickAway: onClose },
                React.createElement("div", { className: classes.soloDiaParticipantContainer },
                    React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                        React.createElement("div", { style: { marginRight: '10px' } },
                            (speaker === null || speaker === void 0 ? void 0 : speaker.userAvatar) && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                                    width: 32,
                                    height: 32,
                                } })),
                            speaker && !speaker.userAvatar && React.createElement(AvatarLogo, { color: speaker.theme.color, width: 32, height: 32 })),
                        React.createElement("div", { className: classes.speakerNameWrapper },
                            React.createElement(Input, { placeholder: t('transcripts.suggestSpeakerPlaceholder'), fullWidth: true, autoFocus: true, className: classes.speakerNameInput, value: speakerName, onChange: (e) => setSpeakerName(e.currentTarget.value) }),
                            React.createElement(Button, { disabled: speakerName.length === 0, className: classes.speakerNameButton, onClick: assignSpeakerName }, t('transcripts.suggestSpeakerButtonText')))))));
    }
    if (isSoloDiaV2) {
        return (React.createElement(Dialog, { PaperProps: {
                style: {
                    borderRadius: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 342,
                    boxShadow: '0px 4px 8px 0px rgba(112, 144, 176, 0.20)',
                },
            }, onClose: onClose, open: true, maxWidth: "xs", fullWidth: true },
            React.createElement("div", { style: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                } },
                React.createElement(DialogTitle, { sx: { fontSize: 18, fontWeight: 400, color: (theme) => theme.palette.ava.grey } }, t('solodia.suggestedSpeakers')),
                React.createElement(IconButton, { style: { width: 24, height: 24, padding: 0, marginRight: 10 }, onClick: onClose },
                    React.createElement(HighlightOffIcon, null))),
            React.createElement(DialogContent, { style: { padding: '0px 10px 20px 10px', margin: 0, width: '100%' } },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: 10 } }, solodiaSpeakersToShow.map((speaker) => {
                    var _a;
                    return (React.createElement(Button, { style: {
                            display: 'flex',
                            padding: 0,
                            gap: 14,
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                        }, key: speaker.userName, onClick: () => {
                            reassignToExistingSpeaker(speaker);
                        } },
                        React.createElement("div", null,
                            (speaker === null || speaker === void 0 ? void 0 : speaker.userAvatar) && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                                    width: 32,
                                    height: 32,
                                } })),
                            speaker && !speaker.userAvatar && React.createElement(AvatarLogo, { color: (_a = speaker.theme) === null || _a === void 0 ? void 0 : _a.color, width: 32, height: 32 })),
                        React.createElement(Typography, { style: { color: 'black', fontSize: 18 } }, speaker.myself ? t('transcripts.you') : speaker.userName)));
                })),
                React.createElement("div", { style: {
                        display: 'flex',
                        alignItems: 'flex-end',
                    } },
                    React.createElement("div", { style: { marginRight: '10px' } },
                        (speaker === null || speaker === void 0 ? void 0 : speaker.userAvatar) && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                                width: 32,
                                height: 32,
                            } })),
                        speaker && !speaker.userAvatar && React.createElement(AvatarLogo, { color: speaker.theme.color, width: 32, height: 32 })),
                    React.createElement("div", { className: classes.speakerNameWrapper, style: { marginTop: 8 } },
                        React.createElement(Input, { placeholder: t('transcripts.suggestSpeakerPlaceholder'), fullWidth: true, autoFocus: true, className: classes.speakerNameInput, value: speakerName, onChange: (e) => setSpeakerName(e.currentTarget.value) }),
                        React.createElement(Button, { disabled: speakerName.length === 0, className: classes.speakerNameButton, onClick: assignSpeakerName, color: "primary" }, t('transcripts.suggestSpeakerButtonText')))))));
    }
    return (React.createElement(Dialog, { onClose: onClose, open: true, maxWidth: "xs", fullWidth: true },
        React.createElement(DialogTitle, null, t('transcripts.suggestedSpeakers')),
        React.createElement(DialogContent, null,
            React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                React.createElement("div", { style: { marginRight: '10px' } },
                    (speaker === null || speaker === void 0 ? void 0 : speaker.userAvatar) && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                            width: 32,
                            height: 32,
                        } })),
                    speaker && !speaker.userAvatar && React.createElement(AvatarLogo, { color: speaker.theme.color, width: 32, height: 32 })),
                React.createElement("div", { className: classes.speakerNameWrapper },
                    React.createElement(Input, { placeholder: t('transcripts.suggestSpeakerPlaceholder'), fullWidth: true, autoFocus: true, className: classes.speakerNameInput, value: speakerName, onChange: (e) => setSpeakerName(e.currentTarget.value) }),
                    React.createElement(Button, { disabled: speakerName.length === 0, className: classes.speakerNameButton, onClick: assignSpeakerName, color: "primary" }, t('transcripts.suggestSpeakerButtonText')))))));
};
export default SoloDiaSpeakerNameModal;
