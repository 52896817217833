import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import addOrg from '../../assets/icons/add_org.svg';
import eduOrg from '../../assets/icons/edu.svg';
import eventOrg from '../../assets/icons/event.svg';
import hrOrg from '../../assets/icons/hr.svg';
import { Z_INDICES } from '../styles';
import useOutsideClick from '../utils/clickOutside';
const useStyles = makeStyles((theme) => ({
    searchResult: {
        padding: 15,
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.grey[300] },
        display: 'flex',
    },
    resultName: {
        fontWeight: 600,
        fontSize: 16,
        color: 'black',
    },
    resultAddress: {
        fontSize: 16,
        color: '#778290',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    searchResultIcon: {
        width: 28,
        marginRight: 10,
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: 28,
    },
    searchResultContent: {
        flexShrink: 0,
        flexGrow: 1,
        maxWidth: 'calc(100% - 38px)',
    },
}));
const SearchResult = (result) => {
    const { t } = useTranslation();
    const { name, id, address, type, onClick } = result;
    const classes = useStyles();
    let img;
    switch (type) {
        case 'edu':
            img = eduOrg;
            break;
        case 'event':
            img = eventOrg;
            break;
        case 'new':
            img = addOrg;
            break;
        default:
            img = hrOrg;
            break;
    }
    return (React.createElement("div", { role: "button", className: classes.searchResult, tabIndex: 0, onClick: () => {
            onClick({
                name,
                id,
            });
        } },
        React.createElement("img", { alt: "search result", src: img, className: classes.searchResultIcon }),
        React.createElement("div", { className: classes.searchResultContent },
            React.createElement(Typography, { variant: "h6", className: classes.resultName },
                type === 'new' ? `${t('profiling.orgAutocomplete.addNew')} ` : '',
                name),
            address && React.createElement(Typography, { className: classes.resultAddress }, address))));
};
const SearchResultsList = ({ searchResults, classes, query, onSelectResult, onClickOutside, orgType }) => {
    const ref = useRef();
    useOutsideClick(ref, onClickOutside);
    if (!query)
        return null;
    const hasPerfectMatch = searchResults && searchResults.some((result) => result.name.toLowerCase() === query.toLowerCase());
    return (React.createElement("div", { style: {
            position: 'absolute',
            width: '100%',
            backgroundColor: 'white',
            border: '0.67069px solid #757575',
            zIndex: Z_INDICES.organizationSearchResults2,
        }, ref: ref },
        searchResults &&
            searchResults.map((result) => (React.createElement(SearchResult, { name: result.name, address: result.address || '', id: result.id || '', key: result.id, classes: classes, type: orgType, onClick: onSelectResult }))),
        !hasPerfectMatch && React.createElement(SearchResult, { name: query, classes: classes, type: "new", onClick: onSelectResult })));
};
export default SearchResultsList;
