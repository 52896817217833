import { Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConvoV2PrimaryButton } from './ConvoV2PrimaryButton';
import { ConvoV2SecondaryButton } from './ConvoV2SecondaryButton';
const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiPaper-root': {
            maxWidth: 314,
            borderRadius: 20,
            padding: '16px 24px',
        },
    },
    root: {},
    title: {
        marginBottom: 10,
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center',
    },
    description: {
        marginBottom: 24,
        fontSize: 18,
        fontWeight: 400,
        textAlign: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        gap: 16,
    },
    action: {
        width: 120,
    },
}));
export const ConvoV2SaveTranscriptDialog2 = React.memo(({ open, disabled, onSaveClicked, onContinueClicked }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Dialog, { className: classes.dialog, open: open },
        React.createElement(Box, { className: classes.root },
            React.createElement(Box, { className: classes.title }, t('saveTranscript.dialog2.heading')),
            React.createElement(Box, { className: classes.description }, t('saveTranscript.dialog2.text')),
            React.createElement(Box, { className: classes.actions },
                React.createElement(ConvoV2SecondaryButton, { className: classes.action, disabled: disabled, onClick: onContinueClicked }, t('convoV2.saveTranscript.dontSave')),
                React.createElement(ConvoV2PrimaryButton, { className: classes.action, disabled: disabled, onClick: onSaveClicked }, t('saveTranscript.dialog2.saveButton'))))));
});
