import VolumeUp from '@mui/icons-material/VolumeUp';
import { Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectUserRoleInConversation, UserRoleInConversation } from '../../selectors/combined';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectSpeakerListened } from '../../selectors/scribe';
import { updateSpeakerListened } from '../../store/slices/scribe';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { isDefaultRoomId } from '../../utils/ws-v1';
const audioLinkData = {
    id: 'audioLink',
    avaId: 'audioLink',
    device: 'audioLink',
    forceSR: false,
    audioLink: true,
    muted: false,
    sampleRateHertz: 16000,
};
const useStyles = makeStyles(() => ({
    backendBadge: {
        marginLeft: '30px',
        marginRight: '30px',
        paddingLeft: '6px',
        borderRadius: '7px',
        paddingRight: '6px',
        paddingTop: '1px',
        paddingBottom: '2px',
        borderColor: 'coral',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: 'coral',
    },
}));
const getShortDisplay = (participants, audioStream, speakers) => {
    if (!audioStream) {
        return 'no audio';
    }
    const participant = participants[audioStream.avaId] || {};
    let an;
    if (audioStream.id === 'audioLink') {
        return 'smartAudio';
    }
    if (participant.avaName) {
        an = `&${participant.avaName}`;
    }
    else {
        an = 'guest';
    }
    if (speakers.filter((x) => x.avaId === audioStream.avaId).length === 1) {
        an += ` ${speakers.filter((x) => x.avaId === audioStream.avaId)[0].userName}`;
    }
    let device;
    if (audioStream.channel === 'web') {
        device = 'web';
    }
    else {
        ({ device } = audioStream);
    }
    device += audioStream.trackName ? ` ${audioStream.trackName}` : '';
    return `${device} - (${an})`;
};
const SpeakerAudioSwitch = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [forceSR, setForceSR] = useState(false);
    const dispatch = useAppDispatch();
    const userRoleIsScribe = useAppSelector(selectUserRoleInConversation) === UserRoleInConversation.SCRIBE;
    const speakerListened = useAppSelector(selectSpeakerListened);
    const status = useAppSelector(selectStatus);
    const [socket] = useV1Socket();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const updateSpeaker = (speaker) => {
        setAnchorEl(null);
        dispatch(updateSpeakerListened(Object.assign(Object.assign({}, speaker), { forceSR })));
    };
    const audioStreamListened = () => {
        var _a;
        if (!status || !status.id || !status.audioStreams) {
            return undefined;
        }
        if (!speakerListened || speakerListened === 'host') {
            return status.audioStreams.find((p) => p.avaId === status.id.split('_')[0]);
        }
        if (speakerListened === 'audioLink') {
            return audioLinkData;
        }
        if ((_a = status.audioStreams[0]) === null || _a === void 0 ? void 0 : _a.trackId) {
            return status.audioStreams.find((p) => p.trackId === speakerListened);
        }
        return status.audioStreams.find((p) => p.avaId === speakerListened);
    };
    const handleForceSRChange = () => {
        const speakerListened = audioStreamListened();
        if (!speakerListened)
            return;
        setForceSR(!forceSR);
    };
    useEffect(() => {
        updateSpeaker(audioStreamListened());
    }, [forceSR]);
    const audioStreamsListened = audioStreamListened();
    useEffect(() => {
        const newSpeakers = status.audioStreams;
        if ((newSpeakers === null || newSpeakers === void 0 ? void 0 : newSpeakers.length) && !audioStreamsListened) {
            updateSpeaker(newSpeakers[0]);
        }
    }, [status.audioStreams, audioStreamsListened]);
    if (!status.participants) {
        return null;
    }
    if (!status.scribe) {
        return null;
    }
    if (isDefaultRoomId(status.id)) {
        return null;
    }
    if (!userRoleIsScribe) {
        return null;
    }
    const speakers = {};
    status.participants.forEach((p) => {
        speakers[p.avaId] = p;
    });
    const streamsAndAudioLink = status.audioStreams.concat([audioLinkData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: handleClick, className: "a-button", type: "submit", variant: "outlined", style: { width: 200, maxWidth: 200, marginLeft: 20 } },
            React.createElement(VolumeUp, { style: {
                    width: 20,
                    marginRight: 10,
                } }),
            React.createElement("span", { style: {
                    textTransform: 'none',
                    color: theme.palette.contrast,
                } }, getShortDisplay(speakers, audioStreamsListened, status.speakers))),
        React.createElement(Menu, { id: "simple-menu", anchorEl: anchorEl, open: Boolean(anchorEl) }, streamsAndAudioLink.map((p) => (React.createElement(MenuItem, { key: p.trackId || p.id, onClick: () => updateSpeaker(p), selected: !!audioStreamsListened &&
                (audioStreamsListened.trackId
                    ? p.trackId === audioStreamsListened.trackId
                    : p.avaId === audioStreamsListened.avaId), disabled: p.muted }, getShortDisplay(speakers, p, status.speakers))))),
        React.createElement(FormControlLabel, { control: React.createElement(Switch, { size: "small", checked: forceSR, color: "default", onChange: () => handleForceSRChange() }), label: "Force captions" }),
        status.scribe && status.scribe.status === 'leaving' && (React.createElement("div", { style: {} },
            React.createElement("div", { className: classes.backendBadge }, "Scribe end requested")))));
};
export default SpeakerAudioSwitch;
