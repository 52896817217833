import React, { useEffect, useState } from 'react';
const VARIANT_COUNT = 4;
export const ConvoV2ParticipantSmiley = React.memo(({ className, variant, size }) => {
    const [image, setImage] = useState(undefined);
    useEffect(() => {
        const smileyNumber = (variant % VARIANT_COUNT) + 1;
        import(`../../../assets/images/convov2-smiley-0${smileyNumber}.svg`).then((result) => {
            setImage(result.default);
        });
    }, [variant]);
    return image ? React.createElement("img", { className: className, src: image, width: size, height: size }) : null;
});
