import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectIsRateConversationOpen } from '../../selectors/rate-conversation';
import { useAppSelector } from '../../store/store';
import { ConvoV2HomeHeader } from './ConvoV2HomeHeader';
import { ConvoV2LeftSidebar } from './ConvoV2LeftSidebar';
import { ConvoV2PastSessionsPlaceholder } from './ConvoV2PastSessionsPlaceholder';
import { ConvoV2RatingFeedbackPopup } from './ConvoV2RatingFeedbackPopup';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    sidebar: {
        height: 'calc(var(--vh, 1vh) * 100)',
    },
    right: {
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(var(--vh, 1vh) * 100)',
        overflow: 'hidden',
        backgroundColor: theme.palette.ava.transparent.blue[theme.palette.mode],
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 40,
    },
}));
export const ConvoV2HomePage = React.memo(() => {
    const classes = useStyles();
    const isInConversation = useAppSelector(selectIsInConversation);
    const isRateConversationOpen = useAppSelector(selectIsRateConversationOpen);
    return (React.createElement(Box, { className: classes.root },
        React.createElement(ConvoV2LeftSidebar, { className: classes.sidebar }),
        React.createElement(Box, { className: classes.right },
            React.createElement(ConvoV2HomeHeader, null),
            React.createElement(Box, { className: classes.content },
                React.createElement(ConvoV2PastSessionsPlaceholder, null))),
        !isInConversation && isRateConversationOpen && React.createElement(ConvoV2RatingFeedbackPopup, null)));
});
