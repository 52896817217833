import VoiceIcon from '@mui/icons-material/Campaign';
import BackIcon from '@mui/icons-material/ChevronLeft';
import SendIcon from '@mui/icons-material/Send';
import { Input, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeyboardInput } from '../../hooks/useKeyboardInput';
import { setIsUsingTts } from '../../store/slices/textToSpeech';
import { updateKeyboardInputShown } from '../../store/slices/uiState';
import { useAppDispatch } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 32,
        padding: '8px 16px',
        borderRadius: 40,
        border: `1px solid ${theme.palette.ava.grey7}`,
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 2,
        gap: 16,
    },
    textInput: {
        flexGrow: 1,
        border: 0,
        fontSize: 20,
        fontWeight: 400,
        outline: 0,
        resize: 'none',
        '&::placeholder': {
            color: theme.palette.ava.text1,
        },
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        cursor: 'pointer',
    },
    iconContainerActive: {
        borderRadius: 8,
        background: theme.palette.ava.navy5,
    },
    voiceIcon: {
        width: 20,
        height: 20,
        color: theme.palette.ava.deepBlue,
    },
    sendIcon: {
        width: 19,
        height: 15,
        color: theme.palette.ava.deepBlue,
    },
}));
export const ConvoV2KeyboardInput = React.memo(({ className, shown }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { message, isUsingTts, onInputChange, onInputKeyDown, onSendClick } = useKeyboardInput();
    const onHide = useCallback(() => {
        dispatch(updateKeyboardInputShown(false));
    }, [updateKeyboardInputShown]);
    // Temporary until TTS v2 is complete
    const onToggleTtsClick = useCallback(() => {
        dispatch(setIsUsingTts(!isUsingTts));
    }, [isUsingTts]);
    // const onSendMessage = useCallback(() => {
    //   // @ts-ignore
    //   dispatch(scribeCreateTranscript(undefined, value, avaId));
    //   dispatch(updateKeyboardInputShown(false));
    //   setValue('');
    // }, [scribeCreateTranscript, updateKeyboardInputShown, value, setValue]);
    if (!shown) {
        return null;
    }
    return (React.createElement(Box, { className: classNames(classes.root, className) },
        React.createElement(Box, { className: classes.left },
            React.createElement(Box, { className: classes.iconContainer },
                React.createElement(BackIcon, { onClick: onHide })),
            React.createElement(Input, { className: classes.textInput, placeholder: t('convoV2.controls.typeYourMessage'), maxRows: 3, multiline: true, value: message, autoFocus: true, onChange: onInputChange, onKeyDown: onInputKeyDown })),
        React.createElement(Box, { className: classes.right },
            React.createElement(Box, { className: classNames(classes.iconContainer, { [classes.iconContainerActive]: isUsingTts }) },
                React.createElement(Tooltip, { title: t(isUsingTts ? 'ccMode.keyboard.tooltip.tts' : 'ccMode.keyboard.tooltip.switch') },
                    React.createElement(VoiceIcon, { className: classes.voiceIcon, onClick: onToggleTtsClick }))),
            React.createElement(Box, { className: classes.iconContainer },
                React.createElement(SendIcon, { className: classes.sendIcon, onClick: onSendClick })))));
});
