import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontSizeInput } from '../../components/Inputs/FontSizeInput';
import { selectFontSize } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
const useStyles = makeStyles({
    root: {
        width: '150px',
    },
    avaSlider: {
        '& .MuiSlider-thumb': {
            backgroundColor: '#50aced',
            height: 12,
            width: 12,
            '&:hover': {
                boxShadow: '0px 0px 0px 8px rgba(80, 172, 237, 15%)',
                '&.MuiSlider-active': {
                    boxShadow: '0px 0px 0px 14px rgba(80, 172, 237, 15%)',
                },
            },
        },
        '& .MuiSlider-rail': {
            backgroundColor: 'rgba(80, 172, 237, 65%)',
        },
        '& .MuiSlider-track': {
            backgroundColor: '#50aced',
            border: 'none',
        },
    },
});
const ScribeFontSizeInput = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const fontSize = useAppSelector(selectFontSize);
    const timeout = useRef();
    const [preview, setPreview] = useState(false);
    const showPreview = useCallback(() => {
        clearTimeout(timeout.current);
        if (window.navigator.share) {
            timeout.current = setTimeout(() => setPreview(false), 1000);
            setPreview(true);
        }
    }, [timeout, setPreview]);
    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { style: {
                zIndex: Z_INDICES.fontSizeInputItem,
                paddingLeft: 32,
            } },
            React.createElement(ListItemIcon, null,
                React.createElement(TextFieldsOutlinedIcon, null)),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', flexGrow: 1 } },
                React.createElement(ListItemText, null, t('editorSettings.fontSize')),
                React.createElement(FontSizeInput, { className: classes.avaSlider, onSizeChanged: showPreview }))),
        React.createElement("div", { style: {
                fontSize,
                position: 'absolute',
                backgroundColor: theme.palette.primary[theme.palette.mode],
                zIndex: preview ? Z_INDICES.fontSizeInputExampleActive : Z_INDICES.fontSizeInputExampleInactive,
                padding: 10,
                width: '100%',
                left: 0,
                color: theme.palette.contrast[theme.palette.mode],
                WebkitTransition: 'opacity 0.5s ease-in',
                MozTransition: 'opacity 0.5s ease-in',
                MsTransition: 'opacity 0.5s ease-in',
                OTransition: 'opacity 0.5s ease-in',
                transition: 'opacity 0.5s ease-in',
                textAlign: 'center',
                opacity: preview ? 0.9 : 0,
            } },
            React.createElement("span", null, t('theme.example')))));
};
export default ScribeFontSizeInput;
