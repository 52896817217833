import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Z_INDICES } from '../../styles';
const useStyles = makeStyles({
    paper: {
        position: 'absolute',
        zIndex: Z_INDICES.joinConversationMenu,
        left: 0,
        right: 0,
        top: (props) => (props.header ? -40 : -170),
    },
});
const JoinConversationMenu = ({ children, innerProps, options, selectProps }) => {
    const optionsToPass = options.length || 0;
    const { header } = selectProps;
    const classes = useStyles({ optionsToPass, header });
    if (!options.length)
        return null;
    return (React.createElement(Paper, Object.assign({ square: true, className: classes.paper, style: { marginTop: selectProps.header ? 90 : 220 } }, innerProps), children));
};
export default JoinConversationMenu;
