import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectLang, selectSpeakersMap, selectTranscript } from '../../selectors/legacy-conversation';
import { selectFontSize } from '../../selectors/ui';
import { selectAvaId } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { getText } from '../../utils/scribeUtils';
import { combineParsedWords, parseWord } from '../../utils/transcripts';
import { ConvoV2ParticipantAvatar } from './ConvoV2ParticipantAvatar';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        gap: 24,
        flexDirection: 'row',
        justifyContent: 'stretch',
        width: '100%',
        maxWidth: 812,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px',
            gap: 8,
        },
    },
    rootAnimated: {
        transition: 'transform 0.3s ease-in-out',
    },
    firstInGroup: {
        marginTop: 8,
    },
    avatarContainer: {
        paddingTop: '1.7ex',
        flexGrow: 0,
        marginBottom: 0,
        minWidth: 48,
        fontSize: (props) => props.fontSize,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '2ex',
            minWidth: 32,
        },
    },
    hiddenAvatar: {
        visibility: 'hidden',
    },
    texts: {
        flexGrow: 1,
        gap: 4,
        transition: 'background-color 2s ease',
        padding: '0 24px',
        marginRight: 16,
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    boldWord: {
        fontWeight: 1000,
    },
    disabledWord: {
        opacity: 0.5,
        textDecoration: 'line-through',
    },
    firstTextsInGroup: {
        paddingTop: 24,
    },
    textsFreshAnimated: {
        backgroundColor: theme.palette.ava.orange6,
        transition: '',
    },
    userNameText: {
        fontSize: (props) => props.fontSize,
        fontWeight: '600',
        color: (props) => props.speakerTextColor,
    },
    transcriptText: {
        fontSize: (props) => props.fontSize,
        fontWeight: '400',
        paddingTop: 8,
        color: (props) => props.speakerTextColor,
    },
}));
export const ConvoV2TranscriptItem = React.memo(({ transcriptId }) => {
    var _a, _b;
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) && !window.isElectron;
    const transcript = useAppSelector((state) => selectTranscript(state, transcriptId));
    const isFirstInGroup = transcript.authorShow;
    const speakers = useAppSelector(selectSpeakersMap);
    const lang = useAppSelector(selectLang);
    const avaId = useAppSelector(selectAvaId);
    const fontSize = useAppSelector(selectFontSize);
    const speaker = speakers[transcript.author];
    const isSpeakerCurrentUser = transcript.author === avaId;
    const speakerTextColor = ((_b = (_a = speaker === null || speaker === void 0 ? void 0 : speaker.theme) === null || _a === void 0 ? void 0 : _a.light_theme) === null || _b === void 0 ? void 0 : _b.text_color) || 'white';
    const classes = useStyles({ speakerTextColor, fontSize });
    const transcriptText = getText(transcript, lang);
    if (!transcriptText)
        return null;
    // Combine words with the same style so we minimize rendered DOM nodes (most
    // cases there should be just one big text)
    const transcriptSpans = combineParsedWords(transcriptText.split(' ').map(parseWord));
    return (React.createElement(Box, { className: classNames({
            [classes.root]: true,
            [classes.firstInGroup]: isFirstInGroup,
        }) },
        React.createElement(Box, { className: classes.avatarContainer }, isFirstInGroup && speaker && (React.createElement(ConvoV2ParticipantAvatar, { speaker: speaker, size: !isMobile ? 48 : 32, borderAroundPhoto: false, borderAroundSmiley: false }))),
        React.createElement(Box, { className: classNames({
                [classes.texts]: true,
                [classes.firstTextsInGroup]: isFirstInGroup,
            }) },
            isFirstInGroup && (React.createElement(Typography, { className: classes.userNameText }, isSpeakerCurrentUser ? t('transcripts.you') : speaker === null || speaker === void 0 ? void 0 : speaker.userName)),
            React.createElement(Typography, { className: classes.transcriptText }, transcriptSpans.map((span, index) => {
                return (React.createElement(React.Fragment, { key: index },
                    React.createElement("span", { className: classNames({
                            [classes.boldWord]: span.bold,
                            [classes.disabledWord]: span.disabled,
                        }) }, span.text),
                    ' '));
            })))));
});
