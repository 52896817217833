import CloseIcon from '@mui/icons-material/Close';
import HeadsetRoundedIcon from '@mui/icons-material/HeadsetRounded';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OneClickScribeChatBubble from '../../../assets/icons/one_click_scribe_chat_bubble.svg';
import OneClickScribeTwoToneIcon from '../../../assets/icons/one_click_scribe_two_tone.svg';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectIsOneClickScribeModalOpen } from '../../selectors/ui';
import { setIsOneClickScribeModalOpen } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { show } from '../../utils/intercom';
import { sendTypeOnly } from '../../utils/ws-v1';
import OneClickButtonGlowingDot from '../convoV2/OneClickButtonGlowingDot';
import OneClickScribeScheduleLaterButton from './OneClickScribeScheduleLaterButton';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 440,
        padding: 32,
        borderRadius: 20,
        background: theme.palette.mode === 'light' ? 'white' : '#FAF9F6',
        boxShadow: '4px 2px 12px 0px rgba(112, 144, 176, 0.25)',
        [theme.breakpoints.down('sm')]: {
            width: 343,
            minWidth: 343,
        },
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
        padding: 8,
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
    icon: {
        width: 16,
        height: 16,
        color: 'rgba(0, 0, 0, 0.48)',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
    },
    contentHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
        padding: '0px 48px 16px 48px',
    },
    contentHeaderIcon: {
        width: 32,
        height: 32,
    },
    contentHeaderText: {
        color: theme.palette.ava.green2,
        fontWeight: 700,
        fontSize: 32,
        textAlign: 'center',
        width: '90%',
        lineHeight: '98%',
    },
    contentBodyText: {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '98%',
        textAlign: 'center',
        color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.ava.grey2,
    },
    contentBottom: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 32,
        marginTop: 12,
    },
    requestNowContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
    },
    button: {
        width: 343,
        height: 48,
        padding: '14px 24px',
        borderRadius: 100,
        [theme.breakpoints.down('sm')]: {
            width: 279,
        },
    },
    requestNowButton: {
        background: 'linear-gradient(320deg, #006651 27.53%, #009778 76.48%)',
        color: 'white',
        fontSize: 18,
        fontWeight: 600,
        boxShadow: '4px 2px 12px 0px rgba(112, 144, 176, 0.25)',
        lineHeight: '113%',
        '&:hover': {
            boxShadow: 'inset 0 5px 15px rgba(0, 0, 0, 0.3)',
        },
    },
    description: {
        fontSize: 14,
        color: '#000000A3',
        lineHeight: 'normal',
    },
    laterButton: {
        gap: 8,
        border: '0.901px solid #006651',
        color: '#006651',
        fontSize: 18,
        fontWeight: 400,
        lineHeight: 'normal',
    },
    headsetContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 48,
        height: 48,
        borderRadius: 30,
        padding: 12,
        gap: 10,
        color: 'white',
        backgroundColor: theme.palette.ava.green2,
    },
    hasScribeButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        borderRadius: 100,
        width: 343,
        padding: '12px 24px',
        [theme.breakpoints.down('sm')]: {
            width: 279,
        },
    },
    report: {
        padding: '12px 24px',
        border: '0.901px solid #006651',
    },
    reportText: {
        fontSize: 18,
        color: '#006651',
    },
    remove: {
        background: 'rgba(222, 66, 62, 0.12)',
        '&:hover': { background: 'rgba(222, 66, 62, 0.3)' },
    },
    removeText: {
        fontSize: 18,
        color: '#800000',
    },
    live: {
        display: 'flex',
        padding: '2px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        border: '1px solid rgba(0, 151, 120, 0.24)',
        borderRadius: 60,
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.ava.green2,
    },
    moreGapHeader: {
        gap: 8,
    },
    lessGapBottom: {
        gap: 16,
    },
    hr: {
        width: '100%',
        borderColor: '#00000014',
        height: 1,
    },
}));
function OneClickScribeModal({ hasScribe }) {
    const { t } = useTranslation();
    const isModalOpen = useAppSelector(selectIsOneClickScribeModalOpen);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [ws] = useV1Socket();
    const handleClose = useCallback(() => dispatch(setIsOneClickScribeModalOpen(false)), []);
    const handleRequestScribe = useCallback(() => {
        if (ws) {
            sendTypeOnly(ws, 'request-scribe');
            handleClose();
        }
    }, [ws]);
    const report = useCallback(() => {
        show();
        dispatch(setIsOneClickScribeModalOpen(false));
    }, []);
    const handleRemoveScribe = useCallback(() => {
        if (ws) {
            sendTypeOnly(ws, 'end-scribe');
            handleClose();
        }
    }, [ws]);
    const content = hasScribe ? (React.createElement(Box, { className: classes.content },
        React.createElement(Box, { className: classNames(classes.contentHeader, classes.moreGapHeader) },
            React.createElement("div", { className: classes.live },
                t('oneClickScribe.modal.ongoingScribe.live'),
                " ",
                React.createElement(OneClickButtonGlowingDot, null)),
            React.createElement("div", { className: classes.headsetContainer },
                React.createElement(HeadsetRoundedIcon, null))),
        React.createElement(Box, null,
            React.createElement(Typography, { className: classes.contentBodyText }, t('oneClickScribe.modal.ongoingScribe.body'))),
        React.createElement(Box, { className: classNames(classes.contentBottom, classes.lessGapBottom) },
            React.createElement(Button, { className: classNames(classes.hasScribeButton, classes.report), onClick: report },
                React.createElement("img", { src: OneClickScribeChatBubble, alt: "chat bubble" }),
                React.createElement(Typography, { className: classes.reportText }, t('oneClickScribe.modal.ongoingScribe.report'))),
            React.createElement("hr", { className: classes.hr }),
            React.createElement("div", { className: classes.requestNowContainer },
                React.createElement(Button, { className: classNames(classes.hasScribeButton, classes.remove), onClick: handleRemoveScribe },
                    React.createElement(Typography, { className: classes.removeText }, t('oneClickScribe.modal.ongoingScribe.remove'))),
                React.createElement(Typography, { className: classes.description }, t('oneClickScribe.modal.ongoingScribe.description')))))) : (React.createElement(Box, { className: classes.content },
        React.createElement(Box, { className: classes.contentHeader },
            React.createElement("img", { src: OneClickScribeTwoToneIcon, alt: "scribe icon" }),
            React.createElement(Typography, { className: classes.contentHeaderText }, t('oneClickScribe.modal.preScribe.title'))),
        React.createElement(Box, null,
            React.createElement(Typography, { className: classes.contentBodyText }, t('oneClickScribe.modal.preScribe.body'))),
        React.createElement(Box, { className: classes.contentBottom },
            React.createElement("div", { className: classes.requestNowContainer },
                React.createElement(Button, { onClick: handleRequestScribe, className: classNames(classes.button, classes.requestNowButton) }, t('oneClickScribe.modal.preScribe.requestNow')),
                React.createElement(Typography, { className: classes.description }, t('oneClickScribe.modal.preScribe.description'))),
            React.createElement(OneClickScribeScheduleLaterButton, { className: classNames([classes.button, classes.laterButton]) }))));
    return (React.createElement(Dialog, { open: isModalOpen, onClose: handleClose, PaperProps: { className: classes.container }, "aria-labelledby": "modal-one-click-scribe", "aria-describedby": "modal-request-scribe-quickly" },
        React.createElement(IconButton, { "aria-label": "close", onClick: handleClose, className: classes.closeButton },
            React.createElement(CloseIcon, { className: classes.icon })),
        content));
}
export default OneClickScribeModal;
