import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import AvaLogoImage from '../../../assets/icons/new-ava-logo.svg';
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '100%',
        display: 'flex',
        width: 'fit-content',
        background: 'white',
        position: 'relative',
        padding: 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: -1,
            borderRadius: '50%',
            border: 'solid 2px #50aced',
            top: 0,
            left: 0,
        },
        '&::before': {
            animation: '$grow 2s infinite ease-in-out',
        },
        '&::after': {
            animation: '$grow 2s 0.5s infinite ease-in-out',
        },
    },
    '@keyframes grow': {
        '100%': {
            transform: 'scale(1.5)',
            opacity: 0,
        },
    },
}));
export const ConvoV2RadiatingLogo = React.memo(({ className }) => {
    const classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.root, className) },
        React.createElement("img", { src: AvaLogoImage, width: 64, height: 64 })));
});
