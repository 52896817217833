import React from 'react';
import BoostPopup from '../../components/Popups/BoostPopup';
import SaveTranscriptPopup from '../../components/Popups/SaveTranscriptPopup';
import { selectCurrentConversationEndingStage } from '../../selectors/conversationEnd';
import { activateNextEndConversationStage, ConversationEndingStage } from '../../store/slices/conversationEnd';
import { useAppDispatch, useAppSelector } from '../../store/store';
const ConversationEndModals = (props) => {
    var _a;
    const dispatch = useAppDispatch();
    const currentConversationEndingStage = useAppSelector(selectCurrentConversationEndingStage);
    const showBoostModal = currentConversationEndingStage === ConversationEndingStage.BOOST;
    const showSaveTranscriptsModal = currentConversationEndingStage === ConversationEndingStage.SAVE_TRANSCRIPTS;
    const handleBoostPopup = () => {
        dispatch(activateNextEndConversationStage());
    };
    const handleSaveTranscriptPopup = () => {
        dispatch(activateNextEndConversationStage());
    };
    const SaveTranscriptPopupComponent = (_a = props.SaveTranscriptPopupComponent) !== null && _a !== void 0 ? _a : SaveTranscriptPopup;
    return (React.createElement(React.Fragment, null,
        showSaveTranscriptsModal && React.createElement(SaveTranscriptPopupComponent, { onFinish: handleSaveTranscriptPopup }),
        showBoostModal && React.createElement(BoostPopup, { open: showBoostModal, onClose: handleBoostPopup })));
};
export default ConversationEndModals;
