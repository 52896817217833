import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Fab, Fade, Popper, Typography, } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import autoTranslateLangs from '../../../assets/auto_translate_langs.json';
import AvaMic from '../../../assets/icons/mic-ava.png';
import AvaMicInternal from '../../../assets/icons/mic-ava-internal.png';
import Headset from '../../../assets/images/headphones.png';
import MicrophoneDenied from '../../components/Modals/MicrophoneDenied';
import usePrevious from '../../hooks/usePrevious';
import { selectAudioV2MicrophoneDenied, selectAudioV2Status, selectSelectedMicrophone } from '../../selectors/audioV2';
import { selectGuestLanguage } from '../../selectors/avaTranslate';
import { selectCanBeginRecording, selectCurrentCaptionsBackground, selectCurrentCaptionsBackgroundColor, } from '../../selectors/combined';
import { selectInTwilioCalls } from '../../selectors/conversation';
import { selectStatusAudioStreams, selectStatusConversationMode } from '../../selectors/legacy-conversation';
import { selectElectronCaptionMode, selectFullScreen } from '../../selectors/ui';
import { selectAvaId } from '../../selectors/userProfile';
import { RecordingStatus, startRecording, stopRecording } from '../../store/slices/audioV2';
import { setGuestLanguageSelectorOpen } from '../../store/slices/avaTranslate';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import { isMobile } from '../../utils';
import { canShowFlagEmoji } from '../../utils/platform';
const useStyles = makeStyles((theme) => ({
    headsetPopupContent: {
        fontSize: 18,
        textAlign: 'center',
    },
    headsetPopupCTA: {
        backgroundColor: theme.palette.ava.blue,
        color: 'white',
        display: 'flex',
        flexGrow: 1,
        margin: 20,
        marginBottom: 10,
        marginTop: 0,
    },
    headsetPopupImg: {
        display: 'block',
        margin: 'auto',
        marginBottom: 30,
        marginTop: 30,
    },
    headsetPopupPaper: {
        maxWidth: 400,
    },
    headsetPopupTitle: {
        fontSize: 22,
        fontWeight: 600,
        marginBottom: 20,
        textAlign: 'center',
    },
    audioIndicatorBtn: {
        marginBottom: '10px',
        WebkitAppRegion: 'no-drag',
    },
    audioIndicatorCCBtn: {
        position: 'fixed',
        marginBottom: 'unset',
        top: '50%',
        left: 60,
        transform: 'translate(-50%, -50%)',
        minHeight: 30,
        minWidth: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 120ms ease-in 0s',
    },
}));
const AudioTranscoderUI = React.memo(function AudioTranscoderUI(props) {
    const { className, showMinimalMic, hideTextBelow } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [headsetPopupShown, setHeadsetPopupShown] = useState(false);
    const [showLanguageDetected, setShowLanguageDetected] = useState(false);
    const avaId = useAppSelector(selectAvaId);
    const fullScreen = useAppSelector(selectFullScreen);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const inTwilioCalls = useAppSelector(selectInTwilioCalls);
    const guestLang = useAppSelector(selectGuestLanguage);
    const guestLangObj = autoTranslateLangs[guestLang || 'en-US'];
    const canBeginRecording = useAppSelector(selectCanBeginRecording);
    const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
    const recordingStatus = useAppSelector(selectAudioV2Status);
    const microphoneDenied = useAppSelector(selectAudioV2MicrophoneDenied);
    const audioStreams = useAppSelector(selectStatusAudioStreams);
    const conversationMode = useAppSelector(selectStatusConversationMode);
    const micBackground = useAppSelector(selectCurrentCaptionsBackground)(theme);
    const micBackgroundColor = useAppSelector(selectCurrentCaptionsBackgroundColor)(theme);
    const dispatch = useAppDispatch();
    const microphoneFab = useRef();
    const prevGuestLang = usePrevious(guestLang);
    useEffect(() => {
        if (!guestLang || prevGuestLang) {
            return;
        }
        setShowLanguageDetected(true);
        const timeout = setTimeout(() => {
            setShowLanguageDetected(false);
        }, 6000);
        return () => clearTimeout(timeout);
    }, [guestLang]);
    if (inTwilioCalls || (fullScreen && !electronCaptionMode)) {
        return React.createElement("div", null);
    }
    let micImg = AvaMic;
    if (selectedMicrophone) {
        micImg = selectedMicrophone.isInternal ? AvaMicInternal : AvaMic;
    }
    const micStyle = {
        background: micBackground,
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        transition: 'all 120ms ease-in 0s',
    };
    if (showMinimalMic) {
        micStyle.width = 20;
        micStyle.height = 20;
        micStyle.minHeight = 20;
    }
    // recall-ai counts as an audio stream only when disconnecting
    // can lead to popup when only two people are present
    const recallAIThirdWheel = audioStreams.length === 3 && audioStreams.find((stream) => stream.device === 'recall-ai');
    return (React.createElement("div", { className: classnames(classes.audioIndicatorBtn, className, {
            [classes.audioIndicatorCCBtn]: window.isElectron && electronCaptionMode,
        }), style: {
            width: showMinimalMic ? 30 : 56,
            height: showMinimalMic ? 30 : 56,
        } },
        React.createElement(Fab, { color: "secondary", className: "audio-indicator", ref: microphoneFab, style: micStyle, disabled: (recordingStatus === RecordingStatus.NOT_RECORDING && !canBeginRecording) ||
                recordingStatus === RecordingStatus.PENDING, "data-qa": "microphoneButton", onClick: () => recordingStatus === RecordingStatus.RECORDING ? dispatch(stopRecording()) : dispatch(startRecording()) },
            micImg && recordingStatus !== RecordingStatus.PENDING && (React.createElement("img", { src: micImg, alt: "ava_mic", style: {
                    transition: 'all 200ms ease-in',
                    height: showMinimalMic ? 0 : 30,
                    opacity: showMinimalMic ? 0 : 1,
                } })),
            recordingStatus === RecordingStatus.PENDING && (React.createElement(CircularProgress, { sx: {
                    width: 48,
                    height: 48,
                    color: 'white',
                } }))),
        !electronCaptionMode && !hideTextBelow && (React.createElement("div", { style: {
                background: micBackground,
                color: 'white',
                borderRadius: 10,
                textAlign: 'center',
                margin: 'auto',
                paddingLeft: 5,
                paddingRight: 5,
                width: 'fit-content',
                marginTop: -1,
            } }, recordingStatus === RecordingStatus.RECORDING ? 'ON' : 'OFF')),
        microphoneDenied && React.createElement(MicrophoneDenied, { open: microphoneDenied }),
        React.createElement(Dialog, { open: !headsetPopupShown &&
                !window.isElectron &&
                !electronCaptionMode &&
                recordingStatus === RecordingStatus.RECORDING &&
                !isMobile() &&
                !!selectedMicrophone &&
                !selectedMicrophone.isBluetooth &&
                (audioStreams || []).filter((as) => as.avaId !== avaId).length > 1 &&
                conversationMode === 'public' &&
                !recallAIThirdWheel, classes: { paper: classes.headsetPopupPaper } },
            React.createElement(DialogContent, null,
                React.createElement("img", { src: Headset, alt: "headphones", className: classes.headsetPopupImg }),
                React.createElement(Typography, { className: classes.headsetPopupTitle }, t('conversation.headsetPopUp.title')),
                React.createElement(Typography, { className: classes.headsetPopupContent }, t('conversation.headsetPopUp.content1')),
                React.createElement(Typography, { className: classes.headsetPopupContent }, t('conversation.headsetPopUp.content2'))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => setHeadsetPopupShown(true), className: classes.headsetPopupCTA, autoFocus: true }, t('conversation.headsetPopUp.cta')))),
        React.createElement(Popper, { placement: "left", anchorEl: microphoneFab.current, disablePortal: false, open: showLanguageDetected, transition: true, sx: {
                zIndex: Z_INDICES.audioRecorderPopper,
            }, modifiers: [
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        altBoundary: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        altBoundary: true,
                        tether: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'arrow',
                    enabled: false,
                },
            ] }, ({ TransitionProps }) => (React.createElement(Fade, Object.assign({}, TransitionProps, { timeout: 350 }),
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                } },
                React.createElement(Button, { sx: {
                        height: '52px',
                        borderRadius: '32px',
                        paddingX: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                        boxShadow: '4px 2px 12px 0px rgba(112, 144, 176, 0.25)',
                        background: micBackground,
                        color: 'white',
                        fontStyle: 'normal',
                    }, onClick: () => {
                        dispatch(setGuestLanguageSelectorOpen(true));
                    } },
                    t('autoTranslate.langDetected').replace('$lang', guestLangObj === null || guestLangObj === void 0 ? void 0 : guestLangObj.englishName),
                    React.createElement(ReactCountryFlag, { style: {
                            height: !canShowFlagEmoji() ? 24 : undefined,
                            width: !canShowFlagEmoji() ? 24 : undefined,
                            fontSize: 24,
                            marginLeft: 10,
                        }, svg: !canShowFlagEmoji(), countryCode: guestLangObj.flagCode })),
                React.createElement(Box, { sx: {
                        height: 0,
                        width: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderLeft: `14px solid ${micBackgroundColor}`,
                        marginRight: 1,
                        marginLeft: '-2px',
                    } })))))));
});
export default AudioTranscoderUI;
