import { CircularProgress, Dialog, useMediaQuery, useTheme } from '@mui/material';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CcIcon from '../../../assets/icons/convov2-cc.svg';
import SettingsIcon from '../../../assets/icons/convov2-settings.svg';
import { fetchRemainingCredits } from '../../actions/session';
import { selectKeyboardInputShown } from '../../selectors/ui';
import { getScribeRemainingTime, selectAvaId } from '../../selectors/userProfile';
import { initiateConversationEndSequence, setConversationEndInitiatedByCurrentUser, } from '../../store/slices/conversationEnd';
import { updateKeyboardInputShown } from '../../store/slices/uiState';
import { HAVENT_FETCHED_SCRIBE_CREDITS } from '../../store/slices/userProfile';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getOS } from '../../utils';
import { CONVOV2_APP_LAUNCH_ROUTE } from './constants';
import { ConvoV2AudioControls } from './ConvoV2AudioControls';
import { ConvoV2CaptionQualityButton } from './ConvoV2CaptionQualityButton';
import { ConvoV2ControlBarButton } from './ConvoV2ControlBarButton';
import { ConvoV2DialogHeader } from './ConvoV2DialogHeader';
import { ConvoV2DialogTitle } from './ConvoV2DialogTitle';
import { ConvoV2KeyboardIcon } from './ConvoV2KeyboardIcon';
import { ConvoV2KeyboardInput } from './ConvoV2KeyboardInput';
import ConvoV2OneClickScribeButton from './ConvoV2OneClickScribeButton';
import { ConvoV2SettingsCard } from './ConvoV2SettingsCard';
import { ConvoV2StopIcon } from './ConvoV2StopIcon';
const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        width: '100%',
        padding: '20px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 56,
        boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.1)',
        boxSizing: 'border-box',
    },
    contentHorizontallyExpanded: {
        width: '100%',
        maxWidth: 832,
    },
    contentVerticallyExpanded: {
        borderRadius: 36,
        padding: 16,
        paddingBottom: 0,
    },
    buttons: {
        display: 'flex',
        padding: 12,
        alignItems: 'center',
        minWidth: '200px',
        gap: 12,
    },
    buttonsMobile: {
        padding: 8,
        gap: 4,
    },
    activeKeyboardIcon: {
        backgroundColor: theme.palette.ava.grey8,
        '&:hover': {
            backgroundColor: theme.palette.ava.grey8,
        },
    },
    inlineKeyboardInput: {
        flexGrow: 1,
    },
    stopButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.ava.red3,
        '&:hover': {
            background: theme.palette.ava.red3,
        },
    },
}));
export const ConvoV2ControlBar = React.memo(({ className }) => {
    const settingsButtonRef = useRef(null);
    const [showSettings, setShowSettings] = useState(false);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const scribeRemainingTime = useAppSelector(getScribeRemainingTime);
    const avaId = useAppSelector(selectAvaId);
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) && !window.isElectron;
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md')) && !window.isElectron;
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const onToggleKeyboard = useCallback(() => {
        dispatch(updateKeyboardInputShown(!keyboardInputShown));
    }, [keyboardInputShown, dispatch, updateKeyboardInputShown]);
    const onShowSettings = useCallback(() => setShowSettings(true), [setShowSettings]);
    const onHideSettings = useCallback(() => setShowSettings(false), [setShowSettings]);
    const onCcClick = useCallback(() => {
        window.open(CONVOV2_APP_LAUNCH_ROUTE, '_blank');
    }, []);
    const onEndConversationClick = useCallback(() => {
        dispatch(setConversationEndInitiatedByCurrentUser(true));
        dispatch(initiateConversationEndSequence());
    }, []);
    const contentVerticallyExpanded = isMobileOrTablet && keyboardInputShown;
    const contentHorizontallyExpanded = !isMobileOrTablet && keyboardInputShown;
    useEffect(() => {
        if (avaId && scribeRemainingTime === HAVENT_FETCHED_SCRIBE_CREDITS) {
            fetchRemainingCredits(avaId)(dispatch);
        }
    }, [scribeRemainingTime, avaId]);
    const ccButtonTitle = useMemo(() => t('sidebar.openAvaDesktop', { OS: getOS() }), []);
    return (React.createElement(Box, { className: classNames(classes.root, className) },
        React.createElement(Box, { className: classNames(classes.content, {
                [classes.contentHorizontallyExpanded]: contentHorizontallyExpanded,
                [classes.contentVerticallyExpanded]: contentVerticallyExpanded,
            }) },
            isMobileOrTablet && React.createElement(ConvoV2KeyboardInput, { shown: keyboardInputShown }),
            React.createElement(Box, { className: classNames(classes.buttons, { [classes.buttonsMobile]: isMobileOrTablet }) },
                React.createElement(ConvoV2AudioControls, null),
                (!keyboardInputShown || isMobileOrTablet) && (React.createElement(ConvoV2ControlBarButton, { className: classNames({ [classes.activeKeyboardIcon]: keyboardInputShown }), title: t('ccMode.tooltip.keyboard'), onClick: onToggleKeyboard },
                    React.createElement(ConvoV2KeyboardIcon, { color: keyboardInputShown ? theme.palette.ava.white : theme.palette.ava.grey8 }))),
                !isMobileOrTablet && (React.createElement(ConvoV2KeyboardInput, { className: classes.inlineKeyboardInput, shown: keyboardInputShown })),
                scribeRemainingTime === HAVENT_FETCHED_SCRIBE_CREDITS ? (React.createElement(ConvoV2ControlBarButton, null,
                    React.createElement(CircularProgress, { size: '1.5rem' }))) : scribeRemainingTime > 0 ? (React.createElement(ConvoV2OneClickScribeButton, null)) : (React.createElement(ConvoV2CaptionQualityButton, null)),
                React.createElement(ConvoV2ControlBarButton, { title: ccButtonTitle, onClick: onCcClick },
                    React.createElement("img", { src: CcIcon })),
                React.createElement(Box, { ref: settingsButtonRef },
                    React.createElement(ConvoV2ControlBarButton, { title: t('convoV2.settings.title'), onClick: onShowSettings },
                        React.createElement("img", { src: SettingsIcon }))),
                isMobile && showSettings && (React.createElement(Dialog, { open: showSettings, PaperProps: {
                        style: {
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '24px 20px',
                            gap: 16,
                            borderRadius: 16,
                            width: '95%',
                            maxWidth: 350,
                        },
                    } },
                    React.createElement(ConvoV2DialogHeader, { onClose: onHideSettings },
                        React.createElement(ConvoV2DialogTitle, null, t('convoV2.settings.title'))),
                    React.createElement(ConvoV2SettingsCard, { onClose: onHideSettings }))),
                !isMobile && settingsButtonRef.current && (React.createElement(Popover, { open: showSettings, anchorEl: settingsButtonRef.current, anchorOrigin: { vertical: 'top', horizontal: 'center' }, transformOrigin: { vertical: 'bottom', horizontal: 'center' }, PaperProps: {
                        style: {
                            marginTop: -25,
                            minWidth: 340,
                            padding: '28px 20px',
                            borderRadius: 20,
                            boxShadow: '2px 4px 12px 0px #7090B040',
                        },
                    } },
                    React.createElement(ConvoV2SettingsCard, { onClose: onHideSettings }))),
                React.createElement(ConvoV2ControlBarButton, { className: classes.stopButton, title: t('convoV2.endConvo.title'), onClick: onEndConversationClick },
                    React.createElement(ConvoV2StopIcon, null))))));
});
