import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 20,
        fontWeight: 600,
    },
}));
export const ConvoV2DialogTitle = React.memo(({ children }) => {
    const classes = useStyles();
    return React.createElement(Typography, { className: classes.title }, children);
});
