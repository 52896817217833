import { closeSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectedIcon from '../../../assets/icons/convov2-connected.svg';
import ErrorIcon from '../../../assets/icons/convov2-error.svg';
import { useNotifications } from '../../hooks/useNotifications';
import usePrevious from '../../hooks/usePrevious';
import { selectV1WebsocketStatus } from '../../selectors/v1Session';
import { useAppSelector } from '../../store/store';
export const ConvoV2ConnectionStatus = React.memo(() => {
    const currentWebsocketStatus = useAppSelector(selectV1WebsocketStatus);
    const previousWebsocketStatus = usePrevious(currentWebsocketStatus);
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const currentNotificationKey = useRef();
    const updateNotification = useCallback((text, options) => {
        if (currentNotificationKey.current) {
            closeSnackbar(currentNotificationKey.current);
        }
        currentNotificationKey.current = notify(text, Object.assign({ variant: 'convoV2' }, options));
    }, [currentNotificationKey.current, notify]);
    useEffect(() => {
        if (previousWebsocketStatus === 'online' && currentWebsocketStatus !== 'online') {
            updateNotification(t('convoV2.network.noConnection'), {
                persist: true,
                convoV2Icon: React.createElement("img", { src: ErrorIcon }),
            });
        }
        if (previousWebsocketStatus && previousWebsocketStatus !== 'online' && currentWebsocketStatus === 'online') {
            updateNotification(t('convoV2.network.connected'), {
                autoHideDuration: 1500,
                convoV2Icon: React.createElement("img", { src: ConnectedIcon }),
            });
        }
    }, [currentWebsocketStatus]);
    return null;
});
