// This file is separate from the rest of firebase, because it contains only auth.
// This means it can be imported in contexts which do not have Window (e.g. service workers).
import 'firebase/auth';
import firebase from 'firebase/app';
import { getBackendConfig } from '../utils/backends';
try {
    firebase.app();
}
catch (_a) {
    firebase.initializeApp(getBackendConfig('firebaseConfig'));
}
export const authInstance = firebase.auth();
