import { CircularProgress, Input, Typography } from '@mui/material';
import { Collapse } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAvaConnectUIHelpers } from '../../../hooks/useAvaConnectUIHelpers';
import { selectRecallAIService, selectRecallAIStatus } from '../../../selectors/recallAI';
import { selectElectronCaptionMode } from '../../../selectors/ui';
import { useAppSelector } from '../../../store/store';
import RoundedButton from '../../Buttons/RoundedButton';
import Checkmark from '../../Icons/Checkmark';
import GoogleMeetIcon from '../../Icons/GoogleMeetIcon';
import { MeetingServiceIcon } from '../../Icons/MeetingServiceIcon';
import MicrosoftTeamsIcon from '../../Icons/MicrosoftTeamsIcon';
import ZoomIcon from '../../Icons/ZoomIcon';
import useStyles from './ConnectToMeetingsCard.styles';
const CONNECTION_STATUS_CSS_CLASSES = {
    LOADING: 'connect',
    WAITING_FOR_HOST: 'connect',
    CONNECTING_TO_AUDIO: 'connect',
    CONNECTED: 'connected',
    CAPTIONING: 'captioning',
    DISCONNECT: 'disconnect',
    ERROR: 'recallError',
};
const ConnectToMeetingsCard = React.memo(({ tabletOrMobile }) => {
    var _a;
    const { t } = useTranslation();
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const service = useAppSelector(selectRecallAIService);
    const { meetingLink, meetingLinkInvalid, meetingLinkEditable, onMeetingLinkKeyDown, onMeetingLinkChange, connected: botConnected, connecting, action, actionLabel, connectionMessage, } = useAvaConnectUIHelpers({ isTabletOrMobile: tabletOrMobile });
    const meetingLinkShown = recallAIStatus === 'NOT_CONNECTED' || recallAIStatus === 'DISCONNECT';
    const hasError = meetingLinkInvalid || recallAIStatus === 'ERROR';
    const classes = useStyles({
        electronCaptionMode: !!electronCaptionMode,
        electronWindow: window.isElectron,
        recallAIBotConnected: botConnected,
        hasError,
        connecting,
        inputShown: meetingLinkShown,
    });
    let buttonText;
    let buttonClass;
    if (meetingLinkInvalid) {
        buttonText = t('connectToOnlineMeetings.invalid');
        buttonClass = 'inputErrorMessage';
    }
    else if (recallAIStatus !== 'NOT_CONNECTED') {
        buttonText = (_a = actionLabel !== null && actionLabel !== void 0 ? actionLabel : connectionMessage) !== null && _a !== void 0 ? _a : '';
        buttonClass = CONNECTION_STATUS_CSS_CLASSES[recallAIStatus];
    }
    else {
        buttonText = t('connectToOnlineMeetings.connect');
        buttonClass = 'connect';
    }
    const buttonDisabled = !action && !connecting;
    return (React.createElement("section", { className: tabletOrMobile ? classes.modal : classes.panel },
        tabletOrMobile && (React.createElement(Typography, { variant: "h5", className: classes.text }, t('connectToOnlineMeetings.title'))),
        React.createElement(Collapse, { in: meetingLinkShown, orientation: "horizontal", collapsedSize: 0 },
            React.createElement(Input, { startAdornment: React.createElement(InputAdornment, { position: "start" },
                    React.createElement(ZoomIcon, null),
                    React.createElement(GoogleMeetIcon, null),
                    React.createElement(MicrosoftTeamsIcon, null)), className: classNames(classes.input, {
                    [classes.inputError]: meetingLinkInvalid,
                }), disabled: !meetingLinkEditable, fullWidth: true, type: "text", placeholder: t('connectToOnlineMeetings.paste'), value: meetingLink, autoFocus: true, disableUnderline: true, onKeyDown: onMeetingLinkKeyDown, onChange: onMeetingLinkChange, error: hasError })),
        React.createElement(RoundedButton, { className: classNames(classes.button, classes[buttonClass]), color: "blue", onClick: action, disabled: buttonDisabled },
            recallAIStatus === 'CAPTIONING' && (React.createElement("span", { className: classes.icon }, service !== '' && React.createElement(MeetingServiceIcon, { service: service }))),
            buttonText,
            recallAIStatus === 'CONNECTED' && React.createElement(Checkmark, null),
            connecting && (React.createElement("span", { className: classes.spinnerContainer },
                React.createElement(CircularProgress, { className: classes.spinner, size: '1.5rem' }))))));
});
export default ConnectToMeetingsCard;
