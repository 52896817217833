import React from 'react';
const SaveTranscript = ({ className, width, height }) => {
    return (React.createElement("svg", { width: width || '224', height: height || '219', style: { minWidth: 150, minHeight: 140, maxWidth: 305, maxHeight: 300 }, className: className, viewBox: "0 0 224 219", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { opacity: "0.7" },
            React.createElement("path", { opacity: "0.7", d: "M185.129 72.3118L111.809 54.6548V192.849L185.129 173.657V72.3118Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.1" },
                React.createElement("path", { opacity: "0.1", d: "M185.129 72.3118L111.809 54.6548V192.849L185.129 173.657V72.3118Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M38.4863 72.3118L111.809 54.6548V192.849L38.4863 174.039V72.3118Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.17" },
                React.createElement("path", { opacity: "0.17", d: "M38.4863 72.3118L111.809 54.6548V192.849L38.4863 174.039V72.3118Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M111.809 54.6546L149.812 23.1772L223.132 41.9879L185.129 72.3116L111.809 54.6546Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M0.86792 42.3695L38.4863 72.3116L111.809 54.6546L74.5724 23.1772L0.86792 42.3695Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.05" },
                React.createElement("path", { opacity: "0.05", d: "M0.86792 42.3695L38.4863 72.3116L111.809 54.6546L74.5724 23.1772L0.86792 42.3695Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("g", { opacity: "0.5" },
                React.createElement("path", { opacity: "0.5", d: "M110.486 38.6812H58.7899C58.0373 38.6812 57.4272 39.2912 57.4272 40.0438V87.5308C57.4272 88.2834 58.0373 88.8935 58.7899 88.8935H110.486C111.239 88.8935 111.849 88.2834 111.849 87.5308V40.0438C111.849 39.2912 111.239 38.6812 110.486 38.6812Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M109.895 39.0688H59.453C58.7004 39.0688 58.0903 39.6789 58.0903 40.4315V86.7012C58.0903 87.4538 58.7004 88.0639 59.453 88.0639H109.895C110.648 88.0639 111.258 87.4538 111.258 86.7012V40.4315C111.258 39.6789 110.648 39.0688 109.895 39.0688Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M69.7909 47.1387H63.5499C62.7973 47.1387 62.1873 47.7488 62.1873 48.5013V53.846C62.1873 54.5985 62.7973 55.2086 63.5499 55.2086H69.7909C70.5434 55.2086 71.1535 54.5985 71.1535 53.846V48.5013C71.1535 47.7488 70.5434 47.1387 69.7909 47.1387Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 47.1387H74.0848C73.951 47.1387 73.8425 47.2471 73.8425 47.3809V47.7927C73.8425 47.9265 73.951 48.035 74.0848 48.035H109.244C109.378 48.035 109.487 47.9265 109.487 47.7927V47.3809C109.487 47.2471 109.378 47.1387 109.244 47.1387Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 49.8276H74.0848C73.951 49.8276 73.8425 49.9361 73.8425 50.0699V50.4817C73.8425 50.6155 73.951 50.724 74.0848 50.724H109.244C109.378 50.724 109.487 50.6155 109.487 50.4817V50.0699C109.487 49.9361 109.378 49.8276 109.244 49.8276Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 59.5933H61.5333C61.3995 59.5933 61.291 59.7017 61.291 59.8355V60.2473C61.291 60.3811 61.3995 60.4896 61.5333 60.4896H109.244C109.378 60.4896 109.487 60.3811 109.487 60.2473V59.8355C109.487 59.7017 109.378 59.5933 109.244 59.5933Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 62.2827H61.5333C61.3995 62.2827 61.291 62.3912 61.291 62.525V62.9368C61.291 63.0706 61.3995 63.179 61.5333 63.179H109.244C109.378 63.179 109.487 63.0706 109.487 62.9368V62.525C109.487 62.3912 109.378 62.2827 109.244 62.2827Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 65.8677H61.5333C61.3995 65.8677 61.291 65.9761 61.291 66.1099V66.5218C61.291 66.6555 61.3995 66.764 61.5333 66.764H109.244C109.378 66.764 109.487 66.6555 109.487 66.5218V66.1099C109.487 65.9761 109.378 65.8677 109.244 65.8677Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M88.8439 68.5596H61.5333C61.3995 68.5596 61.291 68.668 61.291 68.8018V69.2136C61.291 69.3474 61.3995 69.4559 61.5333 69.4559H88.8439C88.9777 69.4559 89.0862 69.3474 89.0862 69.2136V68.8018C89.0862 68.668 88.9777 68.5596 88.8439 68.5596Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 72.1357H61.5333C61.3995 72.1357 61.291 72.2442 61.291 72.378V72.7898C61.291 72.9236 61.3995 73.0321 61.5333 73.0321H109.244C109.378 73.0321 109.487 72.9236 109.487 72.7898V72.378C109.487 72.2442 109.378 72.1357 109.244 72.1357Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 74.8247H61.5333C61.3995 74.8247 61.291 74.9332 61.291 75.067V75.4788C61.291 75.6126 61.3995 75.721 61.5333 75.721H109.244C109.378 75.721 109.487 75.6126 109.487 75.4788V75.067C109.487 74.9332 109.378 74.8247 109.244 74.8247Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M109.244 78.4136H61.5333C61.3995 78.4136 61.291 78.522 61.291 78.6558V79.0676C61.291 79.2014 61.3995 79.3099 61.5333 79.3099H109.244C109.378 79.3099 109.487 79.2014 109.487 79.0676V78.6558C109.487 78.522 109.378 78.4136 109.244 78.4136Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M88.8439 81.1025H61.5333C61.3995 81.1025 61.291 81.211 61.291 81.3448V81.7566C61.291 81.8904 61.3995 81.9989 61.5333 81.9989H88.8439C88.9777 81.9989 89.0862 81.8904 89.0862 81.7566V81.3448C89.0862 81.211 88.9777 81.1025 88.8439 81.1025Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.5" },
                React.createElement("path", { opacity: "0.5", d: "M118.698 50.2822H67.0021C66.2495 50.2822 65.6394 50.8923 65.6394 51.6449V99.1319C65.6394 99.8845 66.2495 100.495 67.0021 100.495H118.698C119.451 100.495 120.061 99.8845 120.061 99.1319V51.6449C120.061 50.8923 119.451 50.2822 118.698 50.2822Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M118.108 50.6729H67.6651C66.9126 50.6729 66.3025 51.2829 66.3025 52.0355V98.3052C66.3025 99.0578 66.9126 99.6679 67.6651 99.6679H118.108C118.86 99.6679 119.47 99.0578 119.47 98.3052V52.0355C119.47 51.2829 118.86 50.6729 118.108 50.6729Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M78.0033 58.7427H71.7623C71.0097 58.7427 70.3997 59.3528 70.3997 60.1053V65.45C70.3997 66.2025 71.0097 66.8126 71.7623 66.8126H78.0033C78.7558 66.8126 79.3659 66.2025 79.3659 65.45V60.1053C79.3659 59.3528 78.7558 58.7427 78.0033 58.7427Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.46 58.7427H82.3001C82.1663 58.7427 82.0579 58.8511 82.0579 58.9849V59.3968C82.0579 59.5305 82.1663 59.639 82.3001 59.639H117.46C117.593 59.639 117.702 59.5305 117.702 59.3968V58.9849C117.702 58.8511 117.593 58.7427 117.46 58.7427Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.46 61.4316H82.3001C82.1663 61.4316 82.0579 61.5401 82.0579 61.6739V62.0857C82.0579 62.2195 82.1663 62.328 82.3001 62.328H117.46C117.593 62.328 117.702 62.2195 117.702 62.0857V61.6739C117.702 61.5401 117.593 61.4316 117.46 61.4316Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.457 71.1943H69.7454C69.6116 71.1943 69.5032 71.3028 69.5032 71.4366V71.8484C69.5032 71.9822 69.6116 72.0907 69.7454 72.0907H117.457C117.59 72.0907 117.699 71.9822 117.699 71.8484V71.4366C117.699 71.3028 117.59 71.1943 117.457 71.1943Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.457 73.8862H69.7454C69.6116 73.8862 69.5032 73.9947 69.5032 74.1285V74.5403C69.5032 74.6741 69.6116 74.7826 69.7454 74.7826H117.457C117.59 74.7826 117.699 74.6741 117.699 74.5403V74.1285C117.699 73.9947 117.59 73.8862 117.457 73.8862Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.457 77.4717H69.7454C69.6116 77.4717 69.5032 77.5801 69.5032 77.7139V78.1258C69.5032 78.2595 69.6116 78.368 69.7454 78.368H117.457C117.59 78.368 117.699 78.2595 117.699 78.1258V77.7139C117.699 77.5801 117.59 77.4717 117.457 77.4717Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M97.0561 80.1606H69.7454C69.6116 80.1606 69.5032 80.2691 69.5032 80.4029V80.8147C69.5032 80.9485 69.6116 81.057 69.7454 81.057H97.0561C97.1899 81.057 97.2983 80.9485 97.2983 80.8147V80.4029C97.2983 80.2691 97.1899 80.1606 97.0561 80.1606Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.457 83.7397H69.7454C69.6116 83.7397 69.5032 83.8482 69.5032 83.982V84.3938C69.5032 84.5276 69.6116 84.6361 69.7454 84.6361H117.457C117.59 84.6361 117.699 84.5276 117.699 84.3938V83.982C117.699 83.8482 117.59 83.7397 117.457 83.7397Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.457 86.4287H69.7454C69.6116 86.4287 69.5032 86.5372 69.5032 86.671V87.0828C69.5032 87.2166 69.6116 87.325 69.7454 87.325H117.457C117.59 87.325 117.699 87.2166 117.699 87.0828V86.671C117.699 86.5372 117.59 86.4287 117.457 86.4287Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M117.457 90.0171H69.7454C69.6116 90.0171 69.5032 90.1255 69.5032 90.2593V90.6712C69.5032 90.805 69.6116 90.9134 69.7454 90.9134H117.457C117.59 90.9134 117.699 90.805 117.699 90.6712V90.2593C117.699 90.1255 117.59 90.0171 117.457 90.0171Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M97.0561 92.7061H69.7454C69.6116 92.7061 69.5032 92.8145 69.5032 92.9483V93.3601C69.5032 93.4939 69.6116 93.6024 69.7454 93.6024H97.0561C97.1899 93.6024 97.2983 93.4939 97.2983 93.3601V92.9483C97.2983 92.8145 97.1899 92.7061 97.0561 92.7061Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.5" },
                React.createElement("path", { opacity: "0.5", d: "M144.44 56.7803H97.2864V112.183H144.44V56.7803Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M143.438 57.6738H98.7368V110.96H143.438V57.6738Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.5" },
                React.createElement("path", { opacity: "0.5", d: "M123.522 66.1465V64.8081H118.662V66.1465H115.243V72.7206H126.932V66.1465H123.522ZM119.268 65.2896H122.901V66.1405H119.268V65.2896Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M126.438 66.3677H115.737V72.3876H126.438V66.3677Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M118.859 65.1411V66.7036H119.416V65.5893H122.759V66.7036H123.316V65.1411H118.859Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M136.861 81.0508H105.426V82.3892H136.861V81.0508Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M136.861 89.8564H105.426V91.1949H136.861V89.8564Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M136.861 92.1973H105.426V93.5357H136.861V92.1973Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M118.692 87.5161H105.426V88.8545H118.692V87.5161Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.6" },
                React.createElement("path", { opacity: "0.6", d: "M136.861 87.5161H126.941V88.8545H136.861V87.5161Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M126.381 97.6602H116.46V98.9986H126.381V97.6602Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M38.4863 72.3115V174.038L111.809 192.849V91.8914L38.4863 72.3115Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M185.129 173.657L111.809 192.849V91.8914L185.129 72.3115V173.657Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.07" },
                React.createElement("path", { opacity: "0.07", d: "M185.129 173.657L111.809 192.849V91.8914L185.129 72.3115V173.657Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("g", { opacity: "0.07" },
                React.createElement("path", { opacity: "0.07", d: "M155.953 139.875V173.272L185.129 165.212V131.814L155.953 139.875Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M168.381 63.8267H146.757V84.6026H168.381V63.8267Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M168.041 64.3354H147.096V84.1787H168.041V64.3354Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.5" },
                React.createElement("path", { opacity: "0.5", d: "M159.42 67.8151V66.8037H155.717V67.8212H153.122V72.8236H162.015V67.8151H159.42ZM156.18 67.1671H158.96V67.8151H156.18V67.1671Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("g", { opacity: "0.6" },
                React.createElement("path", { opacity: "0.6", d: "M161.637 67.9844H153.497V72.5629H161.637V67.9844Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("g", { opacity: "0.6" },
                React.createElement("path", { opacity: "0.6", d: "M155.871 67.0488V68.2389H156.298V67.391H158.842V68.2389H159.266V67.0488H155.871Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("g", { opacity: "0.6" },
                React.createElement("path", { opacity: "0.6", d: "M161.51 76.124H153.964V77.1415H161.51V76.124Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M164.014 77.9893H151.123V79.0067H164.014V77.9893Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.5" },
                React.createElement("path", { opacity: "0.5", d: "M123.51 0.317871H95.4937V23.3316H123.51V0.317871Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M123.131 0.753906H95.8208V22.95H123.131V0.753906Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M123.131 0.753906H95.8208V5.70488H123.131V0.753906Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.1" },
                React.createElement("path", { opacity: "0.1", d: "M123.131 0.753906H95.8208V5.70488H123.131V0.753906Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M120.957 7.50098H97.998V10.3837H120.957V7.50098Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M114.756 12.5605H97.998V13.5386H114.756V12.5605Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M108.878 14.2471H97.998V15.2252H108.878V14.2471Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M112.36 15.9341H97.998V16.9122H112.36V15.9341Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M114.756 17.6177H97.998V18.5958H114.756V17.6177Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M103.6 19.3042H97.998V20.2823H103.6V19.3042Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M168.241 26.499H139.786V30.0722H168.241V26.499Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M160.556 32.7705H139.786V33.9848H160.556V32.7705Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M153.273 34.8599H139.786V36.0741H153.273V34.8599Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M157.588 36.9521H139.786V38.1664H157.588V36.9521Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M160.556 39.0415H139.786V40.2558H160.556V39.0415Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M146.732 41.1309H139.786V42.3451H146.732V41.1309Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M149.361 27.7134H141.27V28.7914H149.361V27.7134Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.1" },
                React.createElement("path", { opacity: "0.1", d: "M37.5293 125.095L78.6028 135.46L111.615 108.589V92.0824L38.2954 73.6562L37.5293 125.095Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("g", { opacity: "0.1" },
                React.createElement("path", { opacity: "0.1", d: "M185.705 125.095L144.628 135.46L111.615 108.589V92.0824L184.935 73.6562L185.705 125.095Z", fill: "#DADCE0", fillOpacity: "0.3" })),
            React.createElement("path", { opacity: "0.7", d: "M38.4863 72.3115L0.86792 103.407L74.1879 121.833L111.809 91.8914L38.4863 72.3115Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("path", { opacity: "0.7", d: "M185.129 72.3115L223.132 103.407L150.197 121.833L111.809 91.8914L185.129 72.3115Z", fill: "#DADCE0", fillOpacity: "0.3" }),
            React.createElement("g", { opacity: "0.07" },
                React.createElement("path", { opacity: "0.07", d: "M185.129 72.3115L223.132 103.407L150.197 121.833L111.809 91.8914L185.129 72.3115Z", fill: "#DADCE0", fillOpacity: "0.3" }))),
        React.createElement("path", { d: "M185.262 71.3792L112.569 53.8706V190.884L185.262 171.855V71.3792Z", fill: "#50ACED" }),
        React.createElement("path", { opacity: "0.1", d: "M185.262 71.3792L112.569 53.8706V190.884L185.262 171.855V71.3792Z", fill: "black" }),
        React.createElement("path", { d: "M39.8792 71.3792L112.569 53.8706V190.884L39.8792 172.234V71.3792Z", fill: "#50ACED" }),
        React.createElement("path", { opacity: "0.17", d: "M39.8792 70.7732L112.569 53.2646V190.884L39.8792 172.234V70.7732Z", fill: "black" }),
        React.createElement("path", { d: "M111.964 54.4761L150.248 22.6626L222.941 41.3128L184.657 71.9847L111.964 54.4761Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M3.18726 42.2968L40.4846 71.9845L113.175 54.4759L76.2589 23.2681L3.18726 42.2968Z", fill: "#50ACED" }),
        React.createElement("path", { opacity: "0.05", d: "M2.58179 41.6913L40.7876 71.9847L113.478 54.4761L75.6534 22.6626L2.58179 41.6913Z", fill: "black" }),
        React.createElement("g", { opacity: "0.5" },
            React.createElement("path", { opacity: "0.5", d: "M111.246 38.0332H60.0162C59.2636 38.0332 58.6536 38.6433 58.6536 39.3959V86.4529C58.6536 87.2055 59.2636 87.8155 60.0162 87.8155H111.246C111.999 87.8155 112.609 87.2055 112.609 86.4529V39.3959C112.609 38.6433 111.999 38.0332 111.246 38.0332Z", fill: "#DADCE0", fillOpacity: "0.3" })),
        React.createElement("path", { d: "M110.662 38.4209H60.6764C59.9238 38.4209 59.3137 39.031 59.3137 39.7835V85.6324C59.3137 86.3849 59.9238 86.995 60.6764 86.995H110.662C111.414 86.995 112.024 86.3849 112.024 85.6324V39.7835C112.024 39.031 111.414 38.4209 110.662 38.4209Z", fill: "white" }),
        React.createElement("path", { d: "M70.9024 46.4214H64.7372C63.9846 46.4214 63.3745 47.0315 63.3745 47.784V53.059C63.3745 53.8116 63.9846 54.4217 64.7372 54.4217H70.9024C71.655 54.4217 72.2651 53.8116 72.2651 53.059V47.784C72.2651 47.0315 71.655 46.4214 70.9024 46.4214Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M110.029 46.4214H75.1749C75.0411 46.4214 74.9326 46.5298 74.9326 46.6636V47.0694C74.9326 47.2032 75.0411 47.3117 75.1749 47.3117H110.029C110.162 47.3117 110.271 47.2032 110.271 47.0694V46.6636C110.271 46.5298 110.162 46.4214 110.029 46.4214Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M110.029 49.0859H75.1749C75.0411 49.0859 74.9326 49.1944 74.9326 49.3282V49.734C74.9326 49.8678 75.0411 49.9762 75.1749 49.9762H110.029C110.162 49.9762 110.271 49.8678 110.271 49.734V49.3282C110.271 49.1944 110.162 49.0859 110.029 49.0859Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M110.029 58.7671H62.7293C62.5955 58.7671 62.4871 58.8756 62.4871 59.0093V59.4151C62.4871 59.5489 62.5955 59.6574 62.7293 59.6574H110.029C110.162 59.6574 110.271 59.5489 110.271 59.4151V59.0093C110.271 58.8756 110.162 58.7671 110.029 58.7671Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M110.029 61.4346H62.7293C62.5955 61.4346 62.4871 61.543 62.4871 61.6768V62.0826C62.4871 62.2164 62.5955 62.3248 62.7293 62.3248H110.029C110.162 62.3248 110.271 62.2164 110.271 62.0826V61.6768C110.271 61.543 110.162 61.4346 110.029 61.4346Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M110.029 64.9897H62.7293C62.5955 64.9897 62.4871 65.0982 62.4871 65.232V65.6378C62.4871 65.7716 62.5955 65.88 62.7293 65.88H110.029C110.162 65.88 110.271 65.7716 110.271 65.6378V65.232C110.271 65.0982 110.162 64.9897 110.029 64.9897Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M89.8038 67.6572H62.7293C62.5955 67.6572 62.4871 67.7657 62.4871 67.8995V68.3052C62.4871 68.439 62.5955 68.5475 62.7293 68.5475H89.8038C89.9376 68.5475 90.046 68.439 90.046 68.3052V67.8995C90.046 67.7657 89.9376 67.6572 89.8038 67.6572Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M110.029 71.2036H62.7293C62.5955 71.2036 62.4871 71.3121 62.4871 71.4459V71.8516C62.4871 71.9854 62.5955 72.0939 62.7293 72.0939H110.029C110.162 72.0939 110.271 71.9854 110.271 71.8516V71.4459C110.271 71.3121 110.162 71.2036 110.029 71.2036Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M110.029 73.8711H62.7293C62.5955 73.8711 62.4871 73.9796 62.4871 74.1133V74.5191C62.4871 74.6529 62.5955 74.7614 62.7293 74.7614H110.029C110.162 74.7614 110.271 74.6529 110.271 74.5191V74.1133C110.271 73.9796 110.162 73.8711 110.029 73.8711Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M110.029 77.4263H62.7293C62.5955 77.4263 62.4871 77.5347 62.4871 77.6685V78.0743C62.4871 78.2081 62.5955 78.3165 62.7293 78.3165H110.029C110.162 78.3165 110.271 78.2081 110.271 78.0743V77.6685C110.271 77.5347 110.162 77.4263 110.029 77.4263Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M89.8038 80.0938H62.7293C62.5955 80.0938 62.4871 80.2022 62.4871 80.336V80.7418C62.4871 80.8756 62.5955 80.984 62.7293 80.984H89.8038C89.9376 80.984 90.046 80.8756 90.046 80.7418V80.336C90.046 80.2022 89.9376 80.0938 89.8038 80.0938Z", fill: "#DADCE0" }),
        React.createElement("g", { opacity: "0.5" },
            React.createElement("path", { opacity: "0.5", d: "M119.389 49.5371H68.1588C67.4062 49.5371 66.7961 50.1472 66.7961 50.8998V97.9568C66.7961 98.7094 67.4062 99.3194 68.1588 99.3194H119.389C120.141 99.3194 120.751 98.7094 120.751 97.9568V50.8998C120.751 50.1472 120.141 49.5371 119.389 49.5371Z", fill: "#DADCE0", fillOpacity: "0.3" })),
        React.createElement("path", { d: "M118.804 49.9248H68.819C68.0664 49.9248 67.4563 50.5349 67.4563 51.2875V97.1363C67.4563 97.8888 68.0664 98.4989 68.819 98.4989H118.804C119.557 98.4989 120.167 97.8888 120.167 97.1363V51.2875C120.167 50.5349 119.557 49.9248 118.804 49.9248Z", fill: "white" }),
        React.createElement("path", { d: "M79.045 57.9248H72.8797C72.1272 57.9248 71.5171 58.5349 71.5171 59.2875V64.5625C71.5171 65.315 72.1272 65.9251 72.8797 65.9251H79.045C79.7976 65.9251 80.4077 65.315 80.4077 64.5625V59.2875C80.4077 58.5349 79.7976 57.9248 79.045 57.9248Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M118.171 57.9248H83.3177C83.1839 57.9248 83.0754 58.0333 83.0754 58.1671V58.5728C83.0754 58.7066 83.1839 58.8151 83.3177 58.8151H118.171C118.305 58.8151 118.414 58.7066 118.414 58.5728V58.1671C118.414 58.0333 118.305 57.9248 118.171 57.9248Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M118.171 60.5898H83.3177C83.1839 60.5898 83.0754 60.6983 83.0754 60.8321V61.2379C83.0754 61.3716 83.1839 61.4801 83.3177 61.4801H118.171C118.305 61.4801 118.414 61.3716 118.414 61.2379V60.8321C118.414 60.6983 118.305 60.5898 118.171 60.5898Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M118.171 70.2705H70.8721C70.7383 70.2705 70.6299 70.379 70.6299 70.5128V70.9185C70.6299 71.0523 70.7383 71.1608 70.8721 71.1608H118.171C118.305 71.1608 118.414 71.0523 118.414 70.9185V70.5128C118.414 70.379 118.305 70.2705 118.171 70.2705Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M118.171 72.9385H70.8721C70.7383 72.9385 70.6299 73.0469 70.6299 73.1807V73.5865C70.6299 73.7203 70.7383 73.8287 70.8721 73.8287H118.171C118.305 73.8287 118.414 73.7203 118.414 73.5865V73.1807C118.414 73.0469 118.305 72.9385 118.171 72.9385Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M118.171 76.4937H70.8721C70.7383 76.4937 70.6299 76.6021 70.6299 76.7359V77.1417C70.6299 77.2755 70.7383 77.3839 70.8721 77.3839H118.171C118.305 77.3839 118.414 77.2755 118.414 77.1417V76.7359C118.414 76.6021 118.305 76.4937 118.171 76.4937Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M97.9466 79.1611H70.8721C70.7383 79.1611 70.6299 79.2696 70.6299 79.4034V79.8092C70.6299 79.9429 70.7383 80.0514 70.8721 80.0514H97.9466C98.0804 80.0514 98.1888 79.9429 98.1888 79.8092V79.4034C98.1888 79.2696 98.0804 79.1611 97.9466 79.1611Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M118.171 82.707H70.8721C70.7383 82.707 70.6299 82.8155 70.6299 82.9493V83.3551C70.6299 83.4888 70.7383 83.5973 70.8721 83.5973H118.171C118.305 83.5973 118.414 83.4888 118.414 83.3551V82.9493C118.414 82.8155 118.305 82.707 118.171 82.707Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M118.171 85.375H70.8721C70.7383 85.375 70.6299 85.4835 70.6299 85.6173V86.023C70.6299 86.1568 70.7383 86.2653 70.8721 86.2653H118.171C118.305 86.2653 118.414 86.1568 118.414 86.023V85.6173C118.414 85.4835 118.305 85.375 118.171 85.375Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M118.171 88.9302H70.8721C70.7383 88.9302 70.6299 89.0386 70.6299 89.1724V89.5782C70.6299 89.712 70.7383 89.8204 70.8721 89.8204H118.171C118.305 89.8204 118.414 89.712 118.414 89.5782V89.1724C118.414 89.0386 118.305 88.9302 118.171 88.9302Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M97.9466 91.5977H70.8721C70.7383 91.5977 70.6299 91.7061 70.6299 91.8399V92.2457C70.6299 92.3795 70.7383 92.4879 70.8721 92.4879H97.9466C98.0804 92.4879 98.1888 92.3795 98.1888 92.2457V91.8399C98.1888 91.7061 98.0804 91.5977 97.9466 91.5977Z", fill: "#DADCE0" }),
        React.createElement("g", { opacity: "0.5" },
            React.createElement("path", { opacity: "0.5", d: "M144.925 55.981H98.1736V110.908H144.925V55.981Z", fill: "#DADCE0", fillOpacity: "0.3" })),
        React.createElement("path", { d: "M143.932 56.8652H99.6121V109.694H143.932V56.8652Z", fill: "white" }),
        React.createElement("g", { opacity: "0.5" },
            React.createElement("path", { opacity: "0.5", d: "M124.185 65.2654V63.936H119.355V65.2654H115.976V71.7849H127.564V65.2654H124.185ZM119.946 64.4205H123.579V65.2654H119.946V64.4205Z", fill: "#DADCE0", fillOpacity: "0.3" })),
        React.createElement("path", { d: "M137.868 65.3491H105.868V71.3491H137.868V65.3491Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M137.409 80.0425H106.243V81.3688H137.409V80.0425Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M137.409 88.7725H106.243V90.0988H137.409V88.7725Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M137.409 91.0952H106.243V92.4215H137.409V91.0952Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M119.395 86.4531H106.243V87.7794H119.395V86.4531Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M127.019 96.5093H117.184V97.8356H127.019V96.5093Z", fill: "#50ACED" }),
        React.createElement("g", { filter: "url(#filter0_d)" },
            React.createElement("path", { d: "M39.8792 71.3789V172.234L112.569 190.884L113.55 90.7892L39.8792 71.3789Z", fill: "#50ACED" })),
        React.createElement("path", { d: "M185.262 171.855L112.569 190.884V90.7892L185.262 71.3789V171.855Z", fill: "#50ACED" }),
        React.createElement("g", { opacity: "0.07", filter: "url(#filter1_d)" },
            React.createElement("path", { d: "M185.262 171.855L112.569 190.884V90.7892L185.262 71.3789V171.855Z", fill: "black" })),
        React.createElement("path", { opacity: "0.07", d: "M156.338 138.364V171.473L185.262 163.482V130.37L156.338 138.364Z", fill: "black" }),
        React.createElement("path", { d: "M168.659 62.9668H147.22V83.5641H168.659V62.9668Z", fill: "#DADCE0", fillOpacity: "0.3" }),
        React.createElement("path", { d: "M168.323 63.4692H147.556V83.143H168.323V63.4692Z", fill: "white" }),
        React.createElement("g", { opacity: "0.5" },
            React.createElement("path", { opacity: "0.5", d: "M159.778 66.9185V65.895H156.105V66.9064H153.534V71.8665H162.349V66.9185H159.778ZM156.565 66.2766H159.32V66.9185H156.565V66.2766Z", fill: "#DADCE0", fillOpacity: "0.3" })),
        React.createElement("path", { opacity: "0.6", d: "M164.868 67.3491H150.868V71.3491H164.868V67.3491Z", fill: "#50ACED" }),
        React.createElement("g", { opacity: "0.6" },
            React.createElement("path", { opacity: "0.6", d: "M161.849 75.1582H154.366V76.1666H161.849V75.1582Z", fill: "#50ACED" })),
        React.createElement("path", { d: "M164.329 77.0083H151.55V78.0167H164.329V77.0083Z", fill: "#DADCE0" }),
        React.createElement("g", { opacity: "0.5" },
            React.createElement("path", { opacity: "0.5", d: "M124.173 0H96.396V22.8169H124.173V0Z", fill: "#DADCE0", fillOpacity: "0.3" })),
        React.createElement("path", { d: "M123.797 0.433105H96.72V22.4385H123.797V0.433105Z", fill: "white" }),
        React.createElement("path", { d: "M123.797 0.433105H96.72V5.34169H123.797V0.433105Z", fill: "#50ACED" }),
        React.createElement("path", { opacity: "0.1", d: "M123.797 0.433105H96.72V5.34169H123.797V0.433105Z", fill: "black" }),
        React.createElement("path", { d: "M121.642 7.12207H98.8792V9.98062H121.642V7.12207Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M115.491 12.1367H98.8792V13.1087H115.491V12.1367Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M109.665 13.8081H98.8792V14.7801H109.665V13.8081Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M113.117 15.48H98.8792V16.452H113.117V15.48Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M115.491 17.1543H98.8792V18.1263H115.491V17.1543Z", fill: "#DADCE0" }),
        React.createElement("path", { d: "M104.436 18.8262H98.8792V19.7982H104.436V18.8262Z", fill: "#DADCE0" }),
        React.createElement("path", { opacity: "0.1", d: "M38.9282 123.711L79.6504 133.985L112.381 107.344V90.9801L39.6883 72.7114L38.9282 123.711Z", fill: "black" }),
        React.createElement("path", { opacity: "0.1", d: "M185.835 123.711L145.113 133.985L112.382 107.344V90.9801L185.075 72.7114L185.835 123.711Z", fill: "black" }),
        React.createElement("path", { d: "M39.8792 71.3789L2.58179 102.205L75.2719 120.474L112.569 90.7892L39.8792 71.3789Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M185.262 71.3789L222.941 102.205L150.63 120.474L112.569 90.7892L185.262 71.3789Z", fill: "#50ACED" }),
        React.createElement("path", { opacity: "0.07", d: "M185.262 71.3789L222.941 102.205L150.63 120.474L112.569 90.7892L185.262 71.3789Z", fill: "black" }),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d", x: "15.8792", y: "51.3789", width: "121.671", height: "167.505", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "12" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow", result: "shape" })),
            React.createElement("filter", { id: "filter1_d", x: "88.5693", y: "51.3789", width: "120.693", height: "167.505", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "12" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow", result: "shape" })))));
};
export default SaveTranscript;
