import { useTheme } from '@mui/material';
import React from 'react';
const SendIcon = ({ buttonState }) => {
    const theme = useTheme();
    const buttonStateColors = {
        disabled: theme.palette.mode === 'light' ? 'black' : 'white',
        inactive: theme.palette.ava.blue2,
        active: theme.palette.ava.blue2,
    };
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
        React.createElement("path", { d: "M18.0782 3.46407C18.0782 3.46407 18.0782 3.47189 18.0782 3.47579L13.5313 18.4711C13.4625 18.7147 13.3208 18.9313 13.1253 19.0921C12.9298 19.2528 12.6898 19.3499 12.4376 19.3703C12.4016 19.3734 12.3657 19.375 12.3297 19.375C12.0933 19.3757 11.8616 19.3088 11.662 19.1822C11.4624 19.0555 11.3032 18.8744 11.2032 18.6602L8.41334 12.7703C8.38579 12.7121 8.37694 12.6467 8.38799 12.5832C8.39904 12.5197 8.42945 12.4612 8.47506 12.4156L12.9485 7.94142C13.0608 7.82325 13.1224 7.66591 13.1203 7.50293C13.1182 7.33996 13.0526 7.18424 12.9373 7.06899C12.8221 6.95374 12.6664 6.88807 12.5034 6.88598C12.3404 6.8839 12.1831 6.94556 12.0649 7.05782L7.58443 11.5313C7.53889 11.5769 7.48036 11.6073 7.41687 11.6183C7.35337 11.6294 7.28801 11.6205 7.22974 11.593L1.37037 8.8172C1.14025 8.71177 0.946586 8.54042 0.813909 8.32486C0.681232 8.10929 0.615507 7.8592 0.625056 7.60626C0.637781 7.34629 0.731899 7.09689 0.894104 6.89333C1.05631 6.68977 1.27839 6.54234 1.52896 6.47188L16.5243 1.92501H16.536C16.7495 1.86503 16.9751 1.86292 17.1897 1.91892C17.4043 1.97491 17.6001 2.08698 17.7571 2.24364C17.914 2.40029 18.0265 2.59589 18.0829 2.81036C18.1394 3.02483 18.1377 3.25045 18.0782 3.46407Z", fill: buttonStateColors[buttonState], fillOpacity: buttonState === 'disabled' ? 0.24 : 1 })));
};
export default SendIcon;
