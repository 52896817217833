import { useCallback, useEffect } from 'react';
import { selectFirebaseUser } from '../selectors/auth';
import { selectRate, selectRateHover } from '../selectors/rate-conversation';
import { selectAvaId, selectAvaName, selectFeedbackMetrics } from '../selectors/userProfile';
import { clearConversationStatus, setRate, setRateConversationOpen, setRateHover, } from '../store/slices/rateConversation';
import { useAppDispatch, useAppSelector } from '../store/store';
import { SNCF } from '../utils';
import { trackConversationRating } from '../utils/conversation';
// Pass in `requestFinishRating` if you need to delay finishing the actual submission and cleanup
// when user clicks a star. If you pass this in, you need to call `finishRating` returned by this
// hook yourself. This is here to allow for the UI to start fading out first and finish/reset
// the rating only when the fade out has been completed. If not provided, `finishRating` will
// be called immediately.
export function useRateConversation(requestFinishRating) {
    const rate = useAppSelector(selectRate);
    const rateHover = useAppSelector(selectRateHover);
    const conversationStatus = useAppSelector((state) => state.rateConversation.conversationStatus);
    const avaId = useAppSelector(selectAvaId);
    const avaName = useAppSelector(selectAvaName);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const feedbackMetrics = useAppSelector(selectFeedbackMetrics);
    const dispatch = useAppDispatch();
    const trackRateEvent = useCallback((rate) => {
        trackConversationRating({
            rate,
            status: conversationStatus,
            feedbackMetrics,
            avaId,
            firebaseAuthUID: firebaseUser.uid,
            dispatch,
        });
    }, [rate, conversationStatus, feedbackMetrics, avaId, firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid, dispatch]);
    // `newRate` is only used to check if we need to submit a zero rating. If the user
    // clicked a rating, it has already been submitted at this point.
    const finishRating = useCallback((newRate) => {
        if (!newRate) {
            trackRateEvent(0);
            dispatch(clearConversationStatus());
        }
        dispatch(setRate(undefined));
        dispatch(setRateHover(undefined));
        dispatch(setRateConversationOpen(false));
    }, [dispatch, trackRateEvent, setRate, setRateHover, setRateConversationOpen]);
    const onRateChange = useCallback((newRate) => {
        // Return if already rated
        if (rate) {
            return;
        }
        dispatch(setRate(newRate));
        // Track as soon as we rate
        trackRateEvent(newRate);
        // If no CTA, let's close
        const isOnDesktopAndLoggedIn = window.isElectron && avaName;
        if (!SNCF && ((newRate >= 4 && isOnDesktopAndLoggedIn) || newRate === 3)) {
            setTimeout(() => {
                if (requestFinishRating) {
                    requestFinishRating();
                }
                else {
                    finishRating(newRate);
                }
            }, 2000);
        }
    }, [rate, window.isElectron, avaName, SNCF, dispatch, setRate, trackRateEvent, requestFinishRating, finishRating]);
    const onRateHover = useCallback((value) => {
        if (rateHover !== value) {
            dispatch(setRateHover(value));
        }
    }, [rateHover, dispatch, setRateHover]);
    useEffect(() => {
        const handleUnload = () => {
            trackRateEvent(0);
        };
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);
    return { rate, rateHover, onRateChange, onRateHover, finishRating };
}
