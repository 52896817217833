import { getAvaBackendEndpoint } from './http';
let worker = undefined;
try {
    worker = new Worker(new URL('../sw.js', import.meta.url));
    if (Math.random() > 1) {
        // The service worker is actually loaded in index.html. This code needs
        // to be somewhere in our JS codebase though in order for webpack
        // to pick it up and compile it well.
        // The weird if condition is meant to evaluate to if (false), but in a way
        // that the compiler won't automatically remove this code
        navigator.serviceWorker.register(new URL('../service-worker.ts', import.meta.url), { scope: '/' }
        /* webpackChunkName: "service-worker" */
        );
    }
}
catch (_a) { }
export const prepareOfflineTracking = (idToken) => {
    if (!worker)
        return;
    const avaBackendEndpoint = getAvaBackendEndpoint();
    const authentifiedHeaders = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${btoa(`firebase-mobile:${idToken}`)}`,
    };
    worker.postMessage({
        type: 'prepareOfflineTracking',
        avaBackendEndpoint,
        authentifiedHeaders,
    });
};
export const updateConvoMetricsThroughWorker = (avaId, firebaseAuthUID, metricsToUpdate) => {
    if (!worker)
        throw new Error('no worker');
    worker.postMessage({
        type: 'updateConvoMetrics',
        avaId,
        firebaseAuthUID,
        metricsToUpdate,
    });
};
