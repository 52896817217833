import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { selectFirebaseUser } from '../../selectors/auth';
import { useAppSelector } from '../../store/store';
import { hashStringToInteger } from '../../utils/hash';
import { ConvoV2ParticipantSmiley } from './ConvoV2ParticipantSmiley';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: (props) => props.size,
        width: (props) => props.size,
        borderRadius: '50%',
        border: '2px solid',
        borderColor: (props) => props.borderColor,
        backgroundColor: (props) => props.backgroundColor,
        color: (props) => props.textColor,
        overflow: 'hidden',
    },
    noBorder: {
        border: 0,
    },
}));
export const ConvoV2ParticipantAvatar = React.memo(({ speaker, size, borderAroundPhoto, borderAroundSmiley, className }) => {
    var _a, _b, _c, _d, _e, _f;
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const theme = useTheme();
    const avatarUrl = speaker.userAvatar || speaker.photoUrl || (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.photoURL);
    const backgroundColor = ((_b = (_a = speaker.theme) === null || _a === void 0 ? void 0 : _a.light_theme) === null || _b === void 0 ? void 0 : _b.background_color) || theme.palette.success.light;
    const borderColor = ((_d = (_c = speaker.theme) === null || _c === void 0 ? void 0 : _c.light_theme) === null || _d === void 0 ? void 0 : _d.text_color) || 'white';
    const textColor = ((_f = (_e = speaker.theme) === null || _e === void 0 ? void 0 : _e.light_theme) === null || _f === void 0 ? void 0 : _f.speaker_color) || theme.palette.success.dark;
    const classes = useStyles({ backgroundColor, borderColor, textColor, size });
    const smileyVariant = useMemo(() => (!avatarUrl ? hashStringToInteger(speaker.avaId) : undefined), [avatarUrl, speaker.avaId]);
    return avatarUrl ? (React.createElement(Box, { className: classNames(classes.root, className, { [classes.noBorder]: !borderAroundPhoto }) }, avatarUrl && React.createElement("img", { src: avatarUrl, style: { height: size }, alt: "Avatar" }))) : (React.createElement(ConvoV2ParticipantSmiley, { className: classNames(classes.root, className, { [classes.noBorder]: !borderAroundSmiley }), size: size, 
        // smileyVariant is always set if !avatarUrl, but Typescript doesn't know that because the
        // smileyVariant value is wrapped is useMemo, so give it a hand by using !
        variant: smileyVariant }));
});
