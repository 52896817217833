var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
export const ConvoV2StarIcon = React.memo((_a) => {
    var { color } = _a, props = __rest(_a, ["color"]);
    return (React.createElement("svg", Object.assign({ width: "31", height: "31", viewBox: "0 0 31 31", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { "clip-path": "url(#clip0_12206_16331)" },
            React.createElement("path", { d: "M29.8906 11.7575C29.7016 11.1713 29.1823 10.7568 28.5672 10.7L20.2154 9.94113L16.9129 2.2108C16.6696 1.64488 16.115 1.2793 15.4988 1.2793C14.8823 1.2793 14.3277 1.64603 14.0844 2.21333L10.7822 9.94251L2.42894 10.7011C1.81525 10.7568 1.29595 11.1713 1.1067 11.7575C0.916524 12.3437 1.09285 12.9851 1.55676 13.3911L7.87045 18.927L6.00861 27.1263C5.87267 27.7289 6.10693 28.3514 6.60753 28.7144C6.87456 28.9111 7.19098 29.0094 7.50741 29.0094C7.7809 29.0094 8.05324 28.9365 8.2965 28.7901L15.4988 24.4832L22.6997 28.789C23.2266 29.1052 23.8908 29.0775 24.39 28.7158C24.8904 28.3528 25.1249 27.7301 24.9887 27.1275L23.1271 18.9284L29.4408 13.3922C29.9045 12.9865 30.081 12.3449 29.8906 11.7575Z", fill: color }),
            React.createElement("path", { d: "M7.14705 29.6378C6.81424 29.6378 6.48512 29.5346 6.20424 29.3315C5.68102 28.9534 5.43661 28.3017 5.57901 27.6716L7.52509 19.1005L0.925209 13.3123C0.440072 12.8876 0.254742 12.2183 0.45392 11.6044C0.653099 10.9919 1.19501 10.557 1.83663 10.4977L10.5705 9.70493L14.0216 1.6254C14.2762 1.03179 14.8559 0.648438 15.5001 0.648438C16.1442 0.648438 16.724 1.03179 16.9786 1.62402L20.4297 9.70493L29.1621 10.4977C29.8051 10.5557 30.347 10.9907 30.5462 11.6032C30.7454 12.2169 30.5612 12.8876 30.0747 13.3111L23.4762 19.0979L25.4223 27.6691C25.5649 28.2994 25.3203 28.9509 24.7973 29.329C24.2766 29.7058 23.5821 29.7375 23.0312 29.4072L15.5001 24.9073L7.97007 29.4097C7.7155 29.5623 7.43185 29.6378 7.14705 29.6378ZM15.5001 22.9689C15.7863 22.9689 16.0697 23.0457 16.3245 23.1969L23.431 27.4473L21.5943 19.3562C21.4632 18.779 21.6587 18.1789 22.1037 17.7895L28.3354 12.3229L20.0906 11.5742C19.4996 11.5213 18.9877 11.1495 18.7557 10.6011L15.5001 2.97649L12.2417 10.6023C12.0111 11.1481 11.4994 11.5199 10.907 11.573L2.66358 12.3217L8.89511 17.7882C9.34147 18.1777 9.53557 18.779 9.40448 19.3562L7.56802 27.4473L14.6745 23.1969C14.9302 23.0457 15.2139 22.9689 15.5001 22.9689ZM10.5024 9.86257V9.86372V9.86257ZM20.4966 9.85864V9.86003C20.4966 9.86003 20.4966 9.86003 20.4966 9.85864Z", fill: color })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_12206_16331" },
                React.createElement("rect", { width: "30.2503", height: "30.2503", fill: color, transform: "translate(0.375)" })))));
});
