var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CancelSaveControl from '../../../components/Controls/CancelSaveControl';
import InputField from '../../../components/Inputs/InputField';
import { useNotifications } from '../../../hooks/useNotifications';
import { selectAddBoostWordsStatus, selectBoostWords } from '../../../selectors/boost';
import { addBoostWordsRequest, resetBoostWordsStatus } from '../../../store/slices/boost';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { handleCancelSaveEvent } from '../../../utils/keyboardEvent';
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
        marginBottom: 40,
    },
    errorMessage: {
        fontSize: 18,
        lineHeight: 1.2,
        color: theme.palette.ava.blue2,
    },
    button: {
        paddingLeft: 16,
        paddingRight: 16,
        fontSize: 18,
        lineHeight: '23.4px',
    },
    input: {
        width: '100%',
        maxWidth: 300,
        marginRight: 10,
    },
}));
const BoostInput = () => {
    const classes = useStyles();
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const boostWords = useAppSelector(selectBoostWords);
    const successRef = useRef(false);
    const addBoostWordsStatus = useAppSelector(selectAddBoostWordsStatus);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');
    const [focused, setFocused] = useState(false);
    useEffect(() => {
        return () => {
            dispatch(resetBoostWordsStatus());
        };
    }, []);
    useEffect(() => {
        if (addBoostWordsStatus === 'fulfilled' && !successRef.current) {
            successRef.current = true;
        }
    }, [addBoostWordsStatus]);
    const onSave = (value) => __awaiter(void 0, void 0, void 0, function* () {
        const words = value
            .split(',')
            .map((w) => w.trim())
            .filter((word) => !!word && !boostWords.includes(word));
        try {
            yield dispatch(addBoostWordsRequest(words)).unwrap();
        }
        catch (_) {
            notify(t('boost.error.requestFailure'), { variant: 'error' });
        }
    });
    const isValid = (value) => {
        return !value
            .split(',')
            .map((w) => w.trim())
            .some((w) => w.length > 50);
    };
    const onSaveClick = () => {
        if (!isValid || isValid(value.trim())) {
            onSave(value.trim());
        }
    };
    const resetValue = () => setValue('');
    const resetSuccess = () => {
        successRef.current = false;
        dispatch(resetBoostWordsStatus());
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
            React.createElement(InputField, { className: classes.input, placeholder: focused ? t('boost.exampleKeywords') : t('boost.enterKeywords'), value: value, onChange: (e) => setValue(e.target.value), onFocus: () => setFocused(true), onBlur: () => setFocused(false), onKeyDown: (event) => {
                    handleCancelSaveEvent({
                        event,
                        saveCondition: !(addBoostWordsStatus === 'pending') && !(addBoostWordsStatus === 'fulfilled') && value.trim(),
                        onSave: () => onSaveClick(),
                        onCancel: () => resetValue(),
                    });
                }, dataQA: "enterKeyWordsToBoost" }),
            React.createElement(CancelSaveControl, { showControls: value.trim() !== '', buttonClassName: classes.button, cancelText: t('options.cancel'), saveText: t('options.add'), saveDisabled: isValid && !isValid(value.trim()), loading: addBoostWordsStatus === 'pending', resetValue: resetValue, resetSuccess: resetSuccess, success: successRef.current, successMessage: t('options.added'), error: isValid && !isValid(value.trim()), errorComponent: React.createElement("div", { className: classes.errorMessage },
                    React.createElement("span", null, t('boost.error.tooManyCharacters')),
                    React.createElement("br", null),
                    React.createElement("span", null, t('boost.error.description'))), onCancelClick: resetValue, onSaveClick: onSaveClick }))));
};
export default BoostInput;
