import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectCanRecord, selectShowKeyboardInput, selectUserRoleInConversation, UserRoleInConversation, } from '../../selectors/combined';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectIsDefaultRoomId, selectParticipantsPresent } from '../../selectors/legacy-conversation';
import { selectScribeTrainingRequested } from '../../selectors/scribe-dashboard';
import { selectFullScreen, selectKeyboardInputShown } from '../../selectors/ui';
import { initiateConversationEndSequence, setConversationEndInitiatedByCurrentUser, } from '../../store/slices/conversationEnd';
import { updateKeyboardInputShown } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { Z_INDICES } from '../../styles';
import { isMobile } from '../../utils';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import { AvaTranslateButton } from './AvaTranslateButton';
import CCButton from './CCButton';
import CloseButton from './CloseButton';
import KeyboardButton from './KeyboardButton';
import TrainingButton from './TrainingButton';
const useStyles = makeStyles({
    container: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: Z_INDICES.conversationButton,
        display: 'flex',
        flexDirection: 'column',
        width: 70,
        marginTop: 20,
        '& > :not(:last-child)': {
            marginBottom: 20,
        },
    },
});
const ConversationButtons = React.memo(() => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const scribeTrainingRequested = useSelector(selectScribeTrainingRequested);
    const fullScreen = useAppSelector(selectFullScreen);
    const userRoleIsParticipant = useAppSelector(selectUserRoleInConversation) === UserRoleInConversation.PARTICIPANT;
    const isInConversation = useAppSelector(selectIsInConversation);
    const showKeyboardButton = useAppSelector(selectShowKeyboardInput);
    const showMicrophoneButton = useAppSelector(selectCanRecord);
    const defaultRoomId = useAppSelector(selectIsDefaultRoomId);
    const participantsPresent = useAppSelector(selectParticipantsPresent);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const showCCButton = (!participantsPresent || isInConversation) &&
        participantsPresent &&
        !fullScreen &&
        userRoleIsParticipant &&
        !defaultRoomId &&
        !isMobile() &&
        !window.isElectron;
    const isCCButtonDesktop = participantsPresent && userRoleIsParticipant && !defaultRoomId && window.isElectron;
    const showCloseButton = participantsPresent && (userRoleIsParticipant || scribeTrainingRequested) && !fullScreen && !defaultRoomId;
    const showTrainingButton = !userRoleIsParticipant && scribeTrainingRequested;
    const onKeyboardButtonClick = useCallback(() => {
        dispatch(updateKeyboardInputShown(!keyboardInputShown));
    }, [keyboardInputShown]);
    const onEndConversationClick = useCallback(() => {
        dispatch(setConversationEndInitiatedByCurrentUser(true));
        dispatch(initiateConversationEndSequence());
        localStorage.setItem('soloDiaNotificationShowed', 'false');
    }, []);
    if (!isInConversation)
        return null;
    return (React.createElement("div", { className: classes.container },
        showMicrophoneButton && React.createElement(AudioRecorder, { hideButton: scribeTrainingRequested }),
        React.createElement(AvaTranslateButton, null),
        showKeyboardButton && React.createElement(KeyboardButton, { onClick: onKeyboardButtonClick }),
        showTrainingButton && React.createElement(TrainingButton, null),
        showCCButton && React.createElement(CCButton, null),
        isCCButtonDesktop && React.createElement(CCButton, { isDesktop: true }),
        showCloseButton && React.createElement(CloseButton, { onEndConversationClick: onEndConversationClick })));
});
export default ConversationButtons;
