import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Fab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Idle, NotIdle } from 'idlejs/dist';
import React from 'react';
import { Z_INDICES } from '../../styles';
import { getIsMac } from '../../utils/electron';
const fabStyle = {
    position: 'absolute',
    zIndex: Z_INDICES.fullScreenHamburgerFab,
    right: 20,
};
function FullScreenHamburger({ fullScreen, updateFullScreen }) {
    const theme = useTheme();
    const [idle, setIdle] = React.useState(false);
    React.useEffect(() => {
        const idle = new Idle()
            .whenNot([
            {
                events: ['click', 'mousemove', 'mouseenter', 'keydown', 'touchstart'],
                target: window.document,
            },
        ])
            .whenNotInteractive()
            .within(3, 1000)
            .do(() => {
            setIdle(true);
        })
            .start();
        const notIdle = new NotIdle()
            .when([
            {
                events: ['click', 'mousemove', 'mouseenter', 'keydown', 'touchstart'],
                target: window.document,
            },
        ])
            .within(1, 300)
            .do(() => setIdle(false))
            .start();
        return () => {
            idle.stop();
            notIdle.stop();
        };
    }, []);
    let heightOffset = '';
    if (window.isElectron) {
        if (getIsMac()) {
            heightOffset = ' - 28px';
        }
        else {
            heightOffset = ' - 32px';
        }
    }
    return (React.createElement("div", { style: {
            overflowX: 'hidden',
            position: 'absolute',
            backgroundColor: theme.palette.background.default,
            display: fullScreen && !idle ? undefined : 'none',
            height: `calc(var(--vh, 1vh) * 100${heightOffset})`,
            width: '100px',
            fontFamily: theme.typography.fontFamily,
        } },
        React.createElement(Fab, { onClick: (e) => {
                updateFullScreen(e);
                if (document.fullscreenElement && document.exitFullscreen)
                    document.exitFullscreen();
            }, style: Object.assign(Object.assign({}, fabStyle), { top: 20 }) },
            React.createElement(MenuOutlinedIcon, { style: { fontSize: 30, cursor: 'pointer', outline: 'none' } }))));
}
export default FullScreenHamburger;
