import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SaveTranscriptIcon from '../Icons/SaveTranscript';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    scrollPaper: {
        background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.1)',
    },
    paper: {
        justifyContent: 'space-around',
        padding: '0 48px',
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.darkBg2,
        borderRadius: 20,
        height: '64%',
        maxHeight: 565,
        [theme.breakpoints.down('md')]: {
            height: '94%',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            margin: 0,
            padding: '0 24px',
            height: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    heading: {
        color: theme.palette.ava.blue2,
        fontSize: 34,
        fontWeight: 600,
        lineHeight: '37.4px',
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
            lineHeight: '30px',
        },
    },
    text: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '27px',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '20px',
        },
    },
    icon: {
        marginBottom: -12,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    saveButton: {
        margin: '0 50px',
        padding: '15px 0',
        background: theme.palette.ava.blue2,
        color: '#FFFFFF',
        fontSize: 20,
        lineHeight: '26px',
        borderRadius: 100,
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
        },
        [theme.breakpoints.down('lg')]: {
            margin: '0 40px',
        },
        [theme.breakpoints.down('md')]: {
            margin: 0,
            fontSize: 18,
            lineHeight: '20px',
        },
    },
    notNowButton: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.blue2 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '27px',
        '&:hover': {
            backgroundColor: 'initial',
            color: theme.palette.mode === 'light' ? theme.palette.ava.blue1 : theme.palette.ava.blue4,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '20px',
        },
    },
}));
const SaveTranscriptDialog1 = ({ open, onSaveClicked, onNotNowClicked, disabled }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [screen, setScreen] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    useEffect(() => {
        const handleResize = () => {
            setScreen({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const getTranscriptIconDimensions = () => {
        if (screen.width < screen.height) {
            return {
                width: screen.width * 0.3,
                height: screen.width * 0.3 - 5,
            };
        }
        return {
            width: screen.height * 0.25,
            height: screen.height * 0.25 - 5,
        };
    };
    return (React.createElement(Dialog, { className: classes.root, classes: {
            scrollPaper: classes.scrollPaper,
            paper: classes.paper,
        }, open: open },
        React.createElement("h3", { className: classes.heading }, t('saveTranscript.dialog1.heading')),
        React.createElement("div", { className: classes.text }, t('saveTranscript.dialog1.text')),
        React.createElement("div", { className: classes.icon },
            React.createElement(SaveTranscriptIcon, Object.assign({}, getTranscriptIconDimensions()))),
        React.createElement("div", { className: classes.buttonContainer },
            React.createElement(Button, { disabled: disabled, className: classes.saveButton, "data-qa": "saveTranscriptButton", onClick: onSaveClicked }, t('saveTranscript.dialog1.saveButton')),
            React.createElement(Button, { disabled: disabled, className: classes.notNowButton, "data-qa": "saveTranscriptNotNowButton", onClick: onNotNowClicked }, t('saveTranscript.dialog1.notNowButton')))));
};
export default SaveTranscriptDialog1;
