import { makeStyles } from '@mui/styles';
import React from 'react';
import { FontSizeInput } from '../../components/Inputs/FontSizeInput';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiSlider-thumb': {
            backgroundColor: theme.palette.ava.blue2,
            height: 12,
            width: 12,
        },
        '& .MuiSlider-rail': {
            backgroundColor: theme.palette.ava.grey5,
            opacity: 1,
        },
        '& .MuiSlider-track': {
            backgroundColor: theme.palette.ava.blue2,
            border: 'none',
        },
    },
    stepSize: {
        color: theme.palette.ava.blue2,
        background: theme.palette.ava.transparent.blue[theme.palette.mode],
        borderRadius: '50%',
        padding: 4,
    },
    decreaseSize: {
        marginRight: 20,
    },
    increaseSize: {
        marginLeft: 20,
    },
}));
export const ConvoV2FontSizeInput = React.memo(() => {
    const classes = useStyles();
    return (React.createElement(FontSizeInput, { className: classes.root, classNames: {
            stepSize: classes.stepSize,
            decreaseSize: classes.decreaseSize,
            increaseSize: classes.increaseSize,
        } }));
});
