import { Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SaveTranscriptIcon from '../../components/Icons/SaveTranscript';
import { ConvoV2PrimaryButton } from './ConvoV2PrimaryButton';
const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiPaper-root': {
            maxWidth: 472,
            borderRadius: 20,
            padding: '32px 32px 16px 32px',
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 32,
    },
    texts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
    },
    description: {
        fontSize: 18,
        fontWeight: 400,
        textAlign: 'center',
    },
    image: {
        width: 137,
        height: 137,
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '0 16px',
        gap: 16,
    },
    saveTranscripts: {
        width: '100%',
    },
    notNow: {
        cursor: 'pointer',
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.ava.blue2,
        width: '100%',
        textAlign: 'center',
    },
}));
export const ConvoV2SaveTranscriptDialog1 = React.memo(({ open, disabled, onSaveClicked, onNotNowClicked }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Dialog, { className: classes.dialog, open: open },
        React.createElement(Box, { className: classes.root },
            React.createElement(Box, { className: classes.texts },
                React.createElement(Box, { className: classes.title }, t('saveTranscript.dialog1.heading')),
                React.createElement(Box, { className: classes.description }, t('saveTranscript.dialog1.text'))),
            React.createElement(SaveTranscriptIcon, { className: classes.image }),
            React.createElement(Box, { className: classes.actions },
                React.createElement(ConvoV2PrimaryButton, { className: classes.saveTranscripts, disabled: disabled, onClick: onSaveClicked }, t('saveTranscript.dialog1.saveButton')),
                React.createElement(Box, { className: classes.notNow, onClick: !disabled ? onNotNowClicked : undefined }, t('saveTranscript.dialog1.notNowButton'))))));
});
