import { makeStyles } from '@mui/styles';
import React from 'react';
import SaveTranscriptsPopup from '../../components/Popups/SaveTranscriptPopup';
import { ConvoV2SaveTranscriptDialog1 } from './ConvoV2SaveTranscriptDialog1';
import { ConvoV2SaveTranscriptDialog2 } from './ConvoV2SaveTranscriptDialog2';
const useStyles = makeStyles((theme) => ({
    root: {},
}));
export const ConvoV2SaveTranscriptPopup = React.memo(({ onFinish }) => {
    const classes = useStyles();
    return (React.createElement(SaveTranscriptsPopup, { onFinish: onFinish, Dialog1Component: ConvoV2SaveTranscriptDialog1, Dialog2Component: ConvoV2SaveTranscriptDialog2 }));
});
